import { Priority } from '@base/components/form';
import React from 'react';

interface ITicketPriorityViewProps {
  value: any;
}
const TicketPriorityView: React.FC<ITicketPriorityViewProps> = (
  props: ITicketPriorityViewProps,
) => {
  const { value } = props;
  return <>{value && <Priority value={value.keyName} />}</>;
};

export default TicketPriorityView;
