import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, listPageSettingsAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { useLanguageByMenu } from '@base/services/i18n';
import SplitView, { EmptySplitViewFC } from '@base/components/split-view';
//import '@desk/main/assets/scss/styles.scss';
import '@desk/knowledge-base/assets/scss/styles.scss';
import {
  DEFAULT_ROUTE,
  ListPage,
  LIST_ROUTE,
  MAIN_MENU,
  PAGE_MENU,
  ViewPage,
  VIEW_ROUTE,
} from '@desk/knowledge-base/config/pages/main-page';
import Loading from '@base/components/loading';
import { isUndefined } from 'lodash';
import { ListType } from '@base/types/enums';

const FirstPage: React.FC = () => {
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(PAGE_MENU));
  const [{ innerHeight, isDesktop }] = useRecoilState(deviceAtom);
  // const listPageSettings = useRecoilValue(listPageSettingsAtom);
  const [isLoadingSetting, setIsLoadingSetting] = useState(false);
  // load menu language
  useLanguageByMenu([MAIN_MENU]);

  const { isSplitMode } = pageData;

  const setIsSplitMode = (isSplitMode: boolean) => {
    setPageData({
      ...pageData,
      listType: isSplitMode ? ListType.SPLIT : ListType.LIST,
      isSplitMode,
    });
  };

  const splitViewProps = {
    isSplitMode,
    styles: {
      height: innerHeight !== 0 ? innerHeight - 60 : 0,
    },
  };

  const listProps = {
    isSplitMode,
    setIsSplitMode,
  };
  // useEffect(() => {
  //   if (listPageSettings && listPageSettings[PAGE_MENU]) {
  //     const menuListPage: any = listPageSettings[PAGE_MENU];
  //     const nPageData = {
  //       ...pageData,
  //       isSplitMode: menuListPage?.listType === ListType.SPLIT,
  //       listType: menuListPage?.listType,
  //     };
  //     setPageData(nPageData);
  //   }
  //   if (
  //     listPageSettings &&
  //     (isUndefined(listPageSettings[PAGE_MENU]) || listPageSettings[PAGE_MENU])
  //   ) {
  //     setIsLoadingSetting(false);
  //   }
  // }, [listPageSettings]);
  // console.log('isSplitMode', isSplitMode);
  return (
    <>
      {isLoadingSetting ? (
        <Loading />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ListPage {...listProps} />}
                  rightPane={<EmptySplitViewFC />}
                />
              ) : (
                <ListPage {...listProps} />
              )
            }
          />
          <Route
            path={VIEW_ROUTE}
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ListPage {...listProps} />}
                  rightPane={
                    <div className="pane-content" style={{ flex: 1 }}>
                      <Suspense fallback={<div className="han-loading" />}>
                        <ViewPage isSplitMode={isSplitMode} />
                      </Suspense>
                    </div>
                  }
                />
              ) : (
                <ViewPage />
              )
            }
          />
        </Routes>
      )}
    </>
  );
};

export default FirstPage;
