import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Select from '@base/components/form/select';
import { useTicketAutoComplete } from '@desk/ticket/services/ticket-service';
import classNames from 'classnames';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import Icon from '@base/assets/icons/svg-icons';

interface TicketAutoCompleteProps {
  className?: string;
  ctrlStyles?: any;
  placeholder?: string;
  single?: boolean;
  visible?: boolean;
  isDisabled?: boolean;
  addLabel?: string;
  onAdd?: any;
  onChange: (item: ITicket) => void;
  value?: any | any[];
}

const TicketAutoComplete: React.FC<TicketAutoCompleteProps> = (props) => {
  const {
    className,
    ctrlStyles,
    placeholder = 'Type or click to select an article...',
    single,
    visible = true,
    isDisabled,
    addLabel = 'Add new ticket',
    onAdd,
    value,
    onChange,
  } = props;
  //state
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;

  //initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
            setSelectedValue(value);
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
          setSelectedValue(value);
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  //get data
  const getSearchParams = () => {
    let params: any = {
      filter: {
        query: `groupBy=all subject:${searchText}`,
        paging: {
          page: 1,
          size: 50,
        },
      },
    };
    return params;
  };
  const { data, status: searchStatus } = useTicketAutoComplete(getSearchParams());

  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    let newItem = newValue;
    if (visible) {
      if (!single) {
        //just apply for multi
        const allIdx = newItem?.findIndex((ele: any) => ele.value === 'all');
        if (allIdx > -1) {
          newItem = [newItem[allIdx]];
        }
      }
      setSelectedValue(newItem);
    }
    //callback
    onChange && onChange(newItem);
  };

  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <Select
        isClearable
        isMulti={!single}
        isDisabled={isDisabled}
        ctrlStyles={ctrlStyles}
        noOptionsMessage={() => 'No Ticket(s) found.'}
        placeholder={<div className="react-select-placeholder">{placeholder}</div>}
        iconIndicator={<div className="icons-size-1">{Icon('ticket')}</div>}
        isLoading={searchStatus === 'loading'}
        inputValue={inputText}
        onInputChange={handleInputChange}
        addLabel={addLabel}
        onAdd={onAdd}
        options={data?.results || []}
        getOptionLabel={(opt: any) => opt.subject}
        getOptionValue={(opt: any) => opt.id}
        value={selectedValue}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default TicketAutoComplete;
