// import * as dataTypes from '@desk/knowledge-base/config/data-types';
import * as keyNames from '@desk/knowledge-base/config/key-names';
import * as components from './components';
import * as commonComponents from '@base/config/write-field/components';
import {
  useKnowledgeBaseCategories,
  useKnowledgeBaseTags,
} from '@desk/knowledge-base/services/knowledge-base-service';
import { IWriteConfig } from '@base/types/interfaces/common';
import { EDisplay, EKnowlegeBaseType } from '@desk/knowledge-base/types/enums';
import validators from '@base/utils/validation/field-validator';

const writeConfig: IWriteConfig = {
  [keyNames.KEY_KNOWLEDGE_BASE_TYPE]: {
    component: commonComponents.RadioGroup,
    componentProps: {
      fieldValue: 'keyName',
      fieldLabel: 'languageKey',
    },
    showFullRow: true,
    validate: {},
    defaultValue: EKnowlegeBaseType.ARTICLE,
  },
  [keyNames.KEY_KNOWLEDGE_BASE_DISPLAY]: {
    component: commonComponents.RadioGroup,
    componentProps: {
      fieldValue: 'keyName',
      fieldLabel: 'languageKey',
    },
    showFullRow: true,
    validate: {},
    defaultValue: EDisplay.PUBLIC,
  },
  [keyNames.KEY_KNOWLEDGE_BASE_ACTIVE]: {
    component: components.Switch,
    componentProps: {},
    showFullRow: true,
    validate: {},
    defaultValue: true,
  },
  [keyNames.KEY_KNOWLEDGE_BASE_SUBJECT]: {
    component: commonComponents.Input,
    componentProps: {
      type: 'text',
    },
    validate: {
      required: validators.required,
    },
    showFullRow: true,
  },
  [keyNames.KEY_KNOWLEDGE_BASE_CATEGORY]: {
    component: components.KBCategoryAutoComplete,
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    showFullRow: true,
    parseParam: (value: any) => {
      //check CATEGORY or FOLDER
      return value;
      //   return value
      //     ?
      //       value.category ?
      //       {
      //         category: {
      //           id: value.category.id,
      //           name: value.category.name
      //         },
      //         folder: {
      //           id: value.id,
      //           name: value.name,
      //         }
      //       }
      //       :
      //       {
      //         id: value.id,
      //         name: value.name,
      //       }
      //     : null;
    },
  },
  [keyNames.KEY_KNOWLEDGE_BASE_CONTENT]: {
    component: commonComponents.EditorTemplate,
    componentProps: {
      templateGroup: 'GROUP_KNOWLEDGE',
    },
    validate: {},
    showFullRow: true,
    defaultValue: {
      tpl: null,
      content: '',
    },
    parseParam: (value: any) => {
      return value
        ? value
        : {
            tpl: null,
            content: '',
          };
    },
  },
  [keyNames.KEY_KNOWLEDGE_BASE_TAG]: {
    component: commonComponents.Tags,
    componentProps: {
      fetchList: useKnowledgeBaseTags,
      fieldValue: 'id',
      fieldLabel: 'name',
    },
    validate: {},
    defaultValue: null,
    showFullRow: true,
    parseParam: (value: any) => {
      return value ? value : [];
    },
  },
};
export default writeConfig;
