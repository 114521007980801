import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { NoData } from '@base/components';
import withMiModal from '@base/hooks/hocs/withMiModal';
import KnowledgeBaseFilterSelect from '@desk/knowledge-base/containers/kb-filter-select';
import KBQuickView from './quick-view';
interface IKnowledgeBaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (items: any[]) => void;
}

const KnowledgeBaseModal = (props: IKnowledgeBaseModalProps) => {
  const { isOpen, onClose, onChange } = props;
  const [showQuickView, setShowQuickView] = useState(false);
  const [articles, setArticles] = useState<any>([]);
  const [viewArticle, setViewArticle] = useState<any>(null);
  const [insertedArticles, setInsertedArticles] = useState<any>([]);

  //select item
  const handleInsert = (article: any) => {
    const newItems = [...insertedArticles];
    const fIdx = newItems.findIndex((_ele: any) => _ele.id === article.id);
    if (fIdx === -1) {
      newItems.push(article);
      setInsertedArticles(newItems);
      //callback
      onChange && onChange(newItems);
    }
  }

  //body
  const ResultsRender = useMemo(() => {
    return (
      <div className="pd-15 scroll-box" style={{ height: 'calc(100vh - 115px)' }}>
        {!showQuickView && (
          <>
            <KnowledgeBaseFilterSelect
              onChange={(items: any) => setArticles(items)}
            />
            <div className="mg-t-20 mg-b-10 tx-13 tx-color-03">{articles.length} Results Found</div>
            {articles.length === 0 && <NoData />}
            <div className="list-group">
              {articles.map((_item: any, index: number) => (
                <div key={index} className="list-group-item d-flex flex-wrap align-items-center">
                  {_item.subject}
                  <button type="button" className="btn btn-sm btn-primary mg-l-auto" onClick={() => handleInsert(_item)}>
                    Insert
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary mg-l-5"
                    onClick={() => {
                      setViewArticle(_item);
                      setShowQuickView(true);
                    }}
                  >
                    View
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
        {showQuickView &&
          <KBQuickView
            id={viewArticle?.id}
            onClose={() => setShowQuickView(false)}
            onInsert={(item: any) => handleInsert(item)}
          />
        }
      </div>
    );
  }, [articles, insertedArticles, showQuickView]);

  //render footer
  const FooterRender = useMemo(() => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button
            type="button"
            className="btn btn-light mg-r-5"
            onClick={() => onClose && onClose()}
          >
            Close
          </button>
          {/* <Button
            color="success"
            onClick={() => {
              handleSubmit((data) => onSubmit(data), onError)();
            }}
            disabled={mutationAdd.isLoading || mUpload.isLoading || !isValid}
            loading={mutationAdd.isLoading || mUpload.isLoading}
            name="Send"
          /> */}
        </div>
      </div>
    );
  }, []);

  return (
    <div className={classNames('pos-relative')}>
      {ResultsRender}
      {FooterRender}
    </div>
  );
};

export default withMiModal(KnowledgeBaseModal);
