import React, { VFC } from 'react';
import ListTableColumnSetting from '@base/components/list/list-table-column-setting';
import ListSearch from '@base/components/list/list-search';
import ListHeaderContainer, { IListHeader } from '@base/components/list/list-header';
import { ListType } from '@base/types/enums';

interface IListHeaderExtend extends IListHeader {
  filterComponent?: React.ReactNode;
  isSplitMode: boolean;
  listType: ListType;
  columns: any[];
  searchFields: any[];
  onSearchSubmit?: (fieldsData: any) => void;
}

const ListHeader: React.FC<IListHeaderExtend> = (props) => {
  const {
    listType,
    isSplitMode,
    filterComponent,
    columns,
    searchFields,
    onSearchSubmit,

    ...remainProps
  } = props;

  // console.log('searchFields', searchFields);

  return (
    <ListHeaderContainer
      isSimple={isSplitMode}
      {...remainProps}
      filterComponent={filterComponent}
      searchComponent={
        onSearchSubmit && (
          <ListSearch
            isSimple={isSplitMode}
            placeholder={'Name'}
            searchFields={searchFields}
            onSubmit={onSearchSubmit}
          />
        )
      }
      columnSettingComponent={
        !isSplitMode &&
        listType == ListType.LIST && (
          <ListTableColumnSetting columns={columns} hideColumns={[]} columLimit={10} />
        )
      }
    />
  );
};

ListHeader.defaultProps = {};

export default ListHeader;

export { default as ListHeaderFilters } from './list-header-filters';
