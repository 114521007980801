import { atom } from 'recoil';

export const deskWriteOptionAtom = atom({
  key: 'deskWriteOptionAtom',
  default: {
    writeMode: 'list', //write
    writeType: '', //'ticket', 'knownlegde', ...
    writeParam: {},
    isOpenWrite: false,
  },
});
