import React from 'react';
import { useRecoilValue } from 'recoil';
import ViewSummary from '@base/containers/view-summary';
import * as keyNames from '@desk/ticket/config/key-names';
import { viewDataByMenuAtom } from '@base/recoil/atoms';

interface IViewLeftProps {
  id: string;
  category: string;
  showTop?: boolean;
  onRefetch?: () => void;
}

const ViewLeft: React.FC<IViewLeftProps> = (props) => {
  const { showTop, category, id, onRefetch } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(category)); //'ticket'

  //Show fields:  Ticket No, Status, Customer, Category, Priority, Classifications, Assigned Group, Assigned Rep, CC, 1st Respond Due, Resolution Due, Duration, Channel, Tag
  //hide fields
  const hiddenFields: string[] = [
    keyNames.KEY_TICKET_SUBJECT,
    keyNames.KEY_TICKET_PRODUCT,
    keyNames.KEY_TICKET_CONTACT,
    keyNames.KEY_TICKET_CONTENT,
    keyNames.KEY_TICKET_ASSIGN_USER,
    keyNames.KEY_TICKET_CHANNEL,
    keyNames.KEY_TICKET_CREATED_AT,
    keyNames.KEY_TICKET_UPDATED_AT,
    keyNames.KEY_TICKET_CREATED_BY,
    keyNames.KEY_TICKET_UPDATED_BY,
  ];
  //if customer category is contact, hide contact field
  // const basicFields = viewData?.layout?.data?.[0]?.children || [];
  // const customerField = basicFields?.find(
  //   (_field: any) => _field?.keyName === keyNames.KEY_TICKET_CUSTOMER,
  // ); //this is account or contact
  // if (customerField?.data?.category === 'CATEGORY_CONTACT') {
  //   hiddenFields.push(keyNames.KEY_TICKET_CONTACT);
  // }

  const allowRefetchFields: string[] = [keyNames.KEY_TICKET_CUSTOMER, keyNames.KEY_TICKET_CATEGORY];

  return (
    <div className="h-100">
      <ViewSummary
        bodyClassName="scroll-box"
        bodyStyle={{ height: showTop ? 'calc(100vh - 290px)' : 'calc(100vh - 160px)' }}
        data={viewData}
        //ignoreFields={[]} //FOR displaying TOP part such as photo, ...
        hiddenFields={hiddenFields} //FOR hiding display
        refetchFields={allowRefetchFields}
        onRefetch={onRefetch}
      />
    </div>
  );
};
export default ViewLeft;
