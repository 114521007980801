import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import Button from '@base/components/form/button';
import { Database, X } from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { TICKET_COMMENT_CREATE } from '@desk/ticket/services/graphql/ticket';
import TuiEditor from '@base/components/form/tui-editor';
import { CheckboxGroup, Input, SpanLang } from '@base/components/form';
import UploadFile from '@base/components/form/upload/file';
import * as keyNames from '@desk/ticket/config/key-names';
import { REPLY_TYPE_OPTIONS } from '@desk/ticket/config/contants';
import SelectTemplate from '@base/containers/select-template';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import EmailPhoneAutoComplete from '@base/containers/email-phone-auto-complete';
import { ETemplateGroup } from '@base/types/enums';
import { useUploadMutation } from '@base/hooks/useFileUploadMutation';

import KnowledgeBaseModal from '../knowledge-base-modal';
import { finalizeParams } from './payload';

interface WriteReplyFormProps {
  type: string;
  ticketId: string;
  ticketName: string;
  parentComment?: any;
  onReload?: () => void;
  onClose?: () => void;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteReplyForm: React.FC<WriteReplyFormProps> = (props) => {
  const {
    type, //reply, forward
    ticketId,
    ticketName,
    parentComment,
    onReload,
    onClose,
  } = props;
  //const { t } = useTranslation();
  // console.log('parentComment', parentComment);

  //state
  const [isOpenKB, setIsOpenKB] = useState<boolean>(false);
  const [activeReply, setActiveReply] = useState({
    email: true,
    sms: false,
    call: false,
    fax: false,
  });
  const [curFileIndex, setCurFileIndex] = useState<number>(-1);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState<number>(-1);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [originMsg, setOriginMsg] = useState<string>('');

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      [keyNames.KEY_TICKET_REPLY_TYPE]: [REPLY_TYPE_OPTIONS[0]],
      [keyNames.KEY_TICKET_REPLY_SUBJECT]: '',
      [keyNames.KEY_TICKET_REPLY_FROM]: {
        label: 'Hanbiro Test_1',
        value: '24684695-b612-4b75-a2c9-5f1f75fe090a',
        name: 'hanbirotest1',
        id: '24684695-b612-4b75-a2c9-5f1f75fe090a',
        emails: [
          {
            email: 'hanbirotest1@desk.io',
            label: { languageKey: 'crm_new_common_label_primary', label: 'LABEL_PRIMARY' },
          },
        ],
        phones: [
          {
            phoneNumber: '0123456789',
            label: { languageKey: 'crm_new_common_label_primary', label: 'LABEL_PRIMARY' },
          },
        ],
      }, //TODO: set user login
      [keyNames.KEY_TICKET_REPLY_TO]: null,
      [keyNames.KEY_TICKET_REPLY_CC]: null,
      [keyNames.KEY_TICKET_REPLY_KB]: [],
      [keyNames.KEY_TICKET_REPLY_TEMPALTE]: null,
      [keyNames.KEY_TICKET_REPLY_MESSAGE]: '',
      [keyNames.KEY_TICKET_REPLY_FILE]: [],
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //init default value
  useEffect(() => {
    if (parentComment) {
      //init subject
      let replySubject = type === 'KIND_REPLY' ? 'Re: ' : 'Fwd: ';
      if (parentComment?.subject) {
        replySubject += parentComment.subject;
      }
      if (
        parentComment?.kind === 'KIND_COMMENT' ||
        parentComment?.kind === 'KIND_REPLY' ||
        parentComment?.kind === 'KIND_FORWARD'
      ) {
        if (parentComment?.comment) {
          replySubject += parentComment.comment.subject || '';
        } else if (parentComment?.email) {
          replySubject += parentComment.email.subject;
        } else if (parentComment?.call) {
          replySubject += parentComment.call.subject;
        } else if (parentComment?.sms) {
          replySubject += parentComment.sms.subject;
        } else if (parentComment?.fax) {
          replySubject += parentComment.fax.subject;
        }
      }
      setValue(keyNames.KEY_TICKET_REPLY_SUBJECT, replySubject);

      //init To
      let replyTo: any = [];
      if (type !== 'KIND_FORWARD') {
        replyTo = [
          {
            label: parentComment?.createdBy.name,
            value: parentComment?.createdBy.id,
            name: parentComment?.createdBy.name,
            emails: [
              {
                email: 'hanbirotest@desk.io', //TODO: email
                label: { languageKey: 'crm_new_common_label_primary', label: 'LABEL_PRIMARY' },
              },
            ],
            phones: [
              {
                phoneNumber: '0123456789', //TODO: phone
                label: { languageKey: 'crm_new_common_label_primary', label: 'LABEL_PRIMARY' },
              },
            ],
          },
        ];
      }
      setValue(keyNames.KEY_TICKET_REPLY_TO, replyTo);

      //init cc
      let replyCc: any = [];
      if (parentComment?.ccUsers) {
        //TODO
        setValue(keyNames.KEY_TICKET_REPLY_CC, replyCc);
      }

      //init reply message
      let blockquote = '<p></p>';
      //On Web, 10 Aug at 10:10AM, Sarah Jones \&#60;sarah@vn.vn\&#62; wrote:
      let content = '<p>On ';
      content += convertDateTimeServerToClient({
        date: parentComment.createdAt,
        formatOutput: 'ddd, DD MMM YYYY',
      });
      content += ' at ';
      content += convertDateTimeServerToClient({
        date: parentComment.createdAt,
        isTime: true,
        formatOutput: 'HH:mm:ss',
      });
      content += ', ';
      content += parentComment.createdBy.name;
      if (parentComment.email) {
        content += ` \&#60;${parentComment.email.from}\&#62; wrote: </p>`;
        content += parentComment.email.content;
        blockquote += `<blockquote>${content}</blockquote>`;
      } else if (parentComment.sms) {
        content += ` \&#60;${parentComment.sms.from}\&#62; wrote: </p>`;
        content += parentComment.sms.content;
        blockquote += `<blockquote>${content}</blockquote>`;
      } else if (parentComment.call) {
        content += ` \&#60;${parentComment.call.from}\&#62; wrote: </p>`;
        content += parentComment.call.content;
        blockquote += `<blockquote>${content}</blockquote>`;
      } else if (parentComment.comment) {
        content += ` wrote: </p>`; //TODO: createdBy email here
        content += parentComment.comment.content;
        blockquote += `<blockquote>${content}</blockquote>`;
      } else if (parentComment.content) {
        content += ` wrote: </p>`; //TODO: createdBy email here
        content += parentComment.content;
        blockquote += `<blockquote>${content}</blockquote>`;
      }
      blockquote += '<p></p>';
      // console.log('blockquote', blockquote);
      setValue(keyNames.KEY_TICKET_REPLY_MESSAGE, blockquote);
      setOriginMsg(blockquote);

      //init attached files
      if (type === 'KIND_FORWARD') {
        let attachedFiles: any = [];
        if (parentComment?.kind === 'KIND_COMMENT') {
          attachedFiles = parentComment.comment.attachedFiles;
        } else {
          if (parentComment.email) {
            attachedFiles = parentComment.email.attachedFiles;
          } else if (parentComment.sms) {
            attachedFiles = parentComment.sms.attachedFiles;
          }
        }
        setValue(keyNames.KEY_TICKET_REPLY_FILE, attachedFiles);
      }
    }
  }, [parentComment]);

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    TICKET_COMMENT_CREATE,
    'desk_createTicketComment',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Created ticket comment successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        console.log('mutation error', error);
        toast.error('There is an error during creating: ' + JSON.parse(error).message);
      },
    },
  );

  // upload
  const mUpload: any = useUploadMutation<BaseMutationResponse>(
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //toast.success('Uploaded successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.error('There is error during uploading: ' + JSON.parse(error).message);
      },
    },
    // (pEvent: ProgressEvent, partsNumber: number, partIndex: number, uploadId?: string) =>
    //   uploadProgressHandler(pEvent, partsNumber, partIndex, uploadId),
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      // refecth data
      onReload && onReload();
      onClose && onClose();
    }
  }, [mutationAdd.isSuccess]);

  //upload success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mUpload);
    if (mUpload.isSuccess) {
      const newUploadedFiles = [...uploadedFiles];
      const selectedFiles: any = getValues()[keyNames.KEY_TICKET_REPLY_FILE];
      const newUpload = {
        objectId: mUpload.data.id, //upload id
        objectUrl: mUpload.data.url, //download url
        name: selectedFiles[curFileIndex].name,
        size: selectedFiles[curFileIndex].size,
        contentType: selectedFiles[curFileIndex].type,
      };
      newUploadedFiles.push(newUpload);
      setUploadedFiles(newUploadedFiles);
      //next file for uploading
      setLastUploadedFileIndex(curFileIndex);
    }
  }, [mUpload.isSuccess]);

  //field value watching
  const typeValue = watch(keyNames.KEY_TICKET_REPLY_TYPE);
  const kbValue = watch(keyNames.KEY_TICKET_REPLY_KB);

  //confirm
  const handleConfirmChangeTemplate = (templateValue: any) => {
    //const templateValue: any = getValues(keyNames.KEY_TICKET_REPLY_TEMPALTE);
    //add content to editor
    let curMessage: string = '';
    if (templateValue) {
      try {
        let tplHtml = JSON.parse(templateValue.html);
        //console.log('tplHtml', tplHtml);
        curMessage = tplHtml.html;
      } catch (e) {
        //error
      }
    } else {
      curMessage = originMsg;
    }
    //if kb if any
    if (kbValue?.length > 0) {
      kbValue.map((_ele: any) => {
        curMessage += `${_ele.content}`;
      });
    }
    setValue(keyNames.KEY_TICKET_REPLY_MESSAGE, curMessage);
  }

  //submit form
  const onSubmit = (formData: any) => {
    //upload files
    const uploadFiles = formData[keyNames.KEY_TICKET_REPLY_FILE];
    if (uploadFiles.length > 0) {
      if (curFileIndex === -1) {
        setCurFileIndex(lastUploadedFileIndex === -1 ? 0 : lastUploadedFileIndex + 1);
      }
    } else {
      const params = finalizeParams(formData, type, ticketId, ticketName, parentComment);
      mutationAdd.mutate({ comment: params });
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  //set KB value
  const handleKBChange = (articles: any) => {
    const templateValue: any = getValues(keyNames.KEY_TICKET_REPLY_TEMPALTE);
    //check exist and push
    const curArticles: any = [...getValues(keyNames.KEY_TICKET_REPLY_KB)];
    articles.map((_item: any) => {
      const fIdx = curArticles.findIndex((_ele: any) => _ele.id === _item.id);
      if (fIdx === -1) {
        curArticles.push(_item);
      }
    })
    setValue(keyNames.KEY_TICKET_REPLY_KB, curArticles);

    //add content to editor
    let curMessage: string = originMsg;
    if (templateValue) {
      try {
        let tplHtml = JSON.parse(templateValue.html);
        //console.log('tplHtml', tplHtml);
        curMessage = tplHtml.html;
      } catch (e) {
        //error
      }
    }
    curArticles.map((_ele: any) => {
      curMessage += `${_ele.content}`;
    });
    setValue(keyNames.KEY_TICKET_REPLY_MESSAGE, curMessage);
  }

  //remove kb
  const handleRemoveKb = (index: number) => {
    const templateValue: any = getValues(keyNames.KEY_TICKET_REPLY_TEMPALTE);
    const newItems = [...kbValue];
    newItems.splice(index, 1);
    setValue(keyNames.KEY_TICKET_REPLY_KB, newItems);
    //add content to editor
    let curMessage: string = originMsg;
    if (templateValue) {
      try {
        let tplHtml = JSON.parse(templateValue.html);
        //console.log('tplHtml', tplHtml);
        curMessage = tplHtml.html;
      } catch (e) {
        //error
      }
    }
    newItems.map((_ele: any) => {
      curMessage += `${_ele.content}`;
    });
    setValue(keyNames.KEY_TICKET_REPLY_MESSAGE, curMessage);
  }

  /** =============================== UPLOAD HANDLER =========================== */
  //upload current file
  useEffect(() => {
    //// console.log('curFileIndex', curFileIndex);
    const selectedFiles: any[] = getValues()[keyNames.KEY_TICKET_REPLY_FILE];
    if (curFileIndex !== -1) {
      if (selectedFiles[curFileIndex]?.id) {
        //this file has been uploaded
        const newUploadedFiles = [...uploadedFiles];
        newUploadedFiles.push(selectedFiles[curFileIndex]);
        setUploadedFiles(newUploadedFiles);
        //next file for uploading
        setLastUploadedFileIndex(curFileIndex);
      } else {
        mUpload.mutate(selectedFiles[curFileIndex]);
      }
    }
  }, [curFileIndex]);

  //next file - last file
  useEffect(() => {
    if (lastUploadedFileIndex === -1) {
      return;
    }
    const formData = getValues();
    const selectedFiles = formData[keyNames.KEY_TICKET_REPLY_FILE];
    const isLastFile = lastUploadedFileIndex === selectedFiles.length - 1;
    if (isLastFile) {
      setCurFileIndex(-1);
      //start save to db
      const params = finalizeParams(formData, type, ticketId, ticketName, parentComment);
      if (
        formData[keyNames.KEY_TICKET_REPLY_TYPE].findIndex((_ele: any) => _ele.value === 'email') >
        -1
      ) {
        params.email.attachedFiles = uploadedFiles;
      }
      if (
        formData[keyNames.KEY_TICKET_REPLY_TYPE].findIndex((_ele: any) => _ele.value === 'sms') > -1
      ) {
        params.sms.attachedFiles = uploadedFiles;
      }
      mutationAdd.mutate({ comment: params });
    } else {
      //next uploaded
      const nextFileIndex = curFileIndex + 1;
      setCurFileIndex(nextFileIndex);
    }
  }, [lastUploadedFileIndex]);

  /** =============================== END UPLOAD HANDLER =========================== */

  const renderTypeTabs = () => {
    return (
      <nav className="nav nav-tabs">
        {typeValue.findIndex((_ele: any) => _ele.value === 'email') > -1 && (
          <button
            type="button"
            className={`nav-link ${activeReply.email && 'active'}`}
            onClick={() => setActiveReply({ email: true, sms: false, call: false, fax: false })}
          >
            Email
          </button>
        )}
        {typeValue.findIndex((_ele: any) => _ele.value === 'sms') > -1 && (
          <button
            type="button"
            className={`nav-link ${activeReply.sms && 'active'}`}
            onClick={() => setActiveReply({ email: false, sms: true, call: false, fax: false })}
          >
            SMS
          </button>
        )}
        {typeValue.findIndex((_ele: any) => _ele.value === 'call') > -1 && (
          <button
            type="button"
            className={`nav-link ${activeReply.call && 'active'}`}
            onClick={() => setActiveReply({ email: false, sms: false, call: true, fax: false })}
          >
            Call
          </button>
        )}
        {typeValue.findIndex((_ele: any) => _ele.value === 'fax') > -1 && (
          <button
            type="button"
            className={`nav-link ${activeReply.fax && 'active'}`}
            onClick={() => setActiveReply({ email: false, sms: false, call: false, fax: true })}
          >
            Fax
          </button>
        )}
      </nav>
    );
  };

  //form fields
  const renderFields = () => {
    return (
      <div className="pd-15 scroll-box" style={{ height: 'calc(100vh - 150px)' }}>
        <div className="form-group">
          <label className="form-item-title d-block">Type of Reply</label>
          <Controller
            name={keyNames.KEY_TICKET_REPLY_TYPE}
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxGroup
                isVertical={false}
                className="mg-r-20"
                options={REPLY_TYPE_OPTIONS}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        {renderTypeTabs()}
        <div className="pd-20 bd bd-t-0 rounded">
          <div className="form-group">
            <label className="form-item-title wd-150">
              Subject<span className="tx-danger">*</span>
            </label>
            <div className="flex-fill">
              <Controller
                name={keyNames.KEY_TICKET_REPLY_SUBJECT}
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }: any) => (
                  <Input value={value} onChange={onChange} />
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-item-title wd-150">
              From<span className="tx-danger">*</span>
            </label>
            <div className="flex-fill">
              <Controller
                name={keyNames.KEY_TICKET_REPLY_FROM}
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }: any) => (
                  <EmailPhoneAutoComplete
                    single={true}
                    showAvatar={true}
                    showEmail={activeReply.email ? true : false}
                    showPhone={!activeReply.email ? true : false}
                    isDisabled={true}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-item-title wd-150">
              To<span className="tx-danger">*</span>
            </label>
            <div className="flex-fill">
              <Controller
                name={keyNames.KEY_TICKET_REPLY_TO}
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }: any) => (
                  <EmailPhoneAutoComplete
                    showAvatar={true}
                    showEmail={activeReply.email ? true : false}
                    showPhone={!activeReply.email ? true : false}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>

          {activeReply.email && (
            <div className="form-group">
              <label className="form-item-title wd-150">CC</label>
              <div className="flex-fill">
                <Controller
                  name={keyNames.KEY_TICKET_REPLY_CC}
                  control={control}
                  render={({ field: { value, onChange } }: any) => (
                    <EmailPhoneAutoComplete
                      showAvatar={true}
                      showEmail={activeReply.email ? true : false}
                      showPhone={!activeReply.email ? true : false}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {!activeReply.fax && (
            <div className="form-group">
              <label className="form-item-title wd-150">
                Message<span className="tx-danger">*</span>
              </label>
              <div className="flex-fill">
                {!activeReply.call && (
                  <>
                    <div className="d-flex align-items-center mg-b-10">
                      <button
                        type="button"
                        className="btn btn-outline-secondary ht-38 mg-r-5"
                        onClick={() => setIsOpenKB(true)}
                      >
                        <Database className="mg-r-5" />
                        Knowledge Base
                      </button>
                      <Controller
                        name={keyNames.KEY_TICKET_REPLY_TEMPALTE}
                        control={control}
                        render={({ field: { value, onChange } }: any) => (
                          <SelectTemplate
                            useSelectBox={true}
                            useItemTable={false}
                            value={value}
                            onChange={(newValue: any) => {
                              onChange(newValue);
                              handleConfirmChangeTemplate(newValue);
                            }}
                            templateGroup={
                              activeReply.email ? ETemplateGroup.EMAIL : ETemplateGroup.SMS
                            }
                          />
                        )}
                      />
                    </div>
                    {/* KB items */}
                    {kbValue.length > 0 &&
                      kbValue.map((_item: any, index: number) => (
                        <div key={index} className="d-flex flex-wrap align-items-center mg-b-3 pd-x-10 bg-gray-100 rounded">
                          <div className='text-truncate'>{_item.subject}</div>
                          <div className="d-flex align-items-center mg-l-auto">
                            <button
                              type="button"
                              className="btn btn-link btn-icon han-tooltip--top"
                              data-han-tooltip="Delete"
                              onClick={() => handleRemoveKb(index)}
                            >
                              <X />
                            </button>
                          </div>
                        </div>
                      ))
                    }
                  </>
                )}
                <Controller
                  name={keyNames.KEY_TICKET_REPLY_MESSAGE}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }: any) => (
                    <TuiEditor value={value} onChange={onChange} />
                  )}
                />
              </div>
            </div>
          )}

          {(activeReply.email || activeReply.fax) && (
            <div className="form-group">
              <label className="form-item-title wd-150">
                {activeReply.fax ? 'Image' : 'Attached'} File
              </label>
              <div className="flex-fill">
                <Controller
                  name={keyNames.KEY_TICKET_REPLY_FILE}
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { value, onChange } }: any) => (
                    <UploadFile canDrop={true} multiple={true} value={value} onChange={onChange} />
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button
            type="button"
            className="btn btn-light mg-r-5"
            onClick={() => onClose && onClose()}
          >
            Close
          </button>
          <Button
            color="success"
            onClick={() => {
              handleSubmit((data) => onSubmit(data), onError)();
            }}
            disabled={mutationAdd.isLoading || mUpload.isLoading || !isValid}
            loading={mutationAdd.isLoading || mUpload.isLoading}
            name="Send"
          />
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  console.log('form values', watch()); //get form values when inputing
  // console.log('form values', getValues()); //get form values when inputing
  // console.log('form errors', errors);
  // console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        {/* hidden code generator */}
        {/* <div className="d-none">
          <Controller
            name={keyNames.KEY_TICKET_CODE}
            control={control}
            render={({ field: { value, onChange } }: any) => {
              return <InputCodeGenerator menu="ticket" value={value} onChange={onChange} />;
            }}
          />
        </div> */}
        {renderFields()}
        {renderFooter()}
      </form>

      {isOpenKB &&
        <KnowledgeBaseModal
          showCanvas={true}
          className="mn-wd-900"
          isOpen={isOpenKB}
          title={<SpanLang keyLang={'Knowledge Base'} />}
          onClose={() => setIsOpenKB(false)}
          onChange={handleKBChange}
        />
      }
    </div>
  );
};

export default withMiModal(WriteReplyForm);
