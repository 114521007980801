export enum ECategoryType {
  NONE = 'TYPE_NONE',
  CATEGORY = 'TYPE_CATEGORY',
  FOLDER = 'TYPE_FOLDER',
}

// export enum ECategoryKbType {
//   NONE = 'TYPE_NONE',
//   ARTICLE = 'TYPE_ARTICLE',
//   CANNED_RESPONSE = 'TYPE_CANNED_RESPONSE',
// }

// export enum EDashboardType {
//   DASHBOARD = 'dashboard',
//   REPORT = 'report',
// }
