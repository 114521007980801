import ListReactTable8 from '@base/components/list/list-react-table-v8';
import CategoryFolderTree from '@desk/knowledge-base/containers/category-folder-tree';
import { KnowledgeBaseCategory } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { ChevronLeft } from 'react-feather';

interface IListModeProps {
  listTableProps: any;
  onSelect: (category: KnowledgeBaseCategory | null) => void;
}
const ListMode: React.FC<IListModeProps> = (props: IListModeProps) => {
  const [showTreeList, setShowTreeList] = useState(true);
  const { listTableProps, onSelect } = props;
  return (
    <>
      <div className="desk-knowledgebase-list">
        {/* Category & Folder Tree */}
        <div className={classNames('tree-list', { collapsed: !showTreeList })}>
          <div className="tree-list-header">
            <span className="text-truncate">Categories and Folders</span>
          </div>
          <CategoryFolderTree treeId="tree-list" onSelect={onSelect} />
        </div>
        {showTreeList && (
          <>
            <button type="button" className="btn pd-5 bd-r" onClick={() => setShowTreeList(false)}>
              <ChevronLeft />
            </button>
          </>
        )}
        {!showTreeList && (
          <>
            <button type="button" className="btn pd-5 bd-r" onClick={() => setShowTreeList(true)}>
              <ChevronLeft />
            </button>
          </>
        )}

        {/** List table ui */}
        <div className="article-list">
          {/* <ListReactTable {...listTableProps} /> */}
          <ListReactTable8 {...listTableProps} />
        </div>
      </div>
    </>
  );
};

export default ListMode;
