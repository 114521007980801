import Icon from '@base/assets/icons/svg-icons';
import { viewDataByMenuAtom } from '@base/recoil/atoms/view';
import { getFieldLayoutDataByKeyNames } from '@base/utils/helpers/page-layout-utils';
import React from 'react';
import { Eye, ThumbsDown, ThumbsUp } from 'react-feather';
import { useRecoilValue } from 'recoil';
const mediaBodyWidth = {
  maxWidth: 'calc(100% - 40px)',
};
interface KnowledgeBaseReportProps {
  menuSource: string;
  menuSourceId: string;
}
const KnowledgeBaseReport: React.FC<KnowledgeBaseReportProps> = (
  props: KnowledgeBaseReportProps,
) => {
  const reportKeyNames = ['viewed', 'inserted', 'helped', 'notHelped'];
  const { menuSource, menuSourceId } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(menuSource));
  let viewed = 0,
    inserted = 0,
    helped = 0,
    notHelped = 0;
  const fields = getFieldLayoutDataByKeyNames(viewData, reportKeyNames);
  if (fields && fields.length) {
    fields.forEach((field) => {
      if (field.keyName == 'viewed') {
        viewed = field?.data ? field.data : 0;
      }
      if (field.keyName == 'inserted') {
        inserted = field?.data ? field.data : 0;
      }
      if (field.keyName == 'helped') {
        helped = field?.data ? field.data : 0;
      }
      if (field.keyName == 'notHelped') {
        notHelped = field?.data ? field.data : 0;
      }
    });
  }
  return (
    <div className="view-aside-section pd-15">
      <div className="row row-xs">
        <div className="col-xl-6">
          <div className="media mg-b-10">
            <div className="wd-40 ht-40 rounded bg-gray-500 tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
              <Eye />
            </div>
            <div className="media-body mg-l-10" style={mediaBodyWidth}>
              <div className="tx-12 tx-uppercase tx-gray-500 tx-spacing-1 tx-nowrap">Viewed</div>
              <div className="tx-20 tx-semibold tx-spacing--2 lh-1 text-truncate">{viewed}</div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="media mg-b-10">
            <div className="wd-40 ht-40 rounded bg-info tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
              {Icon('ticket')}
            </div>
            <div className="media-body mg-l-10" style={mediaBodyWidth}>
              <div className="tx-12 tx-uppercase tx-info tx-spacing-1 tx-nowrap">Inserted</div>
              <div className="tx-20 tx-semibold tx-spacing--2 lh-1 text-truncate">{inserted}</div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="media mg-b-10 mg-xl-b-0">
            <div className="wd-40 ht-40 rounded bg-success tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
              <ThumbsUp />
            </div>
            <div className="media-body mg-l-10" style={mediaBodyWidth}>
              <div className="tx-12 tx-uppercase tx-success tx-spacing-1 tx-nowrap">Helpful</div>
              <div className="tx-20 tx-semibold tx-spacing--2 lh-1 text-truncate">{helped}</div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="media">
            <div className="wd-40 ht-40 rounded bg-danger tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
              <ThumbsDown />
            </div>
            <div className="media-body mg-l-10" style={mediaBodyWidth}>
              <div className="tx-12 tx-uppercase tx-danger tx-spacing-1 tx-nowrap">Not Helpful</div>
              <div className="tx-20 tx-semibold tx-spacing--2 lh-1 text-truncate">{notHelped}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBaseReport;
