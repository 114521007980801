export enum EDisplay {
  PUBLIC = 'DISPLAY_PUBLIC',
  PRIVATE = 'DISPLAY_PRIVATE',
}

export enum EKnowlegeBaseType {
  NONE = 'TYPE_NONE',
  ARTICLE = 'TYPE_ARTICLE',
  CANNED_RESPONSE = 'TYPE_CANNED_RESPONSE',
}
