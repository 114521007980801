import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UserAutoComplete } from '@base/containers';
import useMutationPost from '@base/hooks/useMutationPost';
import { ChevronUp } from 'react-feather';
import withMiModal from '@base/hooks/hocs/withMiModal';
import { Controller, useForm } from 'react-hook-form';
import { DISPLAY_OPTIONS } from '@desk/knowledge-base/config/constants';
import { Button, Input, RadioGroup, Switch, TextArea } from '@base/components/form';
import {
  KNOWLEDGE_CATEGORY_CREATE,
  KNOWLEDGE_CATEGORY_UPDATE,
  //KNOWLEDGE_FOLDER_CREATE,
  //KNOWLEDGE_FOLDER_UPDATE,
} from '@desk/knowledge-base/services/graphql/knowledge-base';
import KBCategoryAutoComplete from '@desk/knowledge-base/containers/kb-category-auto-complete';
import { EDisplay } from '@desk/knowledge-base/types/enums';

interface IWriteFolderFormProps {
  fullScreen?: boolean;
  category?: any;
  data?: any;
  isOpen: boolean;
  onClose: () => void;
  onReload?: (params: any) => void;
}

const WriteFolderForm: React.FC<IWriteFolderFormProps> = (props) => {
  const { onClose, onReload, category, data, isOpen, fullScreen = false } = props;
  //state

  //hook form
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: '',
      parent: null, // --> category/folder
      //owner: null,
      //display: DISPLAY_OPTIONS[0],
      //active: true,
      description: '',
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //watching
  const parentValue: any = watch('parent');

  //init data for Edit
  useEffect(() => {
    if (data) {
      if (data.name) {
        setValue('name', data.name, { shouldValidate: true });
      }
      if (data.parent) {
        setValue('parent', data.parent || '', { shouldValidate: true });
      }
      if (data.description) {
        setValue('description', data.description || '');
      }
    } else {
      reset();
    }
  }, [data]);

  //mutation definition
  const mCreate: any = useMutationPost(KNOWLEDGE_CATEGORY_CREATE, 'desk_createKBCategory');
  const mUpdate: any = useMutationPost(KNOWLEDGE_CATEGORY_UPDATE, 'desk_updateKBCategory');

  //check create success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mCreate);
    if (mCreate.isSuccess) {
      onReload && onReload({ ...mCreate.variables.category, id: mCreate.data.id });
      onClose && onClose();
    }
  }, [mCreate.isSuccess]);

  //check create success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mCreate);
    if (mUpdate.isSuccess) {
      onReload && onReload({ ...mUpdate.variables.category });
      onClose && onClose();
    }
  }, [mUpdate.isSuccess]);

  //submit form
  const onSubmit = ({ formData, isPublic }: any) => {
    const newParams: any = {
      name: formData.name,
      description: formData.description,
      //order: 1,
      display: isPublic ? EDisplay.PUBLIC : EDisplay.PRIVATE,
      type: 'TYPE_FOLDER',
    };
    if (formData.parent.category) {
      //this is folder
      newParams.category = formData.parent.category;
      newParams.parent = {
        id: formData.parent.id,
        name: formData.parent.name,
      };
    } else {
      //this is category
      newParams.category = {
        id: formData.parent.id,
        name: formData.parent.name,
      };
    }
    if (data?.id) {
      newParams.id = data?.id;
      mUpdate.mutate({ category: newParams });
    } else {
      mCreate.mutate({ category: newParams });
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  //form fields
  const renderFields = () => {
    // let formHeight = 'calc(100vh - 370px)';
    // if (fullScreen) {
    //   formHeight = 'calc(100vh - 170px)';
    // }

    return (
      <div
        className="pd-15 form-row scroll-box"
        //style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className="form-group col-12">
          <label className="form-item-title">
            Name
            <span className="tx-danger" title="필수 입력">
              *
            </span>
          </label>
          <Controller
            name={'name'}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }: any) => {
              return <Input type="text" value={value} onChange={onChange} />;
            }}
          />
        </div>
        <div className="form-group col-12">
          <label className="form-item-title">
            Category / Parent Folder
            <span className="tx-danger" title="필수 입력">
              *
            </span>
          </label>
          <Controller
            name={'parent'}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }: any) => {
              return <KBCategoryAutoComplete single={true} value={value} onChange={onChange} />;
            }}
          />
        </div>
        {/* <div className="form-group col-12">
          <label className="form-item-title">
            Owner
          </label>
          <Controller
            name={'owner'}
            control={control}
            render={({ field: { value, onChange } }: any) => {
              return <UserAutoComplete
                single={true}
                showAvatar={true}
                value={value}
                onChange={onChange}
              />;
            }}
          />
        </div> */}
        {/* <div className="form-group col-12">
          <label className="form-item-title">
            Display to<span className="tx-danger" title="필수 입력">*</span>
          </label>
          <Controller
            name={'display'}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }: any) => {
              return <RadioGroup
                options={DISPLAY_OPTIONS}
                value={value}
                onChange={onChange}
              />;
            }}
          />
        </div> */}
        {/* <div className="form-group col-12">
          <label className="form-item-title">
            Active
          </label>
          <Controller
            name={'active'}
            control={control}
            render={({ field: { value, onChange } }: any) => {
              return <Switch
                value={value}
                onChange={onChange}
              />;
            }}
          />
        </div> */}
        <div className="form-group col-12">
          <label className="form-item-title">Description</label>
          <Controller
            name={'description'}
            control={control}
            //rules={{ required: true }}
            render={({ field: { value, onChange } }: any) => {
              return <TextArea value={value} onChange={onChange} />;
            }}
          />
        </div>
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                handleSubmit((data) => onSubmit({ formData: data, isPublic: true }), onError)();
              }}
              disabled={
                parentValue?.display === EDisplay.PRIVATE ||
                mCreate.isLoading ||
                mUpdate.isLoading ||
                !isValid
              }
              loading={mCreate.isLoading || mUpdate.isLoading}
              name="Save as Public"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  handleSubmit((data) => onSubmit({ formData: data, isPublic: false }), onError)();
                }}
                disabled={mCreate.isLoading || mUpdate.isLoading || !isValid}
                loading={mCreate.isLoading || mUpdate.isLoading}
                name="Save as Private"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form values', getValues()); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        {renderFields()}
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(WriteFolderForm);
