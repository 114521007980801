import { lazy } from 'react';

/*export const WriteFormDesktopLayout1 = lazy(
  () => import('@inventory/warehouse/layouts/desktop/layout1/write'),
);*/

export const ListDesktopLayout1 = lazy(
  () => import('@desk/knowledge-base/layouts/desktop/layout1/list'),
);
export const ViewFormDesktopLayout1 = lazy(() => import('@base/layouts/desktop/layout1/view'));
export const WriteFormDesktopLayout1 = lazy(
  () => import('@desk/knowledge-base/layouts/desktop/layout1/write'),
);
