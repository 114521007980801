import { MENU_DESK } from '@base/config/menus';
import { lazy } from 'react';

/** MAIN PAGE CONFIG */
export const MAIN_MENU = MENU_DESK;
export const LIST_ROUTE = 'ticket';
export const VIEW_ROUTE = 'ticket/:id/*';
export const DEFAULT_ROUTE = '/desk/ticket';
export const DEFAULT_CATEGORY = 'ticket';
export const ListPage = lazy(() => import('@desk/ticket/pages/list'));
export const ViewPage = lazy(() => import('@desk/ticket/pages/view'));
export const WritePage = lazy(() => import('@desk/ticket/pages/write'));

export const PRIMARY_KEY = 'id';
export const NAME_KEY = 'subject';
