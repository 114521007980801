import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Download, Inbox, Mail, Phone, Printer, Lock } from 'react-feather';
import { Avatar, NoData, SpanLang } from '@base/components';
import { Button } from '@base/components/form';
import { TICKET_COMMENT_KIND_NEW, TICKET_COMMENT_KIND_REPLY } from '@desk/ticket/config/contants';
import { convertDateTimeServerToClient, getFileIcon, humanFileSize } from '@base/utils/helpers';
import { SvgIcons } from '@base/assets/icons/svg-icons';
import { EDisplay } from '@desk/knowledge-base/types/enums';
import { useDownloadObjectMutation } from '@base/hooks/useFileUploadMutation';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';

interface TicketRelyProps {
  className?: string;
  data?: any[];
  onReply?: (item: any) => void;
  onForward?: (item: any) => void;
}

const TicketReply = (props: TicketRelyProps) => {
  const { className, data, onReply, onForward } = props;

  //state
  const [comments, setComments] = useState<any>([]);
  const [downloadFile, setDownloadFile] = useState<any>(null);

  //download mutation
  const mDownload: any = useDownloadObjectMutation<BaseMutationResponse>({
    onSuccess: (data: any, variables: any, context: any) => {
      //toast.success('Uploaded successfully!');
    },
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      toast.error('Downloaded failed: ' + JSON.parse(error).message);
    },
  });

  //download success
  useEffect(() => {
    //// console.log('<<< download completed useEffect >>>', mDownload);
    //reference: https://base64.guru/converter/encode/image
    if (mDownload.isSuccess) {
      let link = window.document.createElement('a');
      link.href = mDownload.data;
      link.download = downloadFile.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      //reset
      setDownloadFile(null);
    }
  }, [mDownload.isSuccess]);

  //init list
  useEffect(() => {
    if (data) {
      if (JSON.stringify(data) !== JSON.stringify(comments)) {
        setComments(data);
      }
    } else {
      setComments([]);
    }
  }, [data]);

  //download a file
  const handleDownloadFile = (file: any) => {
    if (file) {
      setDownloadFile(file);
      const params = {
        id: file.objectId,
        url: file.objectUrl,
      };
      mDownload.mutate(params);
    }
  };

  //render file type icon
  const renderFileIcon = (filename: string) => {
    let icon = getFileIcon(filename);
    return <SvgIcons type={icon} />;
  };

  //render an attachment
  const renderAttachment = (file: any) => {
    return (
      <div className="d-flex align-items-center mg-b-5">
        <div className="media align-items-center">
          <span className="file-type-icon">{renderFileIcon(file?.name)}</span>
          <div className="media-body mg-l-10 text-truncate">
            {file.name}
            <small className="d-block text-muted text-truncate">
              ({humanFileSize(file.size || 1)}){' '}
              {convertDateTimeServerToClient({ date: file.createdAt?.toString() })}
            </small>
          </div>
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Download"
            onClick={() => handleDownloadFile(file)}
          >
            <Download />
            {/* {mDownload.isLoading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )} */}
          </button>
          {/* <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
            onClick={() => handleDeleteFile(item)}
          >
            {!mDeleteUpload.isLoading && !mDeleteAttachment.isLoading && <X className="tx-danger" />}
            {(mDeleteUpload.isLoading || mDeleteAttachment.isLoading) && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )}
            <span className="sr-only">
              <SpanLang keyLang="common_btn_download" />
            </span>
          </button> */}
        </div>
      </div>
    );
  };

  //main content
  const renderContent = (content: string, keyIdx: string) => {
    return (
      <>
        <Button
          outline
          color="primary"
          className="pd-y-1 pd-x-10"
          icon="ChevronDown"
          data-toggle="collapse"
          data-target={'#replyContent' + keyIdx}
          aria-expanded="true"
        />
        <div
          id={'replyContent' + keyIdx}
          className="collapse fade show pd-t-10"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </>
    );
  };

  //content header
  const renderHeader = (item: any) => {
    const { createdAt, createdBy, kind, display } = item;
    return (
      <div className="d-flex align-items-center pd-10">
        <div className="d-flex" style={{ width: '6%' }}>
          <Avatar
            url={createdBy?.url}
            alt={createdBy?.name}
            className="bg-pink-light mg-r-5 rounded-5"
            height={36}
            width={36}
            isCircle={false}
          />
        </div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex align-items-center">
            <span className="tx-bold tx-primary mg-r-5">{createdBy?.name}</span>
            <span>
              {kind === TICKET_COMMENT_KIND_NEW
                ? 'created'
                : kind === TICKET_COMMENT_KIND_REPLY
                  ? 'replied'
                  : 'forwarded'}
            </span>
            {display === EDisplay.PRIVATE && (
              <Lock className="mg-l-10 tx-warning" style={{ width: '17px' }} />
            )}
          </div>
          <div className="d-flex">
            <span className="tx-italic">
              {convertDateTimeServerToClient({ date: createdAt, humanize: true })} (
              {convertDateTimeServerToClient({ date: createdAt, formatOutput: 'ddd, DD MMM YYYY' })}
              {' at '}
              {convertDateTimeServerToClient({
                date: createdAt,
                isTime: true,
                formatOutput: 'HH:mm:ss',
              })}
              )
            </span>
          </div>
        </div>
        <div className="d-flex mg-l-auto">
          <Button
            outline
            color={kind === TICKET_COMMENT_KIND_NEW ? 'info' : 'warning'}
            className="pd-y-1 pd-x-10"
            icon="CornerUpLeft"
            style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, borderRight: 0 }}
            onClick={() => onReply && onReply({ ...item, name: item.kind })}
          />
          <Button
            outline
            color={kind === TICKET_COMMENT_KIND_NEW ? 'info' : 'warning'}
            className="pd-y-1 pd-x-10"
            icon="CornerUpRight"
            style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
            onClick={() => onForward && onForward({ ...item, name: item.kind })}
          />
        </div>
      </div>
    );
  };

  //sub content
  const renderReply = (index: number, replyContent: string) => {
    return (
      <>
        <Button
          outline
          color="primary"
          className="pd-y-1 pd-x-10"
          icon="ChevronDown"
          data-toggle="collapse"
          data-target={'#replyContent' + index}
          aria-expanded="true"
        />
        <div
          className="d-flex mg-t-10 pd-x-15"
          style={{
            borderLeft: '1px gray dashed',
            wordSpacing: '1.5px',
            lineHeight: 1.8,
          }}
        >
          <div
            id={'replyContent' + index}
            className="collapse fade show"
            dangerouslySetInnerHTML={{ __html: replyContent }}
          />
        </div>
      </>
    );
  };

  //render new
  const renderNew = (comment: any, index: number) => {
    return (
      <div className="d-flex pd-10">
        <div className="d-flex" style={{ width: '6%' }}></div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex flex-column align-items-start pd-t-10">
            {renderContent(comment?.content || '', 'comment' + index)}
            {comment?.attachedFiles?.map((_file: any, index: number) => (
              <Fragment key={index}>
                {_file && renderAttachment({ ..._file, createdAt: comment.createdAt })}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  };

  //render reply by email
  const renderReplyEmail = (email: any, index: number) => {
    return (
      <div className="d-flex pd-10">
        <div className="d-flex" style={{ width: '6%' }}>
          <Mail className="tx-info" />
        </div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex">
            <span className="tx-bold mg-r-5">To:</span>
            <span>{email?.to}</span>
          </div>
          <div className="d-flex flex-column align-items-start pd-t-10">
            {renderContent(email?.content, 'email' + index)}
            {/* {item?.parent && renderReply(index, item?.parent?.content)} */}
          </div>
          {email?.attachedFiles?.map((_file: any, index: number) => (
            <Fragment key={index}>
              {_file && renderAttachment({ ..._file, createdAt: email.createdAt })}
            </Fragment>
          ))}
        </div>
      </div>
    );
  };

  //render reply by call
  const renderReplyCall = (call: any, index: number) => {
    return (
      <div className="d-flex pd-10">
        <div className="d-flex" style={{ width: '6%' }}>
          <Phone className="tx-info" />
        </div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex">
            <span className="tx-bold mg-r-5">To:</span>
            <span>{call?.to}</span>
          </div>
          <div className="d-flex flex-column align-items-start pd-t-10">
            {renderContent(call?.content, 'call' + index)}
          </div>
        </div>
      </div>
    );
  };

  //render reply by sms
  const renderReplySms = (sms: any, index: number) => {
    return (
      <div className="d-flex pd-10">
        <div className="d-flex" style={{ width: '6%' }}>
          <Inbox className="tx-info" />
        </div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex">
            <span className="tx-bold mg-r-5">To:</span>
            <span>{sms?.to}</span>
          </div>
          <div className="d-flex flex-column align-items-start pd-t-10">
            {renderContent(sms?.content, 'sms' + index)}
            {/* {item?.parent && renderReply(index, item?.parent?.content)} */}
            {sms?.attachedFiles?.map((_file: any, index: number) => (
              <Fragment key={index}>
                {_file && renderAttachment({ ..._file, createdAt: sms.createdAt })}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  };

  //render reply by fax
  const renderReplyFax = (fax: any, index: number) => {
    return (
      <div className="d-flex pd-10">
        <div className="d-flex" style={{ width: '6%' }}>
          <Printer className="tx-info" />
        </div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex">
            <span className="tx-bold mg-r-5">To:</span>
            <span>{fax?.to}</span>
          </div>
          <div className="d-flex flex-column align-items-start pd-t-10">
            {renderContent(fax?.content, 'fax' + index)}
            {/* {item?.parent && renderReply(index, item?.parent?.content)} */}
          </div>
        </div>
      </div>
    );
  };

  //a comment
  const renderComment = (item: any, index: number) => {
    const { comment, email, sms, call, fax, kind, createdAt } = item;
    return (
      <div className="pos-relative pd-b-10">
        <div
          className="card w-100"
          style={{
            minHeight: '120px',
            backgroundColor:
              kind === TICKET_COMMENT_KIND_NEW
                ? '#e4f3e5'
                : kind === TICKET_COMMENT_KIND_REPLY
                  ? '#f6f6f6'
                  : '#fff0d9',
          }}
        >
          {renderHeader({ ...item, display: comment?.display })}
          {comment && renderNew({ ...comment, createdAt }, index)}
          {email && renderReplyEmail({ ...email, createdAt }, index)}
          {sms && renderReplySms({ ...sms, createdAt }, index)}
          {/* {call && renderReplyCall(call, index)} */}
          {/* {fax && renderReplyFax(fax, index)} */}
        </div>
      </div>
    );
  };

  //// console.log('comments', comments);
  //render
  return (
    <>
      <div className={classNames('', className)}>
        {comments.length === 0 && (
          <div className="card w-100">
            <NoData icon="MessageSquare" label="No comments found." />
          </div>
        )}
        {comments.map((_item: any, index: number) => (
          <Fragment key={index}>{renderComment(_item, index)}</Fragment>
        ))}
      </div>
    </>
  );
};

export default TicketReply;
