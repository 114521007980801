import usePost from '@base/hooks/usePost';
import {
  IDeskPriority,
  ITicket,
  ITicketCategory,
  ITicketChannel,
  ITicketCustomer,
  ITicketPolicy,
  ITicketTodo,
} from '@desk/ticket/types/interfaces/ticket';
import {
  DELETE_TICKET_PRODUCT,
  DELETE_TICKET_TAG,
  getListQuery,
  getViewQuery,
  GET_ALL_CATEGORIES,
  GET_ALL_CHANNELS,
  GET_ALL_POLICIES,
  GET_ALL_PRIORITIES,
  GET_ALL_PUBLIC_CATEGORIES,
  GET_ALL_PUBLIC_TAGS,
  GET_ALL_TAGS,
  GET_ALL_TICKET_KNOWLEDGE_BASE,
  GET_ALL_TICKET_TODOS,
  GET_TICKETS_AUTO_COMPLETE,
  GET_TICKET_ASSIGNED_USERS,
  GET_TICKET_CC_USERS,
  GET_TICKET_CUSTOMERS,
  GET_TICKET_VIEW,
  TICKET_COMMENTS_GET,
} from '@desk/ticket/services/graphql/ticket';
import { stringify } from 'query-string';
import { keyStringify } from '@base/utils/helpers';
import { FilterInput } from '@base/types/interfaces/common';
import usePosts from '@base/hooks/usePosts';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { User } from '@base/types/interfaces/user';
import { KnowledgeBase } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import { call } from '@deepakvishwakarma/ts-util';
import useMutationPost from '@base/hooks/useMutationPost';
import { ECategoryType } from '@desk/main/types/enums';
import { DESC } from '@base/config/constant';
import usePublicPosts from '@base/hooks/usePublicPosts';

export const useTickets = (filter: FilterInput, querySchema: string) => {
  let queryKey = ['desk_tickets', keyStringify(filter, '')];
  let params = {
    filter: filter,
  };
  const response = usePosts<ITicket[]>(queryKey, getListQuery(querySchema), params, {
    // initialData: ticketsFakeData(),
    enabled: querySchema != '',
  });
  return response;
};

export const useTicket = (id: string, querySchema: string) => {
  let queryKey = ['desk_ticket', id];
  let params = {
    id,
  };
  const response = usePost<ITicket>(queryKey, getViewQuery(querySchema), params, {
    // initialData: ticketFakeData(),
    enabled: querySchema != '',
  });
  return response;
};

export const useTicketCategories = (
  keyword: string,
  productIds: string[],
  isPublic?: boolean,
  token?: string,
) => {
  let strQuery = `(name:"${keyword}" {isAllProducts=true`;
  const notAllProductIds = productIds.filter((_ele: string) => _ele !== 'all');
  if (notAllProductIds.length > 0) {
    // strQuery += ` products={`;
    // notAllProductIds.map((_ele: string, index: number) => {
    //   if (index > 0) strQuery += ' ';
    //   strQuery += `"${_ele}"`;
    // });
    // strQuery += `}`;
    strQuery += ` products=_in_[${notAllProductIds.join(',')}]`;
  }
  strQuery += `})`;

  let response = null;
  if (isPublic) {
    response = usePublicPosts<any[]>(
      ['tracking_ticketCategories', keyword, productIds.join(',')],
      GET_ALL_PUBLIC_CATEGORIES,
      { filter: { query: strQuery }, token },
      {
        enabled: productIds.length > 0,
      },
    );
  } else {
    response = usePosts<any[]>(
      ['desk_ticketCategories', keyword, productIds.join(',')],
      GET_ALL_CATEGORIES,
      { filter: { query: strQuery } },
      {
        enabled: productIds.length > 0,
      },
    );
  }

  return response;
};

export const useTicketTags = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['desk_tags', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<ITicketCategory>>(queryKey, GET_ALL_TAGS, params, {
    // initialData: ticketTagsFakeData(),
  });
  return response;
};

export const usePublicTicketTags = (keyword: string, token: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['tracking_tags', keyword];
  let params = {
    filter,
    token,
  };
  const response = usePublicPosts<ITicketCategory[]>(queryKey, GET_ALL_PUBLIC_TAGS, params, {
    // initialData: ticketTagsFakeData(),
  });
  return response;
};

// export const useTicketProcesses = (keyword: string) => {
//   let filter: FilterInput = {
//     keyword: keyword,
//   };
//   let queryKey = ['desk_ticketProcesses', keyword];
//   let params = {
//     filter,
//   };
//   const response = usePost<BaseResponse<ITicketCategory>>(queryKey, GET_ALL_TAGS, params, {
//     initialData: ticketProcessesFakeData(),
//   });
//   return response;
// };
export const useTicketChannels = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['desk_channels', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<ITicketChannel>>(queryKey, GET_ALL_CHANNELS, params, {
    // initialData: ticketChannelsFakeData(),
  });
  return response;
};
export const useTicketPolicies = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['desk_policies', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<ITicketPolicy>>(queryKey, GET_ALL_POLICIES, params, {
    // initialData: ticketPoliciesFakeData(),
  });
  return response;
};
export const useTicketKnowledgeBases = (id: string) => {
  let queryKey = ['desk_ticketKbs', id];
  let params = {
    id,
  };
  const response = usePost<BaseResponse<KnowledgeBase>>(
    queryKey,
    GET_ALL_TICKET_KNOWLEDGE_BASE,
    params,
    {
      // initialData: ticketKnowledgeBasesFakeData(),
    },
  );
  return response;
};

export const useTicketAssignedUsers = (id: string) => {
  let queryKey = ['desk_ticketAssignedUsers', id];
  let params = {
    id,
  };
  const response = usePost<BaseResponse<User>>(queryKey, GET_TICKET_ASSIGNED_USERS, params, {
    // initialData: ticketAssignedRepsFakeData(),
  });
  return response;
};
export const useTicketCcUsers = (id: string) => {
  let queryKey = ['desk_ticketCcUsers', id];
  let params = {
    id,
  };
  const response = usePost<BaseResponse<User>>(queryKey, GET_TICKET_CC_USERS, params, {
    // initialData: ticketAssignedRepsFakeData(),
  });
  return response;
};

export const useTicketCustomers = (id: string) => {
  let queryKey = ['desk_ticketCustomers', id];
  let params = {
    id,
  };
  const response = usePost<BaseResponse<ITicketCustomer>>(queryKey, GET_TICKET_CUSTOMERS, params, {
    // initialData: ticketCustomersFakeData(),
  });
  return response;
};

export const useDeskPriorities = (keyword: string) => {
  let queryKey = ['desk_priorities', keyword];
  let filter: FilterInput = {
    keyword: keyword,
  };
  const response = usePost<BaseResponse<IDeskPriority>>(
    queryKey,
    GET_ALL_PRIORITIES,
    {
      filter,
    },
    {
      // initialData: ticketCustomersFakeData(),
    },
  );
  return response;
};

export const useDeleteTicketTag = () => {
  const mDeleteTag: any = useMutationPost<BaseMutationResponse>(
    DELETE_TICKET_TAG,
    'customer_deleteTicketTag',
  );
  return mDeleteTag;
};
export const useDeleteTicketProduct = () => {
  const mDeleteProduct: any = useMutationPost<BaseMutationResponse>(
    DELETE_TICKET_PRODUCT,
    'customer_deleteTicketProduct',
  );
  return mDeleteProduct;
};

/** ticket comment */
export const useTicketComments = ({ ticketId, paging }: any) => {
  const graphQLKey = 'desk_ticketComments';

  //build filter
  let filtersQuery: FilterInput = {
    paging: {
      page: paging.page || 1,
      size: paging.size || 10,
    },
    sort: { field: 'createdAt', orderBy: DESC },
  };
  //build query
  filtersQuery.query = `ticket=${ticketId}`;
  //get params
  let params = {
    filter: filtersQuery,
  };

  const usePostResult = usePosts<any[]>(
    [graphQLKey, keyStringify(filtersQuery, '')],
    TICKET_COMMENTS_GET,
    params,
    {
      enabled: ticketId.length > 0,
    },
  );

  return usePostResult;
};
export const useTicketTodos = (menuSource: string, menuSourceId: string) => {
  let queryKey = ['activity_activities', menuSource, menuSourceId];
  let params = {
    source: {
      menu: menuSource,
      id: menuSourceId,
    },
    filter: {
      query: '',
    },
  };
  const response = usePost<BaseResponse<ITicketTodo>>(queryKey, GET_ALL_TICKET_TODOS, params, {
    // initialData: ticketKnowledgeBasesFakeData(),
  });
  return response;
};

export const useTicketAutoComplete = (params: any) => {
  const query = params?.filter?.query ?? '';
  let queryKey = ['desk_tickets', query];
  const response = usePost<BaseResponse<ITicket>>(queryKey, GET_TICKETS_AUTO_COMPLETE, params, {});
  return response;
};

export const useTicketView = (id: string) => {
  let queryKey = ['desk_ticket', id];
  const response = usePost<ITicket>(queryKey, GET_TICKET_VIEW, { id }, { enabled: id.length > 0 });
  return response;
};
