import React, { useEffect, useState } from 'react';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import CategorySelect from '../category-select';

interface IProductCategoryProps {
  hideProductLabel?: boolean;
  isPublic?: boolean;
  token?: string;
  value?: any;
  onChange?: any;
}

const ProductCategory: React.FC<IProductCategoryProps> = (props) => {
  const { isPublic = false, token, hideProductLabel = false, value, onChange } = props;
  //// console.log('edit value', value);
  //state
  const [curProduct, setCurProduct] = useState<any>(null);
  const [curCategory, setCurCategory] = useState<any>(null);
  //initial value
  useEffect(() => {
    if (value) {
      if (value?.product) {
        if (value.product?.id !== curProduct?.id) {
          let product = { ...value.product, label: value.product.name };
          setCurProduct(product);
        }
      }
      if (value?.category) {
        if (value.category?.id !== curCategory?.id) {
          setCurCategory(value.category);
        }
      }
    } else {
      setCurProduct(null);
      setCurCategory(null);
    }
  }, [value]);

  //value change
  const handleProductChange = (newProduct: any) => {
    setCurProduct(newProduct);
    setCurCategory(null);
    //callback
    onChange && onChange({ product: newProduct, category: null });
  };

  //value change
  const handleCategoryChange = (newCategory: any) => {
    setCurCategory(newCategory);
    //callback
    onChange && onChange({ product: curProduct, category: newCategory });
  };

  return (
    <div className="pos-relative">
      <div className="form-group">
        {!hideProductLabel && <label className="form-item-title">Product</label>}
        <ProductAutoComplete
          value={[curProduct]}
          onChange={handleProductChange}
          outSide={true}
          single={true}
          //showAllOption={false}
          hiddenAdd={isPublic}
          isPublic={isPublic}
          token={token}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Category</label>
        <CategorySelect
          isPublic={isPublic}
          token={token}
          productIds={curProduct?.id ? [curProduct.id] : []}
          value={curCategory}
          onChange={handleCategoryChange}
        />
      </div>
    </div>
  );
};

export default ProductCategory;
