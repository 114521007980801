import React, { useEffect, useState } from 'react';
import withMiModal from '@base/hooks/hocs/withMiModal';
import { Button } from '@base/components/form';
import ProductCategory from '../product-category';
import PrioritySelect from '@base/containers/priority-select';

interface IWriteFolderFormProps {
  fullScreen?: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onChange?: (params: any) => void;
}

const ProductCategoryModal: React.FC<IWriteFolderFormProps> = (props) => {
  const { isLoading = false, onClose, onChange, fullScreen = false } = props;
  //state
  const [selectedItem, setSelectedItem] = useState<any>(null);

  let formHeight = 'calc(100vh - 300px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 120px)';
  }

  //change
  const handleSaveChange = () => {
    if (selectedItem) {
      onChange && onChange(selectedItem);
      onClose();
    }
  };

  //// console.log('selectedItem', selectedItem);
  return (
    <div className="pos-relative">
      <div
        className="pd-10 scroll-box"
        style={fullScreen ? { height: formHeight } : { maxHeight: formHeight }}
      >
        <div className="card">
          <div className="card-body">
            <ProductCategory
              value={null}
              onChange={(item: any) => {
                const curRule = item?.category?.rules?.length > 0 ? item.category.rules[0] : null;
                setSelectedItem({
                  ...item,
                  priority: curRule
                    ? {
                        priority: curRule.priority.keyName,
                        languageKey: curRule.priority.languageKey,
                      }
                    : null,
                });
              }}
            />
            <div className="form-group">
              <label className="form-item-title">Priority</label>
              <PrioritySelect isDisabled={true} value={selectedItem?.priority || null} />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={onClose}>
            Close
          </button>
          <Button
            color="primary"
            name="Change"
            disabled={selectedItem === null || selectedItem?.assignedUser === null}
            loading={isLoading}
            onClick={handleSaveChange}
          />
        </div>
      </div>
    </div>
  );
};

export default withMiModal(ProductCategoryModal);
