// import * as keyNames from '@desk/ticket/config/data-types';
import * as keyNames from '@desk/ticket/config/key-names';
import * as components from './components';
import * as commonComponents from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import {
  //useDeskPriorities,
  //useTicketCategories,
  useTicketChannels,
  //useTicketPolicies,
  useTicketTags,
} from '@desk/ticket/services/ticket-service';
import { IWriteConfig } from '@base/types/interfaces/common';
import { useGetModuleProcesses } from '@settings/process/hooks/useProcess';

const writeConfig: IWriteConfig = {
  // [keyNames.KEY_TICKET_CODE]: {
  //   component: commonComponents.InputCodeGenerator,
  //   componentProps: {
  //     menu: 'ticket',
  //   },
  //   defaultValue: '',
  // },
  [keyNames.KEY_TICKET_SUBJECT]: {
    component: commonComponents.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
  },
  [keyNames.KEY_TICKET_PRIORITY]: {
    component: components.PrioritySelect,
    componentProps: {
      isDisabled: true,
    },
    validate: {},
    defaultValue: null,
    showFullRow: false,
    parseParam: (value: any) => value?.priority || '',
  },
  [keyNames.KEY_TICKET_STATUS]: {
    component: commonComponents.DataSourceSelect,
    componentProps: {
      single: true,
      sourceKey: 'ticket_status',
      sourceType: 'field',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: 'STATUS_NEW',
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => value?.value || 'STATUS_NEW',
  },
  [keyNames.KEY_TICKET_CLASSIFICATION]: {
    component: components.Classification,
    validate: {},
    defaultValue: null,
    showFullRow: true,
    parseParam: (value: any) => {
      const newParams: any = [];
      if (value?.language) {
        newParams.push({
          classification: {
            id: value.language.id,
            name: value.language.name,
          },
          value: value.language.value,
        });
      }
      if (value?.region) {
        newParams.push({
          classification: {
            id: value.region.id,
            name: value.region.name,
          },
          value: value.region.value,
        });
      }
      return newParams;
    },
  },
  [keyNames.KEY_TICKET_ASSIGN_GROUP]: {
    component: components.AssignGroupAutoComplete,
    componentProps: {
      single: true,
    },
    validate: {},
    defaultValue: null,
    showFullRow: true,
    parseParam: (value: any) => (value ? { id: value.id, name: value.name } : {}),
  },
  [keyNames.KEY_TICKET_ASSIGN_USER]: {
    component: components.AssignRepAutoComplete,
    componentProps: {
      single: true,
    },
    validate: {},
    defaultValue: null,
    showFullRow: true,
    parseParam: (value: any) => {
      return value
        ? {
            user: { id: value.id, name: value.name },
            group: value?.group || null, //{ id: '', name: '' }
          }
        : {};
    },
  },
  [keyNames.KEY_TICKET_CATEGORY]: {
    component: components.CategorySelect,
    componentProps: {},
    defaultValue: null,
    showFullRow: false,
    validate: {
      required: validators.required,
    },
    parseParam: (value: any) => (value ? { id: value.id, name: value.name } : {}),
  },
  [keyNames.KEY_TICKET_PROCESS]: {
    component: commonComponents.Lookup,
    componentProps: {
      // fetchList: useTicketProcesses,
      fetchList: useGetModuleProcesses,
      fieldValue: 'id',
      fieldLabel: 'name',
      extraParams: { module: 'MODULE_DESK' },
      isSearch: false,
    },
    validate: {},
    defaultValue: null,
    showFullRow: false,
    parseParam: (value: any) => {
      return value
        ? {
            id: value.id,
            name: value.name,
          }
        : {};
    },
  },
  [keyNames.KEY_TICKET_CHANNEL]: {
    component: commonComponents.Lookup,
    componentProps: {
      fetchList: useTicketChannels,
      fieldValue: 'id',
      fieldLabel: 'name',
    },
    validate: {},
    defaultValue: null,
    showFullRow: false,
    parseParam: (value: any) => {
      return value
        ? {
            id: value.id,
            name: value.name,
          }
        : {};
    },
  },
  // [keyNames.KEY_TICKET_POLICY]: {
  //   component: commonComponents.Lookup,
  //   componentProps: {
  //     fetchList: useTicketPolicies,
  //     fieldValue: 'id',
  //     fieldLabel: 'name',
  //   },
  //   validate: {},
  //   defaultValue: null,
  //   showFullRow: false,
  //   parseParam: (value: any) => {
  //     return {
  //       id: value.id,
  //       name: value.name,
  //     };
  //   },
  // },
  [keyNames.KEY_TICKET_CONTENT]: {
    component: commonComponents.Editor,
    componentProps: {},
    validate: {},
    defaultValue: '',
    showFullRow: true,
  },
  [keyNames.KEY_TICKET_CUSTOMER]: {
    component: components.CustomerAutoComplete,
    componentProps: {
      single: true,
      showAvatar: true,
      addLabel: 'Add new customer',
    },
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    showFullRow: false,
    parseParam: (value: any) => {
      return value
        ? {
            id: value.id,
            name: value.name,
          }
        : {};
    },
  },
  [keyNames.KEY_TICKET_CONTACT]: {
    component: components.CustomerAutoComplete,
    componentProps: {
      single: true,
      showAvatar: true,
      placeholder: 'Type or click to select a contact...',
      category: 'contact',
      addLabel: 'Add new contact',
    },
    validate: {},
    defaultValue: null,
    showFullRow: false,
    parseParam: (value: any) => {
      return value
        ? {
            id: value.id,
            name: value.name,
          }
        : {};
    },
  },
  [keyNames.KEY_TICKET_PRODUCT]: {
    component: components.ProductAutoComplete,
    componentProps: {
      single: true,
    },
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    showFullRow: false,
    parseParam: (value: any) => {
      return value
        ? {
            id: value.id,
            name: value.name,
          }
        : {};
    },
  },
  // [keyNames.KEY_TICKET_DUE_DATE]: {
  //   component: components.Date,
  //   componentProps: {},
  //   validate: {},
  // },
  // [keyNames.KEY_TICKET_DURATION]: {
  //   component: components.Duration, //TODO
  //   componentProps: {},
  //   validate: {},
  //   showFullRow: true,
  //   defaultValue: { durationUnit: 'UNIT_DAY', duration: 86400 },
  // },
  [keyNames.KEY_TICKET_TAG]: {
    component: commonComponents.Tags,
    componentProps: {
      fetchList: useTicketTags,
      fieldValue: 'id',
      fieldLabel: 'name',
    },
    validate: {},
    defaultValue: null,
    showFullRow: true,
    parseParam: (value: any) => {
      return value
        ? value?.map((_item: any) => {
            return {
              id: _item.id,
              name: _item.name,
            };
          })
        : [];
    },
  },
};
export default writeConfig;
