import UserAutoComplete from '@base/containers/user-auto-complete';
import classnames from 'classnames';
import React from 'react';

interface IEditProps {
  value: any;
  onChange: (params: any) => {};
  errors: any;
  componentProps: any;
}
const Edit: React.FC<IEditProps> = (props: IEditProps) => {
  const { value = [], onChange = () => {}, errors = {}, componentProps } = props;

  return (
    <div>
      <div
        className={classnames({
          'is-required': errors?.isRequired,
        })}
      >
        <UserAutoComplete value={value} {...componentProps} onChange={onChange} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default Edit;
