import {
  ActivityStatusOptions,
  ACTIVITY_ADD_OPTIONS,
  ACTIVITY_ICONS,
  ACTIVITY_MENU_KEYS,
} from '@activity/config/constants';
import { NoData, SpanLang } from '@base/components';
import Loading from '@base/components/loading';
import { useTicketTodos } from '@desk/ticket/services/ticket-service';
import { ITicketTodo } from '@desk/ticket/types/interfaces/ticket';
import { useEffect, useState } from 'react';
import { ChevronDown, Plus, X } from 'react-feather';
import { keys } from 'lodash';
import WritePage from '@activity/pages/write';
import { Source } from '@activity/types/interfaces';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_ACTIVITY } from '@activity/services/mywork';
import { toast } from 'react-toastify';
import { MENU_ACTIVITY } from '@base/config/menus';

interface TicketTodoProps {
  menuSource: string;
  menuSourceId: string;
}
const TicketTodo = (props: TicketTodoProps) => {
  const { menuSourceId } = props;
  const addOptions: any = ACTIVITY_ADD_OPTIONS;
  const source: Source = {
    id: menuSourceId,
    menu: 'MENU_DESK',
  };
  //state
  const [isSaving, setIsSaving] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [actType, setActType] = useState('task');
  const [fullScreen, setFullScreen] = useState(false); //for write page
  // recoil
  const [isOpenWrite, setIsOpenWrite] = useState(false);
  //get list
  const { data, isLoading, refetch } = useTicketTodos('MENU_DESK', menuSourceId);
  const handleDelete = (item: ITicketTodo) => {
    setIsSaving(true);
    mDeleteTodo.mutate(
      { ids: [item.id] },
      {
        onSuccess: (res: BaseMutationResponse) => {
          // console.log('mDeleteTodo', res);
          // refetch();
          const nItems = items.filter((temp: ITicketTodo) => {
            return item.id != temp.id;
          });
          setItems(nItems);
          setIsSaving(false);
          toast.success('Todo has removed!');
        },
        onError: (res: any) => {
          setIsSaving(false);
        },
      },
    );
  };
  const handleAdd = (key: string) => {
    setActType(key);
    setIsOpenWrite(true);
  };
  const mDeleteTodo: any = useMutationPost<BaseMutationResponse>(
    DELETE_ACTIVITY,
    'activity_deleteActivity',
  );
  //init list
  useEffect(() => {
    if (data?.results) {
      setItems(data.results);
    } else {
      setItems([]);
    }
  }, [data]);

  /** ======================================== RENDER ======================================== */

  //items view
  const renderItems = () => {
    return (
      <>
        {items.length > 0 &&
          items.map((item: ITicketTodo, index: number) => {
            const foundStatusLang = ActivityStatusOptions.find((s: any) => {
              return s.value === item.status;
            });
            return (
              <div
                className="d-flex flex-wrap align-items-center mg-b-3 pd-x-10 pd-y-3"
                key={item.id}
              >
                <a
                  href={`/${MENU_ACTIVITY}/mywork/${ACTIVITY_MENU_KEYS[item.type]}_${item.id}`}
                  target="_blank"
                  title={item.subject}
                  className="mg-r-10"
                >
                  {ACTIVITY_ICONS[item.type] ? (
                    <span className="todo-icon">{ACTIVITY_ICONS[item.type]}</span>
                  ) : (
                    ''
                  )}
                  {item.subject ? item.subject : ''}
                </a>
                <div className="d-flex align-items-center mg-l-auto">
                  <div className="tags">
                    <span className="tag-item" key={item.status}>
                      {foundStatusLang ? <SpanLang keyLang={foundStatusLang.label} /> : item.status}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link btn-icon han-tooltip--top"
                    data-han-tooltip="Delete"
                    onClick={() => {
                      handleDelete(item);
                    }}
                  >
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <div className="pos-relative mg-b-10">
        {(isLoading || isSaving) && <Loading />}
        {!isLoading && renderItems()}
        {items.length == 0 && <NoData icon={'Package'} iconType={'feather'} />}
      </div>
      <div className="btn-group dropdown">
        <button type="button" className="btn btn-link  pd-x-0" onClick={() => handleAdd('task')}>
          <Plus /> New
        </button>
        {addOptions && keys(addOptions).length > 0 && (
          <>
            <button
              type="button"
              className="btn btn-link dropdown-toggle-split pd-x-5"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <>
                {keys(addOptions).map((key: string, i) => (
                  <button
                    key={i}
                    type="button"
                    className="dropdown-item"
                    onClick={() => handleAdd(key)}
                  >
                    {addOptions[key].icon ? addOptions[key].icon : ''} {addOptions[key].name}
                  </button>
                ))}
              </>
            </div>
          </>
        )}
      </div>
      {isOpenWrite && (
        <WritePage
          title={<SpanLang keyLang={`crm_new_${actType}`} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          //defaultValue={writeDefaultValue}
          isOpen={isOpenWrite}
          onClose={() => setIsOpenWrite(false)}
          onReload={refetch}
          menuApi={`activity_${actType}`}
          source={source}
        />
      )}
    </>
  );
};

export default TicketTodo;
