import { NoData } from '@base/components';
import Loading from '@base/components/loading';
import { KnowledgeTextView } from '@base/containers/quick-view';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { KnowledgeBase } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import { ADD_TICKET_KB, DELETE_TICKET_KB } from '@desk/ticket/services/graphql/ticket';
import { useTicketKnowledgeBases } from '@desk/ticket/services/ticket-service';
import { ITicketKB } from '@desk/ticket/types/interfaces/ticket';
import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import KnowledgeAutoComplete from '../knowledge-auto-complete';
// import TicketAutoComplete from '../ticket-auto-complete';

interface ITicketKnowledgeBaseProps {
  menuSource: string;
  menuSourceId: string;
}
const TicketKnowledgeBase: React.FC<ITicketKnowledgeBaseProps> = (props) => {
  const { menuSourceId } = props;
  //state
  const [isSaving, setIsSaving] = useState(false);
  const [items, setItems] = useState<any>([]);

  //get list
  const { data, isLoading, refetch } = useTicketKnowledgeBases(menuSourceId);

  //save
  const mAddTicketKb: any = useMutationPost<BaseMutationResponse>(
    ADD_TICKET_KB,
    'desk_createTicketKb',
    {
      onSuccess: (res: any) => {
        toast.success('This knowledge has added!');
        refetch();
        setIsSaving(false);
      },
      onError: (res: any) => {
        setIsSaving(false);
      },
    },
  );

  //delete
  const mDeleteTicketKb: any = useMutationPost<BaseMutationResponse>(
    DELETE_TICKET_KB,
    'desk_deleteTicketKb',
    {
      onSuccess: (res: any) => {
        toast.success('This knowledge has deleted!');
        refetch();
        setIsSaving(false);
      },
      onError: (res: any) => {
        setIsSaving(false);
      },
    },
  );

  //assign KB to ticket
  const handleAssign = (item: KnowledgeBase) => {
    setIsSaving(true);
    mAddTicketKb.mutate({
      id: menuSourceId,
      kb: {
        id: item.id,
        name: item.subject,
      },
    });
  };

  //delete KB
  const handleDelete = (item: ITicketKB) => {
    setIsSaving(true);
    mDeleteTicketKb.mutate({
      id: menuSourceId,
      kbId: item.knowledge?.id,
    });
  };

  //init list
  useEffect(() => {
    if (data?.results) {
      setItems(data.results);
    } else {
      setItems([]);
    }
  }, [data]);

  /** ======================================== RENDER ======================================== */

  //items view
  const renderItems = () => {
    return (
      <>
        {items.length > 0 &&
          items.map((item: any, index: number) => {
            return (
              <div
                className="d-flex flex-wrap align-items-center mg-b-3 pd-x-10 pd-y-3"
                key={item.id}
              >
                {/* <a
                  target="_blank"
                  onClick={() => {
                    handleShowDetail(item.knowledge?.id ?? '');
                  }}
                  title={item.knowledge?.subject ? item.knowledge?.subject : ''}
                  className="mg-r-10 pointer"
                >
                  {item.knowledge?.subject ? item.knowledge?.subject : ''}
                </a> */}
                <KnowledgeTextView
                  value={{ id: item.knowledge?.id, name: item.knowledge?.subject }}
                />
                <div className="d-flex align-items-center mg-l-auto">
                  <div className="tags">
                    {item.knowledge?.tags
                      ? item.knowledge.tags.map((tag: any) => {
                          return (
                            <span className="tag-item" key={tag.id}>
                              {tag.name}
                            </span>
                          );
                        })
                      : ''}
                  </div>
                  <button
                    type="button"
                    className="btn btn-link btn-icon han-tooltip--top"
                    data-han-tooltip="Delete"
                    onClick={() => {
                      handleDelete(item);
                    }}
                  >
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <div className="pos-relative mg-b-10">
        {(isLoading || isSaving) && <Loading />}
        {!isLoading && renderItems()}
        {items.length == 0 && <NoData icon={'Package'} iconType={'feather'} />}
      </div>
      <KnowledgeAutoComplete
        visible={false}
        addLabel={'Add new knowledge'}
        onAdd={() => console.log('add...')}
        onChange={handleAssign}
        single={true}
      />
      {/* {showDetail && <KBDetailModal id={viewId} onClose={handleCloseDetail} />} */}
      {/* <TicketAutoComplete
        visible={false}
        addLabel={'Add new ticket'}
        onAdd={() => // console.log('add...')}
        onChange={handleAssign}
        single={true}
      /> */}
    </>
  );
};

export default TicketKnowledgeBase;
