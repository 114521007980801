import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import Button from '@base/components/form/button';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { TICKET_COMMENT_CREATE } from '@desk/ticket/services/graphql/ticket';
import TuiEditor from '@base/components/form/tui-editor';
import UploadFile from '@base/components/form/upload/file';
import * as keyNames from '@desk/ticket/config/key-names';
import { ChevronUp } from 'react-feather';
import {
  TICKET_COMMENT_DISPLAY_PRIVATE,
  TICKET_COMMENT_DISPLAY_PUBLIC,
  TICKET_COMMENT_KIND_NEW,
} from '@desk/ticket/config/contants';
import { useUploadMutation } from '@base/hooks/useFileUploadMutation';

interface IWriteReplyFormProps {
  ticketId: string;
  ticketName: string;
  onReload?: () => void;
  onClose?: () => void;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteCommentForm: React.FC<IWriteReplyFormProps> = (props) => {
  const { ticketId, ticketName, onReload, onClose } = props;
  //const { t } = useTranslation();
  //state
  const [isPublic, setIsPublic] = useState(false);
  const [curFileIndex, setCurFileIndex] = useState<number>(-1);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState<number>(-1);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      [keyNames.KEY_TICKET_REPLY_MESSAGE]: '',
      [keyNames.KEY_TICKET_REPLY_FILE]: [],
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    TICKET_COMMENT_CREATE,
    'desk_createTicketComment',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Created ticket comment successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during creating: ' + JSON.parse(error).message);
      },
    },
  );

  // upload
  const mUpload: any = useUploadMutation<BaseMutationResponse>(
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //toast.success('Uploaded successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.error('There is error during uploading: ' + JSON.parse(error).message);
      },
    },
    // (pEvent: ProgressEvent, partsNumber: number, partIndex: number, uploadId?: string) =>
    //   uploadProgressHandler(pEvent, partsNumber, partIndex, uploadId),
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      // refecth data
      onReload && onReload();
      onClose && onClose();
    }
  }, [mutationAdd.isSuccess]);

  //upload success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mUpload);
    if (mUpload.isSuccess) {
      const newUploadedFiles = [...uploadedFiles];
      const selectedFiles: any = getValues()[keyNames.KEY_TICKET_REPLY_FILE];
      const newUpload = {
        objectId: mUpload.data.id, //upload id
        objectUrl: mUpload.data.url, //download url
        name: selectedFiles[curFileIndex].name,
        size: selectedFiles[curFileIndex].size,
        contentType: selectedFiles[curFileIndex].type,
      };
      newUploadedFiles.push(newUpload);
      setUploadedFiles(newUploadedFiles);
      //next file uploading
      setLastUploadedFileIndex(curFileIndex);
    }
  }, [mUpload.isSuccess]);

  //submit form
  const onSubmit = ({ formData, isPublic }: any) => {
    //upload files
    const uploadFiles = formData[keyNames.KEY_TICKET_REPLY_FILE];
    if (uploadFiles.length > 0) {
      if (curFileIndex === -1) {
        setCurFileIndex(lastUploadedFileIndex === -1 ? 0 : lastUploadedFileIndex + 1);
      }
    } else {
      const params = {
        comment: {
          ticket: { id: ticketId, name: ticketName },
          kind: TICKET_COMMENT_KIND_NEW,
          comment: {
            content: formData[keyNames.KEY_TICKET_REPLY_MESSAGE],
            display: isPublic ? TICKET_COMMENT_DISPLAY_PUBLIC : TICKET_COMMENT_DISPLAY_PRIVATE,
          },
        },
      };
      mutationAdd.mutate(params);
    }
  };

  /** =============================== UPLOAD HANDLER =========================== */
  //upload current file
  useEffect(() => {
    //// console.log('curFileIndex', curFileIndex);
    const selectedFiles = getValues()[keyNames.KEY_TICKET_REPLY_FILE];
    if (curFileIndex !== -1) {
      mUpload.mutate(selectedFiles[curFileIndex]);
    }
  }, [curFileIndex]);

  //next file - last file
  useEffect(() => {
    if (lastUploadedFileIndex === -1) {
      return;
    }
    const selectedFiles = getValues()[keyNames.KEY_TICKET_REPLY_FILE];
    const isLastFile = lastUploadedFileIndex === selectedFiles.length - 1;
    if (isLastFile) {
      setCurFileIndex(-1);
      //start save to db
      const params = {
        comment: {
          ticket: { id: ticketId, name: ticketName },
          kind: TICKET_COMMENT_KIND_NEW,
          comment: {
            content: getValues()[keyNames.KEY_TICKET_REPLY_MESSAGE],
            display: isPublic ? TICKET_COMMENT_DISPLAY_PUBLIC : TICKET_COMMENT_DISPLAY_PRIVATE,
            attachedFiles: uploadedFiles,
          },
        },
      };
      mutationAdd.mutate(params);
    } else {
      //next uploaded
      const nextFileIndex = curFileIndex + 1;
      setCurFileIndex(nextFileIndex);
    }
  }, [lastUploadedFileIndex]);

  /** =============================== END UPLOAD HANDLER =========================== */

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  //form fields
  const renderFields = () => {
    return (
      <div className="pd-15 scroll-box" style={{ height: 'calc(100vh - 150px)' }}>
        <div className="form-group">
          <label className="form-item-title wd-150">
            Comment<span className="tx-danger">*</span>
          </label>
          <div className="flex-fill">
            <Controller
              name={keyNames.KEY_TICKET_REPLY_MESSAGE}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }: any) => (
                <TuiEditor value={value} onChange={onChange} />
              )}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title wd-150">Attached File</label>
          <div className="flex-fill">
            <Controller
              name={keyNames.KEY_TICKET_REPLY_FILE}
              control={control}
              //rules={{ required: true }}
              render={({ field: { value, onChange } }: any) => (
                <UploadFile canDrop={true} multiple={true} value={value} onChange={onChange} />
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button
            type="button"
            className="btn btn-light mg-r-5"
            onClick={() => onClose && onClose()}
          >
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                setIsPublic(true);
                handleSubmit((data) => onSubmit({ formData: data, isPublic: true }), onError)();
              }}
              disabled={mutationAdd.isLoading || mUpload.isLoading || !isValid}
              loading={mutationAdd.isLoading || mUpload.isLoading}
              name="Save as Public"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  setIsPublic(false);
                  handleSubmit((data) => onSubmit({ formData: data, isPublic: false }), onError)();
                }}
                disabled={mutationAdd.isLoading || mUpload.isLoading || !isValid}
                loading={mutationAdd.isLoading || mUpload.isLoading}
                name="Save as Private"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form values', getValues()); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        {renderFields()}
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(WriteCommentForm);
