import Icon from '@base/assets/icons/svg-icons';
import React from 'react';
import { Bell, Calendar, ChevronDown, Pause } from 'react-feather';

interface IDashboardProps {}
const Dashboard: React.FC<IDashboardProps> = (props: IDashboardProps) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-8">
          <div className="row row-xs mg-b-20">
            <div className="col-md-6 col-xl-3 d-flex">
              <div className="d-flex flex-grow-1 rounded-10 pd-10 bg-pink tx-white">
                <div className="d-flex justify-content-center align-items-center pd-10 rounded-10 dashboard-icon">
                  <Calendar />
                </div>
                <div className="d-flex flex-grow-1 flex-column justify-content-center mg-l-15">
                  <button
                    type="button"
                    className="btn pd-0 text-left text-white tx-24 tx-semibold lh-1"
                    data-toggle="modal"
                    data-target="#ticketList"
                  >
                    10
                  </button>
                  <span className="tx-12">Overdue Tickets</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 mg-t-10 mg-md-t-0 d-flex">
              <div className="d-flex flex-grow-1 rounded-10 pd-10 bg-info tx-white">
                <div className="d-flex justify-content-center align-items-center pd-10 rounded-10 dashboard-icon">
                  {Icon('ticket')}
                </div>
                <div className="d-flex flex-grow-1 flex-column justify-content-center mg-l-15">
                  <button
                    type="button"
                    className="btn pd-0 text-left text-white tx-24 tx-semibold lh-1"
                    data-toggle="modal"
                    data-target="#ticketList"
                  >
                    20
                  </button>
                  <span className="tx-12">Open Tickets</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 mg-t-10 mg-xl-t-0 d-flex">
              <div className="d-flex flex-grow-1 rounded-10 pd-10 bg-warning tx-white">
                <div className="d-flex justify-content-center align-items-center pd-10 rounded-10 dashboard-icon">
                  <Pause />
                </div>
                <div className="d-flex flex-grow-1 flex-column justify-content-center mg-l-15">
                  <button
                    type="button"
                    className="btn pd-0 text-left text-white tx-24 tx-semibold lh-1"
                    data-toggle="modal"
                    data-target="#ticketList"
                  >
                    70
                  </button>
                  <span className="tx-12">On Hold</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 mg-t-10 mg-xl-t-0 d-flex">
              <div className="d-flex flex-grow-1 rounded-10 pd-10 bg-orange tx-white">
                <div className="d-flex justify-content-center align-items-center pd-10 rounded-10 dashboard-icon">
                  <Bell />
                </div>
                <div className="d-flex flex-grow-1 flex-column justify-content-center mg-l-15">
                  <button
                    type="button"
                    className="btn pd-0 text-left text-white tx-24 tx-semibold lh-1"
                    data-toggle="modal"
                    data-target="#ticketList"
                  >
                    20
                  </button>
                  <span className="tx-12">Due Today</span>
                </div>
              </div>
            </div>
          </div>

          {/* Activities Progress Rate */}
          <div className="dashboard-section">
            <div className="dashboard-section-header">
              <span className="dashboard-section-title">Performance Metrics</span>
              <div className="dropdown mg-l-auto">
                <button type="button" className="btn pd-0" data-toggle="dropdown">
                  Today
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right wd-280">
                  <button type="button" className="dropdown-item">
                    This Week
                  </button>
                  <button type="button" className="dropdown-item">
                    This Month
                  </button>
                  <div className="dropdown-divider"></div>
                  <div className="dropdown-header">Custom</div>
                  <div className="input-group pd-x-25 pd-y-5">
                    <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-section-body pd-0-f">
              <div className="row no-gutters">
                <div className="col-md-6 bd-b">
                  <div className="pd-x-20 pd-t-20 tx-semibold">Tickets by Category</div>
                </div>
                <div className="col-md-6 bd-b bd-md-l">
                  <div className="pd-x-20 pd-t-20 tx-semibold">Tickets by Channel</div>
                </div>
                <div className="col-md-6 bd-b bd-md-b-0">
                  <div className="pd-x-20 pd-t-20 tx-semibold">Tickets by Assigned</div>
                </div>
                <div className="col-md-6 bd-md-l">
                  <div className="pd-x-20 pd-t-20 tx-semibold">Avg. Tickets Close Time</div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          {/* New Tickets */}
          <div className="dashboard-section mg-b-20">
            <div className="dashboard-section-header">
              <span className="dashboard-section-title">New Tickets</span>
              <button type="button" className="btn btn-link mg-l-auto pd-0 tx-12">
                View All
              </button>
            </div>
            <div
              className="dashboard-section-body pd-y-0-f overflow-auto"
              style={{ maxHeight: '250px' }}
            >
              <ul className="list-unstyled mg-0">
                <li className="pd-y-15">
                  <span className="badge badge-danger">Urgent</span>
                  <a href="" target="_blank" className="link-02 mg-l-5">
                    Subject 1
                  </a>
                </li>
                <li className="pd-y-15 bd-t">
                  <span className="badge bg-orange tx-white">High</span>
                  <a href="" target="_blank" className="link-02 mg-l-5">
                    Subject 2
                  </a>
                </li>
                <li className="pd-y-15 bd-t">
                  <span className="badge badge-info">Normal</span>
                  <a href="" target="_blank" className="link-02 mg-l-5">
                    Subject 3
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Top agents by closed tickets */}
          <div className="dashboard-section">
            <div className="dashboard-section-header">
              <span className="dashboard-section-title">Top agents by closed tickets</span>
              <div className="dropdown mg-l-auto">
                <button type="button" className="btn pd-0" data-toggle="dropdown">
                  All
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Group 1
                  </button>
                </div>
              </div>
            </div>
            <div className="dashboard-section-body pd-y-0-f">
              <ul className="list-unstyled mg-0">
                <li className="d-flex align-items-center pd-y-10">
                  <div className="media align-items-center">
                    <span className="mg-r-10 tx-warning">{Icon('first_grade')}</span>
                    <div className="avatar avatar-sm flex-shrink-0">
                      <img
                        src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                        className="rounded-circle"
                        alt=""
                      />
                    </div>
                    <div className="media-body mg-l-10">MSR</div>
                  </div>
                  <div className="mg-l-auto">
                    <strong
                      className="tx-success han-tooltip--left"
                      data-han-tooltip="Closed tickets"
                    >
                      20
                    </strong>{' '}
                    /
                    <span className="han-tooltip--left" data-han-tooltip="Assigned tickets">
                      40
                    </span>
                    <br />
                    <strong className="han-tooltip--left" data-han-tooltip="Avg. Close time">
                      2.5
                    </strong>{' '}
                    hrs
                  </div>
                </li>
                <li className="d-flex align-items-center pd-y-10 bd-t">
                  <div className="media align-items-center">
                    <span className="mg-r-10 tx-gray-300">{Icon('second_grade')}</span>
                    <div className="avatar avatar-sm flex-shrink-0">
                      <img
                        src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                        className="rounded-circle"
                        alt=""
                      />
                    </div>
                    <div className="media-body mg-l-10">KDH</div>
                  </div>
                  <div className="mg-l-auto">
                    <strong
                      className="tx-success han-tooltip--left"
                      data-han-tooltip="Closed tickets"
                    >
                      15
                    </strong>{' '}
                    /
                    <span className="han-tooltip--left" data-han-tooltip="Assigned tickets">
                      30
                    </span>
                    <br />
                    <strong className="han-tooltip--left" data-han-tooltip="Avg. Close time">
                      2.5
                    </strong>{' '}
                    hrs
                  </div>
                </li>
                <li className="d-flex align-items-center pd-y-10 bd-t">
                  <div className="media align-items-center">
                    <span className="mg-r-10 tx-orange">{Icon('third_grade')}</span>
                    <div className="avatar avatar-sm flex-shrink-0">
                      <img
                        src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                        className="rounded-circle"
                        alt=""
                      />
                    </div>
                    <div className="media-body mg-l-10">MSM</div>
                  </div>
                  <div className="mg-l-auto">
                    <strong
                      className="tx-success han-tooltip--left"
                      data-han-tooltip="Closed tickets"
                    >
                      10
                    </strong>{' '}
                    /
                    <span className="han-tooltip--left" data-han-tooltip="Assigned tickets">
                      30
                    </span>
                    <br />
                    <strong className="han-tooltip--left" data-han-tooltip="Avg. Close time">
                      3
                    </strong>{' '}
                    hrs
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
