import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import Button from '@base/components/form/button';
import { ChevronUp } from 'react-feather';
import Field from '@base/components/hook-form/field';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { KEY_KNOWLEDGE_BASE_DISPLAY } from '@desk/knowledge-base/config/key-names';
import { CREATE_KNOWELEDGEBASE } from '@desk/knowledge-base/services/graphql/knowledge-base';
import { isObject, isUndefined } from 'lodash';

interface WriteFormProps {
  fullScreen: boolean;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  onSuccess?: (newItem: any) => void;
  //with write form
  defaultValues: any;
  fields: any[];
  getParams: any;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const {
    fullScreen,
    onClose,
    onReload,
    onSuccess,
    //withWriteForm
    defaultValues,
    fields,
    getParams,
  } = props;
  //const { t } = useTranslation();

  const {
    handleSubmit,
    watch,
    reset,
    resetField,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_KNOWELEDGEBASE,
    'desk_createKnowledgebase',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //console.log('after save', context);
        //toast.success('Create knowledge base successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //console.log('mutation error', error);
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );

  //success
  useEffect(() => {
    if (mutationAdd.isSuccess) {
      onReload && onReload(); //reload list
      //callback new item
      const newItem: any = { ...mutationAdd.variables.knowledgebase, id: mutationAdd.data.id };
      onSuccess && onSuccess(newItem);
      //close modal
      onClose && onClose();
    }
  }, [mutationAdd.isSuccess]);

  //submit form
  const onSubmit = ({ formData, isPublic }: any) => {
    const configParams = getParams(formData); //generate api params by fields
    //// console.log('configParams', configParams);
    configParams.isPublish = isPublic;
    if (configParams.category.category) {
      configParams.folder = {
        id: configParams.category.id,
        name: configParams.category.name,
      };
      configParams.category = {
        id: configParams.category.category.id,
        name: configParams.category.category.name,
      };
    } else {
      configParams.category = {
        id: configParams.category.id,
        name: configParams.category.name,
      };
    }
    // content : {content: "editor content", tpl:{id, name}}
    if (isObject(configParams.content) && !isUndefined(configParams.content.content)) {
      configParams.tpl = configParams.content.tpl ? configParams.content.tpl : null;
      configParams.content = configParams.content.content;
    }
    mutationAdd.mutate({ knowledgebase: configParams });
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  //form fields
  const renderFields = () => {
    let formHeight = 'calc(100vh - 250px)';
    if (fullScreen) {
      formHeight = 'calc(100vh - 120px)';
    }

    return (
      <div
        className="pd-15 form-row scroll-box"
        style={{ maxHeight: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        {fields.map((_item, _index) => {
          let newComponentProps = { ..._item?.componentProps };
          if (_item.keyName === KEY_KNOWLEDGE_BASE_DISPLAY) return;

          return (
            <Field
              key={_index}
              item={{
                ..._item,
                componentProps: newComponentProps,
              }}
              control={control}
              //isHidden={_item.section != tab.value}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                handleSubmit((data) => onSubmit({ formData: data, isPublic: true }), onError)();
              }}
              disabled={mutationAdd.isLoading || !isValid}
              loading={mutationAdd.isLoading}
              name="Save as Public"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  handleSubmit((data) => onSubmit({ formData: data, isPublic: false }), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                name="Save as Draft"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values watch', watch()); //get form values when inputing
  //// console.log('form values', getValues()); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  return (
    <div className="pd-5">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        {renderFields()}
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(withWriteForm(WriteForm));
