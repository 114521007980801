import { KnowledgeBaseFolder } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import React from 'react';

interface ICategoryFolderTreeViewProps {
  value: KnowledgeBaseFolder;
}
const CategoryFolderTreeView: React.FC<ICategoryFolderTreeViewProps> = (
  props: ICategoryFolderTreeViewProps,
) => {
  const { value } = props;
  return <>{value.name}</>;
};

export default CategoryFolderTreeView;
