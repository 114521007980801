import React, { useEffect, useState } from 'react';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import CategorySelect from '../category-select';
import AssignGroupAutoComplete from '../assign-group-auto-complete';
import { KEY_TICKET_ASSIGN_GROUP, KEY_TICKET_ASSIGN_USER } from '@desk/ticket/config/key-names';
import AssignRepAutoComplete from '../assign-rep-auto-complete';

interface IProductCategoryProps {
  hideGroupLabel?: boolean;
  value?: any;
  onChange?: any;
}

const AssignGroupRep: React.FC<IProductCategoryProps> = (props) => {
  const { hideGroupLabel = false, value, onChange } = props;
  //// console.log('edit value', value);

  //state
  const [curGroup, setCurGroup] = useState<any>(null);
  const [curRep, setCurRep] = useState<any>(null);

  //initial value
  useEffect(() => {
    if (value) {
      if (value?.[KEY_TICKET_ASSIGN_GROUP]) {
        if (value[KEY_TICKET_ASSIGN_GROUP]?.id !== curGroup?.id) {
          setCurGroup(value[KEY_TICKET_ASSIGN_GROUP]);
        }
      }
      if (value?.[KEY_TICKET_ASSIGN_USER]) {
        if (value[KEY_TICKET_ASSIGN_USER]?.id !== curRep?.id) {
          setCurRep(value[KEY_TICKET_ASSIGN_USER]);
        }
      }
    } else {
      setCurGroup(null);
      setCurRep(null);
    }
  }, [value]);

  //value change
  const handleGroupChange = (newGroup: any) => {
    setCurGroup(newGroup);
    setCurRep(null);
    //callback
    onChange && onChange({ [KEY_TICKET_ASSIGN_GROUP]: newGroup, [KEY_TICKET_ASSIGN_USER]: null });
  };

  //value change
  const handleRepChange = (newRep: any) => {
    setCurRep(newRep);
    //callback
    onChange && onChange({ [KEY_TICKET_ASSIGN_GROUP]: curGroup, [KEY_TICKET_ASSIGN_USER]: newRep });
  };

  return (
    <div className="pos-relative">
      <div className="form-group">
        {!hideGroupLabel && <label className="form-item-title">Assgined Group</label>}
        <AssignGroupAutoComplete single={true} value={curGroup} onChange={handleGroupChange} />
      </div>
      <div className="form-group">
        <label className="form-item-title">Assgined Rep</label>
        <AssignRepAutoComplete
          single={true}
          groupId={''}
          defaultOptions={curGroup?.reps || []}
          value={curRep}
          onChange={handleRepChange}
        />
      </div>
    </div>
  );
};

export default AssignGroupRep;
