import React from 'react';
import { CustomerTextView as TextView } from '@base/containers/quick-view';

const View: React.FC = (props: any) => {
  const { value } = props;
  // console.log('ContactName', value);
  return <TextView value={value} />;
};

export default View;
