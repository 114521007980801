import React from 'react';
import { useRecoilState } from 'recoil';
import { getMoreActions } from '@base/utils/helpers/list-page-utils';
import ListHeader, { ListHeaderFilters } from '@desk/ticket/components/list/list-header';
import { searchFields } from '@customer/customer/config/list';
import { pageDataByMenuAtom } from '@base/recoil/atoms/app';
import * as keyNames from '@desk/ticket/config/key-names';
import { t } from 'i18next';
import { MENU_DESK_TICKET } from '@base/config/menus';
import { isEmpty } from 'lodash';

const PageHeader: React.FC<any> = (props) => {
  const { isSplitMode, columnKeys, fields, category, onChecked, refetch } = props;
  //recoil
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_DESK_TICKET));
  let { filter, listType } = pageData;

  //define sort columns
  const sortColumns = [keyNames.KEY_TICKET_CREATED_AT, keyNames.KEY_TICKET_UPDATED_AT];

  //sorting
  const sort = filter?.sort;
  const setSort = (sort: any) => {
    let newPageData = {
      ...pageData,
      filter: {
        ...pageData.filter,
        sort: sort,
      },
    };
    setPageData(newPageData);
  };

  //sort columns
  const getSortColumns = () => {
    const layoutSortColumns = fields?.reduce((options: any, col: any) => {
      if (col.sortable || sortColumns.includes(col.keyName)) {
        options.push({
          label: t(col.title),
          value: col.keyName,
        });
      }
      return options;
    }, []);

    return layoutSortColumns;
  };

  //start search
  const handleSearchSubmit = (searchData: any) => {
    //// console.log('search data', searchData);
    if (isEmpty(searchData)) {
      searchData = { keyword: '' };
    }
    let newFilter = {
      ...pageData.filter,
      // searchFilters: searchData,
      ...searchData,
    };
    let newPageData = {
      ...pageData,
      filter: newFilter,
    };
    setPageData(newPageData);
  };

  //render
  return (
    <ListHeader
      listType={listType}
      isSplitMode={isSplitMode}
      searchFields={searchFields.reduce((final: any[], field: any) => {
        if (columnKeys.indexOf(field.name) !== -1) {
          final.push(field);
        }
        return final;
      }, [])}
      onSearchSubmit={(formData: any) => handleSearchSubmit(formData)}
      columns={fields}
      filterComponent={
        <ListHeaderFilters
          category={category ?? ''}
          isSplitMode={isSplitMode}
          filterData={filter?.headerFilters}
          onFilterDataChanged={(valueData: any) => {
            let newFilter = {
              ...filter,
              headerFilters: valueData,
            };
            let newData = {
              ...pageData,
              filter: newFilter,
            };
            setPageData(newData);
          }}
          sortData={sort}
          onSortDataChanged={setSort}
          sortColumns={getSortColumns()}
        />
      }
      onSelectedAll={() => onChecked('all')}
      onDeselectedAll={() => onChecked('notAll')}
      onRefresh={refetch}
      actionsProps={getMoreActions()}
    />
  );
};

export default PageHeader;
