import { lazy } from 'react';

//for knowledgebase
export const CategoryFolderTreeWrite = lazy(
  () => import('@desk/knowledge-base/containers/view-field/category-folder-tree/edit'),
);
export const Switch = lazy(() => import('@base/containers/view-field/switch'));
export const KBCategoryAutoComplete = lazy(
  () => import('@desk/knowledge-base/containers/kb-category-auto-complete'),
);
