// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kb-table{border-radius:0px !important;border-top:0px !important;border-left:0px !important;border-right:0px !important}", "",{"version":3,"sources":["webpack://./src/desk/knowledge-base/assets/scss/styles.scss"],"names":[],"mappings":"AAAA,UACE,4BAAA,CACA,yBAAA,CACA,0BAAA,CACA,2BAAA","sourcesContent":[".kb-table {\n  border-radius: 0px !important;\n  border-top: 0px !important;\n  border-left: 0px !important;\n  border-right: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kb-table": "kb-table"
};
export default ___CSS_LOADER_EXPORT___;
