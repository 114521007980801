import { NoData } from '@base/components';
import { IdName } from '@base/types/interfaces/common';
import { KnowledgeBase } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import KnowledgeAutoComplete from '@desk/ticket/containers/knowledge-auto-complete';
import classNames from 'classnames';
import { fi } from 'date-fns/locale';
import _ from 'lodash';
import React, { useState } from 'react';
import { X } from 'react-feather';

interface KnowledgeBaseInsertedProps {
  className?: string;
  onChange: (nVal: IdName[]) => void;
  value: IdName[];
}
const KnowledgeBaseInserted: React.FC<KnowledgeBaseInsertedProps> = (
  props: KnowledgeBaseInsertedProps,
) => {
  const { className, onChange, value } = props;
  const [items, setItems] = useState<KnowledgeBase[]>([]);
  const handleDelete = (item: KnowledgeBase) => {
    const nItems = items.filter((oldItem: KnowledgeBase) => {
      return oldItem.id != item.id;
    });
    setItems(nItems);
    const nVals = nItems.map((cItem) => {
      return {
        id: cItem.id,
        name: cItem.subject,
      };
    });
    onChange && onChange(nVals);
  };
  const handleAssign = (item: KnowledgeBase) => {
    if (_.isNull(item)) return;
    const nItems = [...items];
    const fIdx = nItems.findIndex((_ele: any) => _ele.id === item.id);
    if (fIdx === -1) {
      nItems.push(item);
      setItems(nItems);
      //callback
      const nVals = nItems.map((_ele: any) => ({ id: _ele.id, name: _ele.subject }));
      onChange && onChange(nVals);
    }
  };
  const renderItems = () => {
    return (
      <>
        {items.length > 0 &&
          items.map((item: any, index: number) => {
            return (
              <div className="d-flex flex-wrap align-items-center pd-x-10 pd-b-3" key={item.id}>
                <a href="" target="_blank" title="새창으로 열림" className="mg-r-10">
                  {item.subject ? item.subject : ''}
                </a>
                <div className="d-flex align-items-center mg-l-auto">
                  <div className="tags">
                    {item.tags
                      ? item.tags.map((tag: any) => {
                        return (
                          <span className="tag-item" key={tag.id}>
                            {tag.name}
                          </span>
                        );
                      })
                      : ''}
                  </div>
                  <button
                    type="button"
                    className="btn btn-link btn-icon han-tooltip--top"
                    data-han-tooltip="Delete"
                    onClick={() => {
                      handleDelete(item);
                    }}
                  >
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
            );
          })}
      </>
    );
  };
  return (
    <div className={classNames('pos-relative', className)}>
      <div className="pos-relative mg-b-10">
        {renderItems()}
        {items.length == 0 && <NoData icon={'Package'} iconType={'feather'} />}
      </div>
      <KnowledgeAutoComplete
        visible={false}
        addLabel={'Add new knowledge'}
        onAdd={() => console.log('add...')}
        onChange={handleAssign}
        single={true}
      />
    </div>
  );
};

export default KnowledgeBaseInserted;
