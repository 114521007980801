import React from 'react';
import classNames from 'classnames';
import ProductCategory from '@desk/ticket/containers/product-category';

const Edit: React.FC<any> = (props) => {
  const { value, errors, onChange } = props;

  return (
    <>
      <div>
        <div className={classNames({ 'is-required': errors?.isRequired })}>
          <ProductCategory
            //hideProductLabel={true}
            value={value}
            onChange={onChange}
          />
        </div>
        {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
      </div>
    </>
  );
};

export default Edit;
