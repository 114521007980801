import * as keyNames from '@desk/ticket/config/key-names';

export const finalizeParams = (
  formData: any,
  type: string,
  ticketId: string,
  ticketName: string,
  parentComment: any,
) => {
  let newParams: any = {
    kind: type,
    ticket: { id: ticketId, name: ticketName },
    parent: { id: parentComment.id, name: parentComment.name },
  };
  //email
  if (
    formData[keyNames.KEY_TICKET_REPLY_TYPE].findIndex((_ele: any) => _ele.value === 'email') > -1
  ) {
    let primaryEmailFrom: any = {};
    const foundEmail = formData[keyNames.KEY_TICKET_REPLY_FROM].emails?.find(
      (_ele: any) => _ele.label.label === 'LABEL_PRIMARY',
    );
    if (foundEmail) {
      primaryEmailFrom = {
        id: formData[keyNames.KEY_TICKET_REPLY_FROM].id,
        name: formData[keyNames.KEY_TICKET_REPLY_FROM].name,
        email: foundEmail.email,
      };
    }

    const primaryEmailTo: any[] = [];
    formData[keyNames.KEY_TICKET_REPLY_TO]?.map((_to: any) => {
      const foundEmail = _to.emails?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
      if (foundEmail) {
        primaryEmailTo.push({
          id: _to.id,
          name: _to.name,
          email: foundEmail.email,
        });
      }
    });

    const primaryEmailCc: any[] = [];
    formData[keyNames.KEY_TICKET_REPLY_CC]?.map((_cc: any) => {
      const foundEmail = _cc.emails?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
      if (foundEmail) {
        primaryEmailCc.push({
          id: _cc.id,
          name: _cc.name,
          email: foundEmail.email,
        });
      }
    });

    newParams.email = {
      subject: formData[keyNames.KEY_TICKET_REPLY_SUBJECT],
      from: primaryEmailFrom.email,
      to: primaryEmailTo.map((_ele: any) => _ele.email).join(','),
      cc: primaryEmailCc.map((_ele: any) => _ele.email).join(','),
      content: formData[keyNames.KEY_TICKET_REPLY_MESSAGE],
      tpl: formData[keyNames.KEY_TICKET_REPLY_TEMPALTE]
        ? {
            id: formData[keyNames.KEY_TICKET_REPLY_TEMPALTE].id,
            name: formData[keyNames.KEY_TICKET_REPLY_TEMPALTE].name,
          }
        : null,
      // kb: formData[keyNames.KEY_TICKET_REPLY_KB].map((_item: any) => ({
      //   id: _item.id,
      //   name: _item.subject,
      // })),
    };
  }
  //sms
  if (
    formData[keyNames.KEY_TICKET_REPLY_TYPE].findIndex((_ele: any) => _ele.value === 'sms') > -1
  ) {
    const primaryPhoneTo: any[] = [];
    formData[keyNames.KEY_TICKET_REPLY_TO]?.map((_to: any) => {
      const foundPhone = _to.phones?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
      if (foundPhone) {
        primaryPhoneTo.push({
          id: _to.id,
          name: _to.name,
          phoneNumber: foundPhone.phoneNumber,
        });
      }
    });
    newParams.sms = {
      subject: formData[keyNames.KEY_TICKET_REPLY_SUBJECT],
      from: formData[keyNames.KEY_TICKET_REPLY_FROM].name,
      to: primaryPhoneTo,
      content: formData[keyNames.KEY_TICKET_REPLY_MESSAGE],
      tpl: formData[keyNames.KEY_TICKET_REPLY_TEMPALTE]
        ? {
            id: formData[keyNames.KEY_TICKET_REPLY_TEMPALTE].id,
            name: formData[keyNames.KEY_TICKET_REPLY_TEMPALTE].name,
          }
        : null,
      // kb: formData[keyNames.KEY_TICKET_REPLY_KB].map((_item: any) => ({
      //   id: _item.id,
      //   name: _item.subject,
      // })),
    };
  }
  //call
  // if (
  //   formData[keyNames.KEY_TICKET_REPLY_TYPE].findIndex((_ele: any) => _ele.value === 'call') > -1
  // ) {
  //   const primaryPhoneTo: any[] = [];
  //   formData[keyNames.KEY_TICKET_REPLY_TO]?.map((_to: any) => {
  //     const foundPhone = _to.phones?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
  //     if (foundPhone) {
  //       primaryPhoneTo.push({
  //         id: _to.id,
  //         name: _to.name,
  //         phoneNumber: foundPhone.phoneNumber,
  //       });
  //     }
  //   });
  //   newParams.call = {
  //     subject: formData[keyNames.KEY_TICKET_REPLY_SUBJECT],
  //     from: formData[keyNames.KEY_TICKET_REPLY_FROM].name,
  //     to: primaryPhoneTo,
  //     content: formData[keyNames.KEY_TICKET_REPLY_MESSAGE],
  //   };
  // }
  //fax
  // if (
  //   formData[keyNames.KEY_TICKET_REPLY_TYPE].findIndex((_ele: any) => _ele.value === 'fax') > -1
  // ) {
  //   const primaryPhoneTo: any[] = [];
  //   formData[keyNames.KEY_TICKET_REPLY_TO]?.map((_to: any) => {
  //     const foundPhone = _to.phones?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
  //     if (foundPhone) {
  //       primaryPhoneTo.push({
  //         id: _to.id,
  //         name: _to.name,
  //         phoneNumber: foundPhone.phoneNumber,
  //       });
  //     }
  //   });
  //   newParams.fax = {
  //     subject: formData[keyNames.KEY_TICKET_REPLY_SUBJECT],
  //     from: formData[keyNames.KEY_TICKET_REPLY_FROM].name,
  //     to: primaryPhoneTo,
  //   };
  // }

  return newParams;
};
