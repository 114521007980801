import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { SpanLang } from '@base/components';
import { KEY_TICKET_ASSIGN_GROUP, KEY_TICKET_ASSIGN_USER } from '@desk/ticket/config/key-names';
//import { t } from 'i18next';
//import { SpanLang } from '@base/components';

const View: React.FC = (props: any) => {
  const { value } = props; // value = { [KEY_TICKET_ASSIGN_GROUP]: null, [KEY_TICKET_ASSIGN_USER]: null }
  return (
    <div className="d-flex flex-column">
      <div className="pd-t-5">
        <TextView value={value?.[KEY_TICKET_ASSIGN_GROUP]?.name ?? ''} />
      </div>
      <div className="pd-t-20">
        <label className="form-item-title">
          <SpanLang className="pd-0" keyLang={'Assgined Rep'} />
        </label>
        <div className="pd-t-10">
          <TextView value={value?.[KEY_TICKET_ASSIGN_USER]?.name ?? ''} />
        </div>
      </div>
    </div>
  );
};

export default View;
