import React, { VFC } from 'react';
import WriteFrom from '@desk/ticket/containers/write-form';

const WriteFormDesktopLayout1: VFC<any> = (props: any) => {
  return (
    <>
      <WriteFrom {...props} />
    </>
  );
};

export default WriteFormDesktopLayout1;
