import React from 'react';
import {CustomerTextView as TextView} from "@base/containers/quick-view";

const View: React.FC = (props: any) => {
  const { value } = props;

  return (
    <TextView value={value?.customer ?? {}}/>
  );
};

export default View;
