import React from 'react';
import { useTranslation } from 'react-i18next';
import TimeAttachment from '@base/containers/attachment/time-attachment';
import DesktopLayout1ViewRight from '@base/layouts/desktop/layout1/view-right';
//import TicketCustomer from '@desk/ticket/containers/ticket-customer';
import TicketKnowledgeBase from '@desk/ticket/containers/ticket-knowledgebase';
//import TicketAssignedUsers from '@desk/ticket/containers/ticket-assigned-users';
//import TicketCcUsers from '@desk/ticket/containers/ticket-cc-users';
import TicketContactInfo from '@desk/ticket/containers/ticket-contact-info';
import { useRecoilValue } from 'recoil';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import * as keyNames from '@desk/ticket/config/key-names';
import TicketTodo from '@desk/ticket/containers/ticket-todo';
import { MENU_DESK } from '@base/config/menus';
import KnowledgeBaseInserted from '@desk/knowledge-base/containers/knowledge-base-inserted';

interface IRightProps {
  id: string;
  category: string;
  showTop?: boolean;
}

const ViewRight: React.FC<IRightProps> = (props) => {
  const { showTop, category, id } = props;
  const { t } = useTranslation();

  //view data
  const viewData = useRecoilValue(viewDataByMenuAtom(category));
  const basicFields = viewData?.layout?.data?.[0]?.children || [];
  const customerField = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_TICKET_CUSTOMER,
  ); //this is account or contact
  // const contactField = basicFields?.find(
  //   (_field: any) => _field?.keyName === keyNames.KEY_TICKET_CONTACT,
  // ); //this is employee contact

  let rightConfig: any[] = [];
  rightConfig.push(
    ...[
      {
        title: t('Customer Information'),
        key: 'ticketContact',
        component: (
          <TicketContactInfo
            menuSource={category}
            menuSourceId={id}
            account={
              customerField?.data?.customer?.category === 'CATEGORY_ACCOUNT'
                ? customerField?.data?.customer
                : null
            }
            contact={
              customerField?.data?.customer?.category === 'CATEGORY_ACCOUNT'
                ? customerField?.data?.contact
                : customerField?.data?.customer
            }
          />
        ),
      },
      {
        title: t('Knowledge Base'),
        key: 'ticketKB',
        // component: <KnowledgeBaseInserted menuSourceId={id} menuSource={MENU_DESK} />,
        component: <TicketKnowledgeBase menuSourceId={id} menuSource={MENU_DESK} />,
      },
      {
        title: t('Related Activities'),
        key: 'ticketTodo',
        component: <TicketTodo menuSource={category} menuSourceId={id} />,
      },
      {
        title: t('common_section_attachment'),
        key: 'ticketAttachment',
        component: <TimeAttachment menuSource={category} menuSourceId={id} />,
      },
    ],
  );

  return (
    <DesktopLayout1ViewRight
      styles={{ height: showTop ? 'calc(100vh - 240px)' : 'calc(100vh - 110px)' }}
      rightConfig={rightConfig}
    />
  );
};

export default ViewRight;
