import { isUndefined } from 'lodash';
import { KnowledgeBase } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import { Link } from 'react-router-dom';
import { MAIN_MENU, NAME_KEY, PRIMARY_KEY } from '@desk/knowledge-base/config/pages/main-page';
// import Priority from '@base/containers/priority';
import {
  useKnowledgeBases,
  useKnowledgeBaseTags,
} from '@desk/knowledge-base/services/knowledge-base-service';
import { SpanLang } from '@base/components';
import { Switch } from '@base/components/form';
import {
  KNOWLEDGEBASE_TYPE_LANGUAGE,
  KNOWLEDGEBASE_DISPLAY_LANGUAGE,
} from '@desk/knowledge-base/config/index';
import { EDisplay, EKnowlegeBaseType } from '@desk/knowledge-base/types/enums';
import * as keyNames from '@desk/knowledge-base/config/key-names';
import * as components from '@desk/knowledge-base/config/write-field/components';
import * as commonComponents from '@base/config/write-field/components';
/** LIST PAGE CONFIG */
export const ListPageConfig = {
  primaryKey: PRIMARY_KEY,
  nameKey: NAME_KEY,
  useFectchList: useKnowledgeBases,
  getTitle: (row: any, isSplitMode: boolean): string => {
    // let preTitle: any = null;
    // let status = row?.status ? row.status : '';
    // if (isSplitMode) {
    //   preTitle = status ? (
    //     <>
    //       [<SpanLang keyLang={status.languageKey} />]
    //     </>
    //   ) : (
    //     ''
    //   );
    // }
    const name = row && isUndefined(row[NAME_KEY]) ? '' : row[NAME_KEY];
    return name;
  },
  searchFields: [
    {
      name: 'subject',
      type: 'text',
      label: 'Subject',
      defaultValue: '',
    },
    // {
    //   name: 'category',
    //   type: 'text',
    //   label: 'Category',
    //   defaultValue: '',
    // },
    // {
    //   name: 'folder',
    //   type: 'text',
    //   label: 'Folder',
    //   defaultValue: '',
    // },
  ],
  groupByOptions: [
    { label: 'All Articles', value: 'all' },
    { label: 'My Articles', value: 'my' },
    { label: 'My Group Articles', value: 'myGroup' },
    { label: 'All Drafts', value: 'allDrafts' },
    { label: 'All Published', value: 'allPublished' },
    { label: 'My Drafts', value: 'myDrafts' },
    { label: 'My Published', value: 'myPublished' },
    { label: 'My Group Drafts', value: 'myGroupDrafts' },
    { label: 'My Group Published', value: 'myGroupPublished' },
    // { label: 'Deleted Articles', value: 'deleted' },
  ],
  dateByOptions: [
    { label: 'Created on', value: 'createdAt' },
    { label: 'Updated on', value: 'updatedAt' },
  ],
  filterByOptions: [
    {
      label: 'Owner',
      value: keyNames.KEY_KNOWLEDGE_CREATED_BY,
      component: commonComponents.UserAutoComplete,
      componentProps: {
        showAvartar: true,
      },
      getValue: (value: any) => {
        return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
      },
      setValue: (value: any) => {
        return value ? value.split(',') : [];
      },
    },
    {
      label: 'Tag',
      value: keyNames.KEY_KNOWLEDGE_BASE_TAG,
      component: commonComponents.Tags,
      componentProps: {
        fetchList: useKnowledgeBaseTags,
        fieldValue: 'id',
        fieldLabel: 'name',
      },
      getValue: (value: any) => {
        return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
      },
      setValue: (value: any) => {
        return value ? value.split(',') : [];
      },
    },
  ],
  getColumnRenderRemap: (extraParams: any) => {
    const { category } = extraParams;
    return {
      subject(col: string, row: KnowledgeBase) {
        return (
          <Link to={`/${MAIN_MENU}/${category}/${row.id}`}>
            {!row.isPublish ? (
              <>
                <span className="badge badge-secondary">Draft</span>&nbsp;
              </>
            ) : (
              ''
            )}
            {row.subject}
          </Link>
        );
      },
      category(col: string, row: KnowledgeBase) {
        return row.category
          ? row.folder
            ? row.category.name + '/' + row.folder.name
            : row.category.name
          : '';
      },
      inserted(col: string, row: KnowledgeBase) {
        return row.inserted ?? 0;
      },
      viewed(col: string, row: KnowledgeBase) {
        return row.viewed ?? 0;
      },
      helped(col: string, row: KnowledgeBase) {
        return row.helped ?? 0;
      },
      notHelped(col: string, row: KnowledgeBase) {
        return row.notHelped ?? 0;
      },
    };
  },
};
