import React, { useEffect, useState } from 'react';
import displayLayoutConfig from '@desk/knowledge-base/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import ViewLeft from '@desk/knowledge-base/layouts/desktop/layout1/view/view-left';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, pageDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { isEmptyLayoutData, mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useNavigate, useParams } from 'react-router-dom';
import configFields from '@desk/knowledge-base/config/view-field';
import {
  DEFAULT_CATEGORY,
  DEFAULT_ROUTE,
  MAIN_MENU,
  NAME_KEY,
  WritePage,
} from '@desk/knowledge-base/config/pages/main-page';
import { ViewPageConfig } from '@desk/knowledge-base/config/pages/view-page';
import { IPageLayoutData } from '@base/types/interfaces/pagelayout';
import ViewRight from '@desk/knowledge-base/layouts/desktop/layout1/view/view-right';
import Title from '@base/containers/view-field/title';
import { MENU_DESK_KNOWLEDGE } from '@base/config/menus';
import { WRITE_OPTION_ATOM } from '@desk/knowledge-base/config/pages/write-page';
import { SpanLang } from '@base/components';
import { ITab } from '@base/types/interfaces/app';
import KnowledgeBaseContent from '@desk/knowledge-base/containers/knowledge-base-content';
import { useQueryClient } from '@tanstack/react-query';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { GENERATE_SHORTEN_URLS } from '@base/services/graphql/shortenUrls';
import {
  CHANGE_KB_PUBLISH_STATUS,
  CLONE_KB,
  DELETE_KNOWELEDGEBASE,
} from '@desk/knowledge-base/services/graphql/knowledge-base';
import { IShortenUrl } from '@base/types/interfaces/shortenUrls';
import { toast } from 'react-toastify';
import PrintPreview from '@desk/knowledge-base/containers/print-preview';

interface IViewProps {
  isSplitMode?: boolean;
}

const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const category = DEFAULT_CATEGORY;

  const categoryWithPrefix = `${MAIN_MENU}_${category}`;
  // console.log('categoryWithPrefix', categoryWithPrefix);
  const params = useParams();

  const { isSplitMode } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const pageData = useRecoilValue(pageDataByMenuAtom(categoryWithPrefix));
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [writeOption, setWriteOption] = useRecoilState(WRITE_OPTION_ATOM); //for write page
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_DESK_KNOWLEDGE));
  const mCopyLink: any = useMutationPost<BaseMutationResponse>(
    GENERATE_SHORTEN_URLS,
    'common_shortenUrls',
  );
  const mDeleteKB: any = useMutationPost<BaseMutationResponse>(
    DELETE_KNOWELEDGEBASE,
    'desk_deleteKnowledgeBase',
  );
  const mChangePublishStatus: any = useMutationPost<BaseMutationResponse>(
    CHANGE_KB_PUBLISH_STATUS,
    'desk_changeKBPublishStatus',
  );
  const mCloneKB: any = useMutationPost<BaseMutationResponse>(CLONE_KB, 'desk_CloneKB');
  const [title, setTitle] = useState('');
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [isPublish, setIsPublish] = useState(false);
  const [showPrint, setShowPrint] = useState(false);

  const { activeMenu, activeId, activeTab } = pageData;

  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';

  let currentTab = activeTab != params['*'] ? params['*'] : activeTab ?? '';
  const writeCategory = `${MAIN_MENU}_${writeOption.writeType}`; //for write
  const keyTitle = `crm_new_${writeCategory}`;

  const { data: layoutView, isLoading: layoutLoading } = usePageLayoutByMenu(
    categoryWithPrefix,
    'view',
  );
  const querySchema = buildViewSchema({
    sections: layoutView?.data,
    configFields,
    hiddenSchemas: ['isPublish'],
  });
  const {
    isLoading,
    data,
    isFetching: dataLoading,
    refetch,
  } = ViewPageConfig.useGetView(id, querySchema);

  const layoutData = mergeLayoutData(layoutView, data, configFields);
  const handleDataUpdate = () => {
    if (data) {
      setTitle(data[NAME_KEY]);
      setIsPublish(data.isPublish ?? false);
    }
    if (
      data &&
      !isEmptyLayoutData(layoutData) &&
      (!viewData ||
        viewData?.menuSourceId != id ||
        (!dataLoading && viewData.forceUpdating === true))
    ) {
      // console.log('handleDataUpdate', layoutData);
      let newLayoutData: IPageLayoutData = { ...layoutData };
      newLayoutData.menuSourceId = id;
      newLayoutData.menu = categoryWithPrefix;
      newLayoutData.menuSource = MENU_DESK_KNOWLEDGE;
      setViewData(newLayoutData);
    }
  };
  const reloadKB = (updateData: any) => {
    refetch();
    const newViewData = { ...viewData, forceUpdating: true };
    setViewData(newViewData);
  };
  const goList = () => {
    queryClient.invalidateQueries(['desk_knowledgebases']);
    navigate(DEFAULT_ROUTE);
  };
  const onCopyLink = () => {
    const cUrl = document.location.href;
    mCopyLink.mutate(
      { longUrls: [cUrl] },
      {
        onSuccess: (res: any) => {
          // console.log('mCopyLink', res);
          if (res && res?.results.length) {
            navigator?.clipboard?.writeText(res.results[0].shortUrl);
            toast.success('Link has copied!');
          }
        },
      },
    );
  };
  const onDelete = () => {
    mDeleteKB.mutate(
      { ids: [id] },
      {
        onSuccess: (res: any) => {
          toast.success('Data was removed!');
          goList();
        },
      },
    );
  };
  const onClone = () => {
    mCloneKB.mutate(
      { id: id },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
        },
      },
    );
  };
  const onChangePublishStatus = (isPublish: boolean) => {
    mChangePublishStatus.mutate(
      { ids: [id], isPublish: isPublish },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
          reloadKB({});
        },
      },
    );
  };

  const moreActions = [
    {
      label: 'Copy Link',
      icon: 'Link',
      onClick: () => {
        // reply
        onCopyLink();
      },
    },
    {
      label: isPublish ? 'UnPublish' : 'Publish',
      icon: isPublish ? 'ArrowDown' : 'ArrowUp',
      onClick: () => {
        // reply
        const newStatus = !isPublish;
        onChangePublishStatus(newStatus);
      },
    },
  ];
  const contentTab: ITab = {
    order: 1,
    default: true,
    label: 'Content',
    path: 'content',
    tabComponent: KnowledgeBaseContent,
    tabComponentProps: {
      reloadKB: reloadKB,
    },
  };
  const viewProps = {
    headerProps: {
      menu: category,
      menus: ViewPageConfig.menus,
      isDeleting: false,
      // onExportToPDF: () => {},
      onBeforeGoList: () => {
        // queryClient.in;
        queryClient.invalidateQueries(['desk_knowledgebases']);
      },
      onPrint: () => setShowPrint(true),
      onDelete: () => {
        onDelete();
      },
      onNew: () => {
        // call new create kb
        setWriteOption((filter) => ({
          ...filter,
          isOpenWrite: true,
          writeMode: 'view',
          writeType: DEFAULT_CATEGORY,
          writeParam: {},
        }));
      },
      moreActions: moreActions,
      onClone: () => {
        onClone();
      },
      splitViewMode: isSplitMode,
      componentLeft: (
        <>
          <Title
            value={title}
            onSave={(params: any) => { }}
            keyName={NAME_KEY}
            menuSourceId={id}
            menuSource={MENU_DESK_KNOWLEDGE}
            userPermission={{
              isEdit: true,
            }}
          />
        </>
      ),
    },
    centerProps: {
      menuSource: MENU_DESK_KNOWLEDGE,
      menuSourceId: id,
      tabs: [contentTab, ...ViewPageConfig.tabs],
      currentTab: currentTab,
    },
    componentLeft: <ViewLeft {...{ id, category: MENU_DESK_KNOWLEDGE }} />,
    leftProps: {},
    // componentRight: <ViewRight menuSource={MENU_DESK_KNOWLEDGE} menuSourceId={id} />,
  };

  useEffect(() => {
    handleDataUpdate();
  }, [layoutData, data, id]);

  return (
    <>
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...viewProps}
      />
      {/* hook form - write new write */}
      {writeOption.isOpenWrite && writeOption.writeType === DEFAULT_CATEGORY && (
        <WritePage
          title={<SpanLang keyLang={keyTitle} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          menuApi={writeCategory}
        />
      )}
      {showPrint && (
        <PrintPreview
          title='Knowledge Base Print Preview'
          isOpen={showPrint}
          onClose={() => setShowPrint(false)}
          data={viewData}
        />
      )}
    </>
  );
};

export default ViewPage;
