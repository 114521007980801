import { lazy } from 'react';

//for ticket
export const Priority = lazy(() => import('@base/containers/view-field/priority/edit'));
export const ProductAutoComplete = lazy(
  () => import('@product/product/containers/product-auto-complete'),
);
export const Date = lazy(() => import('@base/containers/view-field/date/edit'));
export const CustomerAutoComplete = lazy(
  () => import('@customer/customer/containers/customer-auto-complete'),
);
export const AssignGroupAutoComplete = lazy(
  () => import('@desk/ticket/containers/assign-group-auto-complete'),
);
export const AssignRepAutoComplete = lazy(
  () => import('@desk/ticket/containers/assign-rep-auto-complete'),
);
export const Classification = lazy(() => import('@desk/ticket/containers/classification'));
export const PrioritySelect = lazy(() => import('@base/containers/priority-select'));
export const Duration = lazy(() => import('@settings/preferences/components/duration'));
export const CategorySelect = lazy(() => import('@desk/ticket/containers/category-select'));

//filter
export const ProductCategorySelect = lazy(() => import('@desk/ticket/containers/product-category'));
export const AssignGroupRep = lazy(() => import('@desk/ticket/containers/assign-group-rep'));
export const UserAutoComplete = lazy(() => import('@base/containers/user-auto-complete'));
