export const KEY_KNOWLEDGE_BASE_SUBJECT = 'subject';
export const KEY_KNOWLEDGE_BASE_ASSIGN_GROUP = 'availableFor';
export const KEY_KNOWLEDGE_BASE_CATEGORY = 'category';
export const KEY_KNOWLEDGE_BASE_TAG = 'tags';
export const KEY_KNOWLEDGE_BASE_CONTENT = 'content';
export const KEY_KNOWLEDGE_BASE_TYPE = 'type';
export const KEY_KNOWLEDGE_BASE_DISPLAY = 'display';
export const KEY_KNOWLEDGE_BASE_ACTIVE = 'active';
export const KEY_KNOWLEDGE_BASE_ID = 'id';
export const KEY_KNOWLEDGE_CREATED_AT = 'createdAt';
export const KEY_KNOWLEDGE_CREATED_BY = 'createdBy';
export const KEY_KNOWLEDGE_UPDATED_AT = 'updatedAt';
export const KEY_KNOWLEDGE_UPDATED_BY = 'updatedAt';
