import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Move, Trash2 } from 'react-feather';
import { confirmAlert } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import ListQuickAction from '@base/components/list/list-quick-action';
import CategoryFolderModal from '@desk/knowledge-base/containers/category-folder-modal';
import {
  KNOWLEDGE_CATEGORY_CHANGE,
  KNOWLEDGE_CATEGORY_DELETE,
} from '@desk/knowledge-base/services/graphql/knowledge-base';

const PageQuickAction: React.FC<any> = (props) => {
  const { selectedIds, setSelectedIds, onReload } = props;
  const { t } = useTranslation();
  //state
  const [showCategory, setShowCategory] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  //delete
  const mDelete: any = useMutationPost<BaseMutationResponse>(
    KNOWLEDGE_CATEGORY_DELETE,
    'desk_deleteKnowledgebase',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Deleted Ticket successfully!');
        //setSelectedIds([]);
        //onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );

  //change mutation
  const mChangeCategory: any = useMutationPost<BaseMutationResponse>(
    KNOWLEDGE_CATEGORY_CHANGE,
    'desk_moveKB',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Assigned new reps successfully!');
        //setSelectedIds([]);
        //onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    if (mDelete.isSuccess) {
      setSelectedIds([]);
      onReload && onReload();
    }
  }, [mDelete.isSuccess]);

  useEffect(() => {
    if (mChangeCategory.isSuccess) {
      setSelectedIds([]);
      onReload && onReload();
    }
  }, [mChangeCategory.isSuccess]);

  //delete item
  const handleDelete = () => {
    mDelete.mutate({ ids: selectedIds });
    // confirmAlert({
    //   title: t('crm_new_common_delete'),
    //   message: t('crm_new_common_delete_msg'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: () => {
    //         mDelete.mutate({ ids: selectedIds });
    //       },
    //     },
    //   ],
    // });
  };

  const hanleAssignCategory = (item: any) => {
    //item: category or folder
    //// console.log('hanleAssignCategory: ', item);
    const params: any = {
      ids: selectedIds, //kb ids
    };
    if (item.type === 'TYPE_CATEGORY') {
      params.category = { id: item.id, name: item.name };
    } else {
      // 'TYPE_FOLDER'
      params.category = { id: item.category.id, name: item.category.name };
      params.folder = { id: item.id, name: item.name };
    }
    mChangeCategory.mutate(params);
  };

  //buttons
  const quickButtons = [
    {
      title: 'Move To',
      icon: <Move />,
      callback: (selected: any) => {
        setShowCategory(true);
      },
    },
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        handleDelete();
      },
    },
  ];

  return (
    <>
      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons}
        resetSelected={() => setSelectedIds([])}
        visible={selectedIds.length > 0}
      />
      {showCategory && (
        <CategoryFolderModal
          title="Select category/folder"
          isOpen={showCategory}
          size="md"
          isLoading={mChangeCategory.isLoading}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          onClose={() => setShowCategory(false)}
          onSelect={hanleAssignCategory}
        />
      )}
    </>
  );
};

export default PageQuickAction;
