import * as keyNames from '@desk/knowledge-base/config/key-names';
import * as commonConfig from '@base/config/view-field';
import * as components from './components';
import * as commonComponents from '@base/config/view-field/components';
import { IFieldConfig } from '@base/types/interfaces/common';
import {
  useKnowledgeBaseCategories,
  useKnowledgeBaseTags,
} from '@desk/knowledge-base/services/knowledge-base-service';
import { KNOWLEDGEBASE_DISPLAY_LANGUAGE, KNOWLEDGEBASE_TYPE_LANGUAGE } from '..';
import { EDisplay, EKnowlegeBaseType } from '@desk/knowledge-base/types/enums';
import { ETemplateGroup, ETemplateGroupNum } from '@base/types/enums';

const viewFieldsConfig: IFieldConfig = {
  ...commonConfig?.default,
  [keyNames.KEY_KNOWLEDGE_BASE_CATEGORY]: {
    component: components.CategoryFolderSelectView,
    // componentProps: {
    //   fetchList: useKnowledgeBaseCategories,
    // },
    schema: `
      category {
        id
        name
      }
      folder {
        id 
        name
      }
    `,
    getValue: (viewData: any) => {
      //// console.log('getValue', viewData);
      if (viewData.folder) {
        return viewData.folder;
      } else if (viewData.category) {
        return viewData.category;
      } else {
        return { id: '', name: '' };
      }
    },
    getMutationValue: (value: any) => {
      //// console.log('edit value', value);
      return value.category
        ? {
            category: {
              id: value.category.id,
              name: value.category.name,
            },
            folder: {
              id: value.id,
              name: value.name,
            },
          }
        : {
            category: {
              id: value.id,
              name: value.name,
            },
          };
    },
  },
  [keyNames.KEY_KNOWLEDGE_BASE_TAG]: {
    component: commonComponents.Tags,
    componentProps: {
      fetchList: useKnowledgeBaseTags,
      fieldValue: 'id',
      fieldLabel: 'name',
    },
    schema: `tags {
      id
      name
    }`,
  },
  [keyNames.KEY_KNOWLEDGE_BASE_CONTENT]: {
    component: commonComponents.EditorTemplateView,
    componentProps: {
      templateGroup: ETemplateGroup.KNOWLEDGE,
    },
    schema: `
      content
      tpl {
        id
        name
      }
    `,
    getValue: (viewData: any) => {
      // console.log('getValue', viewData);
      return {
        content: viewData.content,
        tpl: viewData.tpl,
      };
    },
    getMutationValue: (value: any) => {
      // console.log('KEY_KNOWLEDGE_BASE_CONTENT', value);
      return {
        content: value.content,
        tpl: value.tpl,
      };
    },
  },
};
export default viewFieldsConfig;
