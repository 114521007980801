import { Button } from '@base/components/form';
import useMutationPost from '@base/hooks/useMutationPost';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { getFieldLayoutDataByKeyName } from '@base/utils/helpers/page-layout-utils';
import { MAIN_MENU } from '@desk/knowledge-base/config/pages/main-page';
import {
  CHANGE_KB_HELPFUL_STATUS,
  CHANGE_KB_PUBLISH_STATUS,
} from '@desk/knowledge-base/services/graphql/knowledge-base';
import React, { useEffect, useState } from 'react';
import { ArrowDown, ArrowUp, ThumbsDown, ThumbsUp } from 'react-feather';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

interface KnowledgeBaseContentProps {
  menuSource: string;
  menuSourceId: string;
  reloadKB?: (updateData: any) => void;
}
const KnowledgeBaseContent: React.FC<KnowledgeBaseContentProps> = (
  props: KnowledgeBaseContentProps,
) => {
  const { menuSource, menuSourceId, reloadKB } = props;

  const viewData = useRecoilValue(viewDataByMenuAtom(menuSource));
  const field = getFieldLayoutDataByKeyName(viewData, 'content');
  const createdByField = getFieldLayoutDataByKeyName(viewData, 'createdBy');
  const _config = field?.config ?? null;
  const Component = _config?.component ?? null;
  let data = field && field.data ? field.data : '';

  const kbId = _config?.viewData?.id ?? '';
  const createdBy = createdByField?.data ?? '';
  const [isPublish, setIsPublish] = useState(false);
  //@TODO get loginUser
  const isOwner = createdBy != '' ? true : false;
  // @TODO get admin role
  const isAdmin = true;
  const canPublish = isOwner && isAdmin;
  const [isChangingHelpful, setIsChangingHelpful] = useState(false);
  const [isChangingPublish, setIsChangingPublish] = useState(false);
  //mutation
  const mChangePublishStatus: any = useMutationPost<BaseMutationResponse>(
    CHANGE_KB_PUBLISH_STATUS,
    'desk_changeKBPublishStatus',
  );
  const mChangeHelpFulStatus: any = useMutationPost<BaseMutationResponse>(
    CHANGE_KB_HELPFUL_STATUS,
    'desk_changeKBHelpfulStatus',
  );
  const onChangePublishStatus = (isPublish: boolean) => {
    setIsChangingPublish(true);
    mChangePublishStatus.mutate(
      { ids: [kbId], isPublish: isPublish },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
          setIsChangingPublish(false);
          // console.log('doisPublish', isPublish);
          setIsPublish(isPublish);
          reloadKB && reloadKB({ isPublish: isPublish });
        },
        onError: () => {
          setIsChangingPublish(false);
        },
      },
    );
  };
  const onChangeHelpfulStatus = (helpful: boolean) => {
    setIsChangingHelpful(true);
    mChangeHelpFulStatus.mutate(
      { ids: [kbId], helpful: helpful },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
          setIsChangingHelpful(false);
          // +,- helped, notHelped
          let updateData = {};
          reloadKB && reloadKB(updateData);
        },
        onError: () => {
          setIsChangingHelpful(false);
        },
      },
    );
  };
  useEffect(() => {
    if (viewData && viewData.forceUpdating !== true) {
      const field = getFieldLayoutDataByKeyName(viewData, 'content');
      const _config = field?.config ?? null;
      const defaultIsPublish = _config?.viewData?.isPublish ?? false;
      setIsPublish(defaultIsPublish);
    }
  }, [viewData]);

  return (
    <>
      <div className="pd-15">
        {field ? (
          <div className="card mg-b-10">
            <div className="card-body">
              <Component
                value={data}
                userPermission={field?.userPermission}
                keyName={field?.keyName}
                dataType={field?.dataType}
                {..._config?.componentProps}
                config={_config}
                menu={MAIN_MENU}
                menuSource={menuSource}
                menuSourceId={menuSourceId}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="mg-t-10">
          {/* @TODO product => !isOwner show Helpful/ not helpfult */}
          {isOwner && (
            <>
              <Button
                color="success"
                icon="ThumbsUp"
                outline={true}
                name="Helpful"
                loading={isChangingHelpful}
                onClick={() => {
                  onChangeHelpfulStatus(true);
                }}
              />
              <Button
                className="mg-l-5"
                color="danger"
                icon="ThumbsDown"
                outline={true}
                name="Not Helpful"
                loading={isChangingHelpful}
                onClick={() => {
                  onChangeHelpfulStatus(false);
                }}
              />
            </>
          )}
          {canPublish && !isPublish && (
            <Button
              className="mg-l-5"
              color="primary"
              icon="ArrowUp"
              outline={true}
              name="Publish"
              loading={isChangingPublish}
              onClick={() => {
                onChangePublishStatus(true);
              }}
            />
          )}
          {canPublish && isPublish && (
            <Button
              className="mg-l-5"
              color="secondary"
              icon="ArrowDown"
              outline={true}
              name="UnPublish"
              loading={isChangingPublish}
              onClick={() => {
                onChangePublishStatus(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default KnowledgeBaseContent;
