import React, { useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { ListContainer } from '@base/components/list';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { handleCheckbox } from '@base/utils/helpers/list-page-utils';
import { SpanLang } from '@base/components/form';
import { listDataByMenuAtom } from '@base/recoil/atoms';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';

import PageHeader from './header';
import PageToolbar from './toolbar';
import PageBody from './body';
import PageQuickAction from './quick-actions';
import {
  DEFAULT_CATEGORY,
  MAIN_MENU,
  PAGE_MENU,
  WritePage,
} from '@desk/knowledge-base/config/pages/main-page';
import { WRITE_OPTION_ATOM } from '@desk/knowledge-base/config/pages/write-page';
import { MENU_DESK } from '@base/config/menus';
import { buildListSchema } from '@base/utils/helpers/schema';
import { FilterInput } from '@base/types/interfaces/common';
import { default as configFields } from '@desk/knowledge-base/config/view-field';
import { ListPageConfig } from '@desk/knowledge-base/config/pages/list-page';
import { KEY_KNOWLEDGE_BASE_ID } from '@desk/knowledge-base/config/key-names';
import displayLayoutConfig from '@desk/knowledge-base/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';

interface IListPage {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
}

const ListPage: React.FC<IListPage> = (props) => {
  const { isSplitMode, setIsSplitMode } = props;
  //router
  const params = useParams();
  //state
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(PAGE_MENU));
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [{ isDesktop, isMobile, isTablet, device, layout }] = useRecoilState(deviceAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [writeOption, setWriteOption] = useRecoilState(WRITE_OPTION_ATOM); //for write page
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(PAGE_MENU)); //current list
  //params
  const category = DEFAULT_CATEGORY;
  const layoutMenu = `${MAIN_MENU}_${category}`; //for list
  const writeCategory = `${MAIN_MENU}_${writeOption.writeType}`; //for write
  const keyTitle = "New Knowledge-Base";//`crm_new_${writeCategory}`;
  const navigate = useNavigate();
  let { filter } = pageData;
  // console.log(PAGE_MENU, filter);
  /*===================================== HOOK ===================================== */
  //get pagelayout
  let { data: listLayoutData, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    layoutMenu,
    'list',
  );

  //fields for build schema
  let viewingFields: any = [];
  if (pageData.activeMenu === category && pageData.settingColumns) {
    viewingFields = pageData.settingColumns.filter((_ele: any) => _ele.isViewing);
  }
  //// console.log('viewingFields', viewingFields);

  //get list data
  // let listQuerySchema = listLayoutData ? buildListSchema({ viewingFields, configFields }) : '';
  let listQuerySchema =
    listLayoutData?.data && viewingFields.length > 0
      ? buildListSchema({ fields: [...viewingFields, { keyName: 'isPublish' }], configFields })
      : '';
  let filtersQuery: FilterInput = {
    //filters: { ...filter?.baseFilters, ...filter?.searchFilters, ...filter?.headerFilters },
    sort: filter?.sort,
    paging: filter?.paging,
    query: ParseFiltersToQuery(filter, ListPageConfig.dateByOptions, ListPageConfig.searchFields),
  };
  const { isLoading, data, isFetching, isPreviousData, refetch } = ListPageConfig.useFectchList(
    filtersQuery,
    listQuerySchema,
  );

  //set viewing columns
  useEffect(() => {
    if (listLayoutData?.data) {
      const newPageData: any = { ...pageData };
      const newItems: any[] = [];
      listLayoutData?.data.map((_ele: any) => {
        newItems.push({ ..._ele, isViewing: _ele.isDefault });
      });
      newPageData.settingColumns = newItems;
      newPageData.activeMenu = category;
      setPageData(newPageData);
    }
  }, [listLayoutData, category]);

  //set data
  useEffect(() => {
    if (data?.data) {
      //set recoil view data
      //const customersList = makeCustomers(data?.data, { category: category });
      setListData(data?.data);
    } else {
      setListData([]);
    }
  }, [data?.data]);

  //checked or unchecked item
  // const handleCheckItem = useCallback(
  //   (checkedValue: string | number) => {
  //     const checkedValueStr = checkedValue as string;
  //     let newSelectedIds = handleCheckbox(checkedValueStr, selectedIds, listData);
  //     setSelectedIds([...newSelectedIds]);
  //   },
  //   [selectedIds, setSelectedIds],
  // );
  //for react-table v8
  const handleCheckItem = useCallback(
    (checkedValue: any[]) => {
      setSelectedIds(checkedValue);
    },
    [selectedIds],
  );
  //go view for new in case of split
  const handleGoView = (row: any) => {
    const url = `/${MAIN_MENU}/${category}/${row.id}`;
    navigate(url);
  };

  /** ============================ RENDER ================================ */
  //toolbar
  const PageToolbarMemo = useMemo(() => {
    return (
      <PageToolbar
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        category={category}
        refetch={refetch}
      />
    );
  }, [category]);

  //header
  const PageHeaderMemo = useMemo(() => {
    const columnKeys = pageData.settingColumns.map((col: any) => col?.name || '') || [];
    return (
      <PageHeader
        isSplitMode={isSplitMode}
        columnKeys={columnKeys} //TODO: for search
        fields={pageData.settingColumns || []}
        category={category}
        onChecked={handleCheckItem}
        refetch={refetch}
      />
    );
  }, [category, pageData?.settingColumns, pageData.filter, listData]);

  //body
  const PageBodyMemo = useMemo(() => {
    return (
      <PageBody
        isSplitMode={isSplitMode}
        category={category}
        isFetching={false}
        fields={viewingFields}
        itemsList={listData}
        paging={data?.paging}
        //isPreviousData={isPreviousData}
        selectedIds={selectedIds}
        onChecked={handleCheckItem}
      />
    );
  }, [isSplitMode, listData, viewingFields, selectedIds, pageData]);

  //track get list and go to first order view
  useEffect(() => {
    //if first load and split = true, go to first item view, router
    if (params?.id === undefined && isSplitMode && listData.length > 0) {
      const [firstOrder] = listData;
      const url = `/${MENU_DESK}/${category}/${firstOrder[KEY_KNOWLEDGE_BASE_ID]}`;
      navigate(url);
    }
  }, [category, listData]);

  //main
  return (
    <>
      <ListContainer
        isLoading={isLayoutLoading || isLoading}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={PageToolbarMemo}
          header={PageHeaderMemo}
          body={PageBodyMemo}
        />
      </ListContainer>

      <PageQuickAction
        category={category}
        itemsList={listData}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onReload={refetch}
      />

      {/* hook form - write new write */}
      {writeOption.isOpenWrite && writeOption.writeType === DEFAULT_CATEGORY && (
        <WritePage
          title={<SpanLang keyLang={keyTitle} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          onReload={refetch}
          menuApi={writeCategory}
        />
      )}
    </>
  );
};

export default ListPage;
