import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { ListType } from '@base/types/enums';
import ListToolbarContainer from '@base/containers/list/toolbar';
import { DEFAULT_CATEGORY, MAIN_MENU } from '@desk/ticket/config/pages/main-page';
import { pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { WRITE_OPTION_ATOM } from '@desk/ticket/config/pages/write-page';
import { categoryOptions } from '@desk/main/config';
import { MENU_DESK_TICKET } from '@base/config/menus';

const PageToolbar: React.FC<any> = (props) => {
  const { isSplitMode, setIsSplitMode, category, refetch } = props;
  //recoil
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_DESK_TICKET));
  const [writeOption, setWriteOption] = useRecoilState(WRITE_OPTION_ATOM); //for write page
  //state
  let { listType } = pageData;
  const navigate = useNavigate();

  //do action
  const handleSelectAction = (actionKey: string) => {
    //// console.log('actionKey', actionKey);
  };

  //more actions
  const moreActions = {
    actions: {
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => handleSelectAction(key),
  };

  return (
    <>
      <ListToolbarContainer
        listTypeProps={{
          listTypeStr: listType,
          onChangeListType: (listType: ListType) => {
            let newPageData = {
              ...pageData,
              listType: listType,
            };
            setPageData(newPageData);
          },
          listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
        }}
        actionsProps={moreActions}
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        onRefresh={refetch}
        onAdd={(selectedCategory: string | undefined) =>
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: category === undefined ? DEFAULT_CATEGORY : category,
          }))
        }
        categoryOptions={categoryOptions}
        categorySelected={category}
        onCategoryChange={(category: string) => navigate(`/${MAIN_MENU}/${category}`)}
        menu={MENU_DESK_TICKET}
      />
    </>
  );
};

export default PageToolbar;
