import React, { useState } from 'react';
import { ListBody, ListContainer, ListToolbar } from '@base/components/list';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms';

import { ChevronDown, Plus, Star } from 'react-feather';
import { useRecoilState } from 'recoil';
import displayLayoutConfig from '@desk/main/config/display-layout';
import { ListType } from '@base/types/enums';
import { useNavigate } from 'react-router-dom';
import { categoryOptions, MAIN_MENU } from '@desk/main/config';
import { DEFAULT_CATEGORY } from '@desk/main/config/pages/dashboard-page';

import DashboardLayout from '@desk/main/layouts/desktop/layout1/dashboard/dashboard';
import ReportLayout from '@desk/main/layouts/desktop/layout1/dashboard/report';
import DashboardToolbar from '@desk/main/components/dashboard-toolbar';
interface IDashboardProps {}
const Dashboard: React.FC<IDashboardProps> = (props: IDashboardProps) => {
  const category = DEFAULT_CATEGORY;
  // const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MAIN_MENU));
  const isLoading = false;
  const [showReport, setShowReport] = useState(false);
  const navigate = useNavigate();
  const [{ isDesktop, isMobile, isTablet, device, layout }] = useRecoilState(deviceAtom);
  return (
    <>
      <ListContainer isLoading={isLoading} isSplitMode={false} isDesktop={isDesktop}>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="dashboard"
          toolbar={
            <>
              <DashboardToolbar
                onAdd={(category: string | undefined) => {}}
                categoryOptions={categoryOptions}
                categorySelected={category}
                onCategoryChange={(category: string) => navigate(`/${MAIN_MENU}/${category}`)}
                setShowReport={setShowReport}
                showReport={showReport}
              />
            </>
          }
          header={<></>}
          body={
            <>
              <ListBody>{!showReport ? <DashboardLayout /> : <ReportLayout />}</ListBody>
            </>
          }
        />

        {/* Tablet layout here */}
        {isTablet && null}

        {/* Mobile layout here */}
        {isMobile && null}
      </ListContainer>
    </>
  );
};

export default Dashboard;
