import React, { useEffect, useState } from 'react';
import displayLayoutConfig from '@desk/ticket/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import ViewLeft from '@desk/ticket/layouts/desktop/layout1/view/view-left';
//import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, pageDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useNavigate, useParams } from 'react-router-dom';
import { default as viewConfig } from '@desk/ticket/config/view-field';
import {
  DEFAULT_CATEGORY,
  DEFAULT_ROUTE,
  LIST_ROUTE,
  MAIN_MENU,
  NAME_KEY,
  WritePage,
} from '@desk/ticket/config/pages/main-page';
import ViewRight from '@desk/ticket/layouts/desktop/layout1/view/view-right';
import { useGetView } from '@base/services/view';
import Title from '@base/containers/view-field/title';
import { deskWriteOptionAtom } from '@desk/main/recoil/atom/write-atom';
import { DESK_MENUS } from '@base/config/route-menus';
import { MENU_DESK_TICKET } from '@base/config/menus';
import { SpanLang } from '@base/components';
import { KEY_TICKET_PROCESS, KEY_TICKET_SUBJECT } from '@desk/ticket/config/key-names';
import { getTabs } from './tabs';
import StatusBar from '@settings/process/components/statusbar/StatusBar';
import WriteReplyForm from '@desk/ticket/containers/write-reply-form';
import WriteCommentForm from '@desk/ticket/containers/write-comment-form';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import {
  DESK_TICKET_CLOSE,
  DESK_TICKET_DELETE,
  DESK_TICKET_SPAM,
} from '@desk/ticket/services/graphql/ticket';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import PrintPreview from '@desk/ticket/containers/print-preview';

interface IViewProps {
  isSplitMode?: boolean;
}

const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const { isSplitMode } = props;
  //state
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const category = DEFAULT_CATEGORY;
  const categoryWithPrefix = `${MAIN_MENU}_${category}`;
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom); //app data
  const pageData = useRecoilValue(pageDataByMenuAtom(categoryWithPrefix)); //list filter data
  const { activeId, activeTab } = pageData;
  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = activeTab != params['*'] ? params['*'] : activeTab ?? '';
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_DESK_TICKET)); //view data
  const [writeOption, setWriteOption] = useRecoilState(deskWriteOptionAtom); //write form
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [isOpenReply, setIsOpenReply] = useState(false);
  const [isOpenComment, setIsOpenComment] = useState(false);
  const [isOpenForward, setIsOpenForward] = useState(false);
  const [showPrint, setShowPrint] = useState(false);

  // Mutation
  //delete
  const mDelete: any = useMutationPost<BaseMutationResponse>(
    DESK_TICKET_DELETE,
    'desk_deleteTicket',
    {
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );

  //close
  const mClose: any = useMutationPost<BaseMutationResponse>(DESK_TICKET_CLOSE, 'desk_closeTicket', {
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      //// console.log('mutation error', error);
      toast.error('There is an error during processing: ' + JSON.parse(error).message);
    },
  });

  //delete
  const mSpam: any = useMutationPost<BaseMutationResponse>(
    DESK_TICKET_SPAM,
    'desk_reportSpamTicket',
    {
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );
  /** Get data */
  const { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    categoryWithPrefix,
    'view',
  );
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const {
    isLoading,
    data: ticketData,
    isFetching: dataLoading,
    refetch,
  } = useGetView<any>({ menu: 'desk_ticket', schemas: viewSchema, params: { id } });
  //// console.log('ticketData', ticketData);

  //get detail data
  useEffect(() => {
    if (!isLayoutLoading && !isLoading && ticketData) {
      const layoutData = mergeLayoutData(layoutView, ticketData, viewConfig);
      let newLayoutData: any = { ...layoutData };
      newLayoutData.menuSourceId = id;
      newLayoutData.menuSource = MENU_DESK_TICKET;
      newLayoutData.menu = categoryWithPrefix;
      setViewData(newLayoutData);
    }
  }, [ticketData, isLoading, isLayoutLoading]);

  /** ================================== VIEW PROPS ================================== */
  //ticket subject
  const keySubIndex = viewData?.layout?.data[0]?.children?.findIndex(
    (_ele: any) => _ele.keyName === KEY_TICKET_SUBJECT,
  );
  const pageTitle = keySubIndex > -1 ? viewData.layout.data[0].children[keySubIndex].data : '';
  //ticket process
  const keyProcessIndex = viewData?.layout?.data[0]?.children?.findIndex(
    (_ele: any) => _ele.keyName === KEY_TICKET_PROCESS,
  );
  const process =
    keyProcessIndex > -1 ? viewData.layout.data[0].children[keyProcessIndex].data : '';

  const goToList = () => {
    //
    queryClient.invalidateQueries(['desk_ticket']);
    navigate(DEFAULT_ROUTE);
  };
  const handleDelete = () => {
    if (id === '') {
      return;
    }
    mDelete.mutate(
      { ids: [id] },
      {
        onSuccess: (res: any) => {
          toast.success('Data has removed!');
          // go to list
          goToList();
        },
      },
    );
  };
  const handleClose = () => {
    if (id === '') {
      return;
    }
    mClose.mutate(
      { ids: [id] },
      {
        onSuccess: (res: any) => {
          toast.success('Data has closed!');
          queryClient.invalidateQueries(['desk_ticket']);
          // go to list
          // goToList();
        },
      },
    );
  };
  const handleSpam = () => {
    if (id === '') {
      return;
    }
    mSpam.mutate(
      { ids: [id] },
      {
        onSuccess: (res: any) => {
          toast.success('Data has reported!');
          queryClient.invalidateQueries(['desk_ticket']);
          // go to list
          // goToList();
        },
      },
    );
  };
  const moreActions = [
    {
      label: 'Reply',
      icon: 'CornerUpLeft',
      onClick: () => setIsOpenReply(true),
    },
    {
      label: 'Forward',
      icon: 'CornerUpRight',
      onClick: () => setIsOpenForward(true),
    },
    {
      label: 'Comment',
      icon: 'MessageSquare',
      onClick: () => setIsOpenComment(true),
    },
    {
      label: 'Close',
      icon: 'XCircle',
      onClick: () => {
        // reply
        handleClose();
      },
    },
    {
      label: 'Spam',
      icon: 'AlertCircle',
      onClick: () => {
        // reply
        handleSpam();
      },
    },
  ];
  const viewProps = {
    isLoading: dataLoading,
    headerProps: {
      menu: category,
      menus: DESK_MENUS, //menu list
      isDeleting: false,
      splitViewMode: isSplitMode,
      onPrint: () => setShowPrint(true),
      onDelete: () => {
        handleDelete();
      },
      onNew: (category: string | undefined) => {
        setWriteOption((filter) => ({
          ...filter,
          isOpenWrite: true,
          writeType: MENU_DESK_TICKET,
          writeMode: 'view',
        }));
      },
      moreActions: moreActions,
      // stages: [
      //   { label: 'Done', value: 'done' },
      //   //{ label: 'Cancel', value: 'cancel' },
      // ],
      // onStageChange: () => null,
      componentLeft: (
        <>
          <Title
            value={pageTitle}
            //onSave={(params: any) => {}}
            keyName={KEY_TICKET_SUBJECT}
            menuSourceId={id}
            menuSource={MENU_DESK_TICKET}
            userPermission={{ isEdit: true }}
            viewConfig={viewConfig}
          />
        </>
      ),
    },
    componentTop: process ? (
      // <TicketStatus status={{ keyName: '1', name: 'New' }} />
      <StatusBar docId={id} processId={ticketData?.process?.id} menu={'MENU_DESK'} />
    ) : null,
    centerProps: {
      showTop: true,
      menuSource: MENU_DESK_TICKET,
      menuSourceId: id,
      menuSourceName: pageTitle,
      sourceData: ticketData,
      tabs: getTabs(),
      currentTab: currentTab,
    },
    componentLeft: (
      <ViewLeft showTop={true} id={id} category={MENU_DESK_TICKET} onRefetch={refetch} />
    ),
    leftProps: {},
    componentRight: <ViewRight showTop={true} id={id} category={MENU_DESK_TICKET} />,
  };

  return (
    <>
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...viewProps}
      />
      {/* hook form - write new write */}
      {writeOption.isOpenWrite &&
        writeOption.writeType === MENU_DESK_TICKET &&
        writeOption.writeMode === 'view' && (
          <WritePage
            title={<SpanLang keyLang={'New Ticket'} />}
            size="lg"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={goToList} //TODO
            menuApi={categoryWithPrefix}
          />
        )}
      {isOpenComment && (
        <WriteCommentForm
          //ticketId
          title={<SpanLang keyLang={'New Comment'} />}
          showCanvas={true}
          isOpen={isOpenComment}
          onClose={() => setIsOpenComment(false)}
        />
      )}
      {isOpenReply && (
        <WriteReplyForm
          //ticketId
          //type='reply'
          title={<SpanLang keyLang={'New Reply'} />}
          showCanvas={true}
          isOpen={isOpenReply}
          onClose={() => setIsOpenReply(false)}
        />
      )}
      {isOpenForward && (
        <WriteReplyForm
          //ticketId
          //type='forward'
          title={<SpanLang keyLang={'New Forward'} />}
          showCanvas={true}
          isOpen={isOpenForward}
          onClose={() => setIsOpenForward(false)}
        />
      )}
      {showPrint && (
        <PrintPreview
          title='Ticket Print Preview'
          isOpen={showPrint}
          onClose={() => setShowPrint(false)}
          data={viewData}
        />
      )}
    </>
  );
};

export default ViewPage;
