import React from 'react';
import classNames from 'classnames';
import CustomerContact from '@customer/customer/containers/customer-contact';
import AssignGroupRep from '../../assign-group-rep';

const Edit: React.FC = (props: any) => {
  const { value, errors, componentProps, onChange } = props;
  //// console.log('edit value', value);

  return (
    <div>
      <div className={classNames({ 'is-required': errors?.isRequired })}>
        <AssignGroupRep hideGroupLabel={true} value={value} onChange={onChange} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default Edit;
