import React, { useEffect, useState } from 'react';
// import { useRecoilValue } from 'recoil';
// import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { Plus } from 'react-feather';
import { default as customerViewConfig } from '@customer/customer/config/view-field';
import * as keyNames from '@customer/customer/config/key-names';
import { ViewFields } from '@base/components/hook-form/view';
import { MENU_CUSTOMER, MENU_CUSTOMER_CONTACT } from '@base/config/menus';
import WriteCustomer from '@customer/customer/pages/write';
import { SpanLang } from '@base/components';
import ContactName from '@desk/ticket/containers/view-field/contact-name';

interface ITicketContactInfoProps {
  menuSource: string;
  menuSourceId: string;
  account?: any;
  contact: any;
}
const TicketContactInfo: React.FC<ITicketContactInfoProps> = (props: ITicketContactInfoProps) => {
  const { menuSource, menuSourceId, account, contact } = props;
  //// console.log('data', contact);
  //state
  const [showAdd, setShowAdd] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  // const viewData = useRecoilValue(viewDataByMenuAtom(menuSource));

  //build fields by config
  const buildFieldConfig = (data: any, keyName: string, languageKey: string) => {
    return {
      config: customerViewConfig[keyName],
      data,
      keyName,
      languageKey,
      userPermission: { isEdit: true, isShow: true },
    };
  };
  let customerBasicFields: any[] = [];
  if (contact) {
    let compConfig = {
      component: ContactName,
      componentProps: {
        showSymbol: true,
      },
      schema: `name`,

      getMutationValue: (value: any) => {
        return { [keyNames.KEY_NAME_CUSTOMER_NAME]: value.name };
      },
    };
    let cusNameComponent = buildFieldConfig(contact, keyNames.KEY_NAME_CUSTOMER_NAME, 'Name');
    cusNameComponent.config = compConfig;

    customerBasicFields.push(cusNameComponent);
    customerBasicFields.push(
      buildFieldConfig(
        contact[keyNames.KEY_NAME_CUSTOMER_EMAIL],
        keyNames.KEY_NAME_CUSTOMER_EMAIL,
        'Email',
      ),
    );
    customerBasicFields.push(
      buildFieldConfig(
        contact[keyNames.KEY_NAME_CUSTOMER_PHONES],
        keyNames.KEY_NAME_CUSTOMER_PHONES,
        'Phone',
      ),
    );
  }
  //// console.log('customerBasicFields', customerBasicFields);

  return (
    <>
      <div className="mg-b-3 pd-x-10 pd-y-3">
        <div className="form-row mg-x-0 mg-t-10">
          <ViewFields
            fields={customerBasicFields}
            ignoreFields={[]}
            menu={MENU_CUSTOMER}
            menuSource={MENU_CUSTOMER} //data?.category === 'CATEGORY_ACCOUNT' ? 'customer_account' : 'customer_contact'
            menuSourceId={contact?.id}
          />
        </div>
        {account && (
          <>
            <button
              type="button"
              className="btn btn-link mg-l-auto pd-0"
              onClick={() => setShowAdd(true)}
            >
              <Plus />
              Add New Contact
            </button>
            {showAdd && (
              <WriteCustomer
                title={<SpanLang keyLang={'New Contact'} />}
                size="lg"
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                isOpen={showAdd}
                account={{
                  id: account.id, //account id
                  name: account.name, //account name
                  value: account.id,
                  label: account.name,
                }}
                menuApi={MENU_CUSTOMER_CONTACT}
                onClose={() => setShowAdd(false)}
                //onReload={onCallback} //TODO
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TicketContactInfo;
