import { NoData } from '@base/components';
import Loading from '@base/components/loading';
import { MENU_SOURCE } from '@base/config/menus';
import { KnowledgeTextView } from '@base/containers/quick-view';
import useMutationPost from '@base/hooks/useMutationPost';
import { FilterInput } from '@base/types/interfaces/common';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import {
  CREATE_KNOWELEDGEBASE_INSERTED,
  DELETE_KNOWELEDGEBASE_INSERTED,
} from '@desk/knowledge-base/services/graphql/knowledge-base';
import { useKBInserteds } from '@desk/knowledge-base/services/knowledge-base-service';
import { KBInserted, KnowledgeBase } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import KnowledgeAutoComplete from '@desk/ticket/containers/knowledge-auto-complete';
import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';

interface KnowledgeBaseInsertedProps {
  menuSource: string;
  menuSourceId: string;
}

const KnowledgeBaseInserted = (props: KnowledgeBaseInsertedProps) => {
  // console.log(props)
  const { menuSourceId, menuSource } = props;
  const menu = MENU_SOURCE[menuSource];
  //state
  const [isSaving, setIsSaving] = useState(false);
  const [items, setItems] = useState<any>([]);
  //get list
  const params: FilterInput = {
    query: 'menu=' + menu + ' refId=' + menuSourceId,
  };
  const { data, isLoading, refetch } = useKBInserteds({ filter: params });
  //save
  const mAddKbInserted: any = useMutationPost<BaseMutationResponse>(
    CREATE_KNOWELEDGEBASE_INSERTED,
    'desk_createKBInserted',
    {
      onSuccess: (res: any) => {
        toast.success('This knowledge has added!');
        refetch();
        setIsSaving(false);
      },
      onError: (res: any) => {
        setIsSaving(false);
      },
    },
  );
  //delete
  const mDeleteKBInserted: any = useMutationPost<BaseMutationResponse>(
    DELETE_KNOWELEDGEBASE_INSERTED,
    'desk_deleteKBInserted',
    {
      onSuccess: (res: any) => {
        toast.success('This knowledge has deleted!');
        refetch();
        setIsSaving(false);
      },
      onError: (res: any) => {
        setIsSaving(false);
      },
    },
  );
  //assign KB to ticket
  const handleAssign = (item: KnowledgeBase) => {
    setIsSaving(true);
    mAddKbInserted.mutate({
      refId: {
        id: menuSourceId,
        name: '',
      },
      menu: menu,
      knowledge: {
        id: item.id,
        name: item.subject,
      },
    });
  };
  //delete KB
  const handleDelete = (item: KBInserted) => {
    setIsSaving(true);
    mDeleteKBInserted.mutate({
      ids: [item.id],
    });
  };
  //init list
  useEffect(() => {
    if (data?.results) {
      setItems(data.results);
    } else {
      setItems([]);
    }
  }, [data]);
  /** ======================================== RENDER ======================================== */
  //items view
  const renderItems = () => {
    return (
      <>
        {items.length > 0 &&
          items.map((item: any, index: number) => {
            return (
              <div
                className="d-flex flex-wrap align-items-center mg-b-3 pd-x-10 pd-y-3"
                key={item.id}
              >
                {/* <a
                  target="_blank"
                  title={item.knowledge?.subject ? item.knowledge?.subject : ''}
                  className="mg-r-10 pointer"
                  onClick={() => {
                    handleShowDetail(item.knowledge?.id ?? '');
                  }}
                >
                  {item.knowledge?.subject ? item.knowledge?.subject : ''}
                </a> */}
                <KnowledgeTextView
                  value={{ id: item.knowledge?.id, name: item.knowledge?.subject }}
                />
                <div className="d-flex align-items-center mg-l-auto">
                  <div className="tags">
                    {item.knowledge?.tags
                      ? item.knowledge.tags.map((tag: any) => {
                          return (
                            <span className="tag-item" key={tag.id}>
                              {tag.name}
                            </span>
                          );
                        })
                      : ''}
                  </div>
                  <button
                    type="button"
                    className="btn btn-link btn-icon han-tooltip--top"
                    data-han-tooltip="Delete"
                    onClick={() => {
                      handleDelete(item);
                    }}
                  >
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
            );
          })}
      </>
    );
  };
  return (
    <>
      <div className="pos-relative mg-b-10">
        {(isLoading || isSaving) && <Loading />}
        {!isLoading && renderItems()}
        {items.length == 0 && <NoData icon={'Package'} iconType={'feather'} />}
      </div>
      <KnowledgeAutoComplete
        visible={false}
        addLabel={'Add new knowledge'}
        onAdd={() => console.log('add...')}
        onChange={handleAssign}
        single={true}
      />
    </>
  );
};

export default KnowledgeBaseInserted;
