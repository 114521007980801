import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { NoData } from '@base/components';
import { useTranslation } from 'react-i18next';
import { useKnowledgeBaseCategories } from '@desk/knowledge-base/services/knowledge-base-service';
import { ChevronDown, Folder } from 'react-feather';
import Loading from '@base/components/loading';
import { FormIcon } from '@base/components/form';

interface IKBCategoryAutoCompleteProps {
  className?: string;
  placeholder?: string;
  single?: boolean;
  visible?: boolean;
  isDisabled?: boolean;
  exceptItems?: any;
  value?: any;
  onChange?: any;
}

// const TEST_DATA = [
//   { id: 'c1', name: 'Category 1', children: [{ id: 'f1', name: 'Folder 1', children: [{ id: 'fc1', name: 'Folder Child 1' }] }, { id: 'f2', name: 'Folder 2' }] },
//   { id: 'c2', name: 'Category 2', children: [{ id: 'f3', name: 'Folder 3' }, { id: 'f4', name: 'Folder 4' }] },
//   { id: 'c3', name: 'Category 3', children: [{ id: 'f5', name: 'Folder 5' }, { id: 'f6', name: 'Folder 6' }] },
// ];

/**
 *
 * @param {*} props
 * @returns
 */
const KBCategoryAutoComplete: React.FC<IKBCategoryAutoCompleteProps> = (props) => {
  const {
    className,
    placeholder = '',
    single = false, //
    visible = true, //hide or display selected items
    isDisabled = false,
    value, //[], initial value
    onChange,
  } = props;
  const { t } = useTranslation();
  //state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  //const [options, setOptions] = useState<any>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<any>(null);
  const [openCategory, setOpenCategory] = useState<any>(null);
  const [openFolder, setOpenFolder] = useState<any>(null);
  const [openDepth, setOpenDepth] = useState<number>(-1);

  //find node in tree
  const findNode = (treeNodes: any[], nodeId: string, result: any): any => {
    for (let i = 0; i < treeNodes.length; i++) {
      if (treeNodes[i].id === nodeId) {
        //// console.log('nodes[i]', nodes[i]);
        result = treeNodes[i];
      }
      if (treeNodes[i]?.children?.length > 0) {
        result = findNode(treeNodes[i].children, nodeId, result);
      }
    }
    return result;
  };

  //outside click dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef && !dropdownRef?.current?.contains(event?.target)) {
        setShowDropdown(false);
      }
    };

    window?.addEventListener('mousedown', handleClickOutside);
    return () => {
      window?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  //initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          //2 cases for value: string[] or object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedValue?.map((_ele: any) => _ele.value);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fNode = findNode(treeData, _item, {});
                if (fNode) {
                  newValue.push(fNode);
                }
              });
              setSelectedValue(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
              setSelectedValue(value);
            }
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (_.isString(value)) {
          if (value !== selectedValue?.id) {
            //find in tree
            const fNode = findNode(treeData, value, {});
            if (fNode) {
              setSelectedValue(fNode);
            }
          }
        } else {
          if (value?.id !== selectedValue?.id) {
            setSelectedValue(value);
          }
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  //hook to get categories-folders
  const {
    data: postData,
    isFetching,
    //refetch,
  } = useKnowledgeBaseCategories({
    keyword: searchText,
    categoryId: openCategory?.id || '',
    folderParentId: openFolder?.id || '',
  });

  //init states list
  useEffect(() => {
    if (openDepth === -1) {
      //init root: all categories
      if (postData?.data) {
        setTreeData(_.cloneDeep(postData.data));
      } else {
        setTreeData([]);
      }
    } else {
      //add nodes
      if (postData?.data) {
        const newItems = _.cloneDeep(treeData); // [...treeData];
        const openId = openDepth === 0 ? openCategory.id : openFolder.id;
        let openNode = findNode(newItems, openId, {});
        if (openNode) {
          openNode.children = postData?.data;
          setTreeData(newItems);
        }
      }
    }
  }, [postData]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    //if (event.action !== 'input-blur' && event.action !== 'menu-close') {
    setInputText(inputText);
    setSearchTextDebounced(inputText);
    setOpenDepth(-1);
    setOpenCategory(null);
    setOpenFolder(null);
    //}
  };

  //value change
  const handleValueChange = (newValue: any) => {
    if (visible) {
      setSelectedValue(newValue);
    }
    //callback
    onChange && onChange(newValue);
    setInputText('');
    setShowDropdown(false);
  };

  //get folders
  const handleGetFolders = (parent: any, item: any, depth: number) => {
    setInputText('');
    setSearchText('');
    setOpenDepth(depth);
    if (depth === 0) {
      setOpenFolder(null);
      setOpenCategory(item); //item is category, parent = null
    } else {
      setOpenFolder(item); //item is folder, parent is category or folder
      setOpenCategory(item.category);
    }
  };

  //render children
  const renderTree = (parent: any, children: any[], depth: number) => {
    const paddingLeft = 20 + 20 * depth;
    return (
      <>
        {children.map((_child: any, index: number) => (
          <div key={index} className="">
            <div
              key={index}
              className={`dropdown-item cursor-default d-flex align-items-center justify-content-between pd-l-${paddingLeft} pd-r-20`}
            >
              <div className="d-flex flex-grow-1 align-items-center">
                {depth === 0 && (
                  <FormIcon
                    icon="category"
                    iconType="icon"
                    className="mg-r-5-f"
                    color="lightcoral"
                  />
                )}
                {depth > 0 && <Folder className="mg-r-5 tx-primary wd-20" />}
                <div className="d-flex flex-grow-1" onClick={() => handleValueChange({ ..._child, parent })}>
                  <span className="text-truncate">{_child.name}</span>
                </div>
              </div>
              {_child?.hasChild && (
                <div className="m-l-auto">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-toggle="collapse"
                    data-target={'#dropdown-node' + _child.id}
                    aria-expanded="false"
                    onClick={() => handleGetFolders(parent, _child, depth)}
                  >
                    {isFetching &&
                      (openFolder
                        ? openFolder?.id === _child.id
                        : openCategory?.id === _child.id) && (
                        <span
                          className="spinner-border spinner-border-sm tx-primary mg-r-2"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    <ChevronDown />
                  </button>
                </div>
              )}
            </div>
            {/* {_child?.children?.length > 0 && */}
            <div id={'dropdown-node' + _child.id} className="collapse fade">
              {renderTree(_child, _child?.children || [], depth + 1)}
            </div>
            {/* } */}
          </div>
        ))}
      </>
    );
  };

  //get node path name
  const getPathName = (node: any, pathName: string) => {
    //combine name
    pathName = node.name + (pathName ? ' > ' + pathName : '');
    //find parent
    if (node.parent && node.parent.type === 'TYPE_FOLDER') {
      const parentNode = findNode(treeData, node.parent.id, {});
      pathName = getPathName(parentNode, pathName);
    } else if (node.category) {
      pathName = getPathName(node.category, pathName);
    }
    return pathName;
  };

  //// console.log('selected value', selectedValue);
  const newPlaceholder = `Type or click to select category`;
  const noOptMsg = `No KB category found.`;
  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <div className={classNames('dropdown', { show: showDropdown })} ref={dropdownRef}>
        {/* <div className="input-group" data-toggle="dropdown"> */}
        <div className="input-group">
          {/* {single && ( */}
          <input
            type="text"
            className="form-control ht-38"
            placeholder={selectedValue ? '' : newPlaceholder}
            value={inputText}
            onFocus={() => setShowDropdown(true)}
            onChange={(e: any) => handleInputChange(e.target.value, e)}
          />
          {inputText.length === 0 && (
            <div className="pos-absolute l-20 z-index-50" style={{ top: '8px' }}>
              <span>
                {selectedValue ? getPathName(selectedValue, '') : ''}
                {/* {selectedValue?.category ?
                  (`${selectedValue.category.name || ''} > ${selectedValue.name || ''}`)
                  :
                  (selectedValue?.name || '')
                } */}
              </span>
            </div>
          )}
          {/* )} */}
          {/* {!single && (
          <div
            className={'form-control d-flex flex-wrap form-control-tags pd-2'}
            style={{ minHeight: 38 }}
            onClick={() => setShowDropdown(true)}
          >
            {tagValue?.map((_item: any, _index: number) => (
              <TagName
                key={_item.id}
                //id={type == "tag" ? null : _item.id}
                name={_item.name}
                onDelete={() => handleRemoveTag(_index)}
              />
            ))}
          </div>
        )} */}
          <div className="input-group-append">
            <button
              type="button"
              className="btn"
              style={{ padding: '0 12px' }}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <ChevronDown />
            </button>
          </div>
        </div>
        <div className={classNames('dropdown-menu wd-100p', { show: showDropdown })}>
          <div className="scroll-box" style={{ maxHeight: 250, overflowY: 'auto' }}>
            {isFetching && treeData.length === 0 && <Loading />}
            {treeData.length === 0 && <NoData label={noOptMsg} />}
            {treeData.length > 0 && renderTree(null, treeData, 0)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KBCategoryAutoComplete;
