import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ListBody, ListGrid, ListPagination } from '@base/components/list';
import ListLoading, { GridNothing, TableNothing } from '@base/components/list/list-loading';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { ListType } from '@base/types/enums';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import { DESC, ASC } from '@base/config/constant';

import ListGridItem from '@desk/knowledge-base/components/list/list-grid/list-grid-item';
import { ListPageConfig } from '@desk/knowledge-base/config/pages/list-page';
import { PAGE_MENU } from '@desk/knowledge-base/config/pages/main-page';
import ListMode from '@desk/knowledge-base/layouts/desktop/layout1/list/type/list-mode';
import { KnowledgeBaseCategory } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import { Button, Checkbox } from '@base/components/form';
import { ColumnDef } from '@tanstack/react-table';
import { ECategoryType } from '@desk/main/types/enums';
import { listDataByMenuAtom } from '@base/recoil/atoms';
import { CHANGE_KB_PUBLISH_STATUS } from '@desk/knowledge-base/services/graphql/knowledge-base';
import useMutationPost from '@base/hooks/useMutationPost';

const PageBody: React.FC<any> = (props) => {
  const {
    isSplitMode,
    category, //router category
    isFetching,
    fields,
    itemsList,
    paging,
    //isPreviousData,
    selectedIds,
    onChecked,
  } = props;
  const { t } = useTranslation();
  //state
  const { device } = useRecoilValue(deviceAtom);
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(PAGE_MENU)); //current list
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(PAGE_MENU));
  let { filter, listType } = pageData;

  //mutation
  const mUpdate: any = useMutationPost(CHANGE_KB_PUBLISH_STATUS, 'desk_changeKBPublishStatus');

  //update success
  useEffect(() => {
    if (mUpdate.isSuccess) {
      const newList = _.cloneDeep(listData);
      const fIndex = newList.findIndex((_ele: any) => _ele.id === mUpdate.data.ids[0]);
      if (fIndex > -1) {
        newList[fIndex].isPublish = mUpdate.variables.isPublish;
        setListData(newList);
      }
    }
  }, [mUpdate.isSuccess]);

  //set filter sort param
  const setSort = (sort: any) => {
    let newPageData = {
      ...pageData,
      filter: {
        ...pageData.filter,
        sort: sort,
      },
    };
    setPageData(newPageData);
  };

  //publish event
  const handlePublish = (row: any) => {
    const params = {
      ids: [row.id],
      isPublish: !row.isPublish,
    };
    mUpdate.mutate(params);
  };

  //grid props
  const listProps = {
    category: category, //router category
    listData: itemsList,
    isFetching,
    fetching: <ListLoading />,
    onCheckedRow: onChecked,
    //grid
    isSplitMode,
    selectedIds,
    device: device !== undefined ? device : 'desktop',
    nodata: <GridNothing />,
    columns: fields,
    columnRenderRemap: ListPageConfig.getColumnRenderRemap({ category }),
    hideColumns: [],
  };

  //config disable sortBy columns
  const disableSortByColumns = [''];

  //table props
  const newFields = fields.map((_ele: any) => ({
    ..._ele,
    disableSortBy: disableSortByColumns.includes(_ele.keyName),
  }));

  /** ================================== React Table 8 ================================== */
  //build columns for table v8
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        width: '45px',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="pd-x-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      ...makeTable8Columns(
        newFields,
        ListPageConfig.getColumnRenderRemap({ category }),
        { category },
        [],
      ),
      {
        id: 'action',
        width: '100px',
        header: ({ table }) => <span>Action</span>,
        cell: ({ row }: any) => {
          //// console.log('row', row);
          return (
            <div>
              <Button
                color={row.original.isPublish ? 'secondary' : 'success'}
                size="sm"
                loading={mUpdate.isLoading}
                disabled={mUpdate.isLoading}
                onClick={() => handlePublish(row.original)}
              >
                {row.original.isPublish ? 'Unpublish' : 'Publish'}
              </Button>
            </div>
          );
        },
      },
    ],
    [newFields, selectedIds],
  );

  const listTableProps = {
    nodata: <TableNothing />,
    data: itemsList,
    // loading: isFetching,
    columns: columns, //tableHideColumns
    initialState: {
      pageSize: paging?.itemPerPage || 15,
    },
    onRowSelect: onChecked,
    // rowSelected: selectedIds,
    onSortBy: (clName: any, isSortedDesc: boolean) => {
      // refetch();
      let orderBy = isSortedDesc ? DESC : ASC;
      // // console.log('sortBy', { field: clName, orderBy: orderBy });
      setSort({ field: clName, orderBy: orderBy });
    },
    className: 'kb-table',
  };

  //table paging
  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || 10,
    nextPage: paging?.nextPage || null,
    previousPage: paging?.previousPage || null,
    //isPreviousData,
    onChange: (page: number, size: number) => {
      let newFilter = {
        ...filter,
        paging: {
          page,
          size,
        },
      };
      let newPageData = {
        ...pageData,
        filter: newFilter,
      };
      setPageData(newPageData);
    },
    /*// for test pagination.
    ...{
      totalPage: 2,
      totalItems: 20,
      currentPage: 1,
      itemPerPage: 10,
    },*/
  };

  const onSelectKBCategory = useCallback(
    (category: KnowledgeBaseCategory | null) => {
      // update filter
      const curSearchFilters = pageData?.filter?.headerFilters
        ? pageData?.filter?.headerFilters
        : {};
      let newSearchFilter: any = {
        ...curSearchFilters,
      };
      if (category) {
        if (category?.type && category.type === ECategoryType.CATEGORY) {
          newSearchFilter.category = category.id;
        } else if (category?.type && category.type === ECategoryType.FOLDER) {
          newSearchFilter.folder = category?.id;
          newSearchFilter.category = category?.category?.id;
        } else {
          newSearchFilter.category = category?.id;
        }
      } else {
        delete newSearchFilter.folder;
        delete newSearchFilter.category;
      }

      let newData = {
        ...pageData,
        filter: {
          ...pageData.filter,
          headerFilters: newSearchFilter,
        },
      };
      setPageData(newData);
    },
    [[itemsList, isSplitMode, listType, pageData]],
  );

  //memorise
  const ListBodyMemo = useMemo(() => {
    //// console.log('render ListBodyMemo', filter);
    return (
      <ListBody>
        {isSplitMode ? (
          <ListGrid
            {...listProps}
            listGridItemRender={(props: any) => <ListGridItem {...props} />}
          />
        ) : (
          <>
            {/* table list */}
            {listType == ListType.LIST && (
              <ListMode listTableProps={listTableProps} onSelect={onSelectKBCategory} />
            )}
            {/* grid list */}
            {listType == ListType.GRID && (
              <ListGrid
                {...listProps}
                listGridItemRender={(props: any) => <ListGridItem {...props} />}
              />
            )}
          </>
        )}

        <ListPagination isSplitMode={isSplitMode} type={'full'} {...pagingProps} />
      </ListBody>
    );
  }, [itemsList, isSplitMode, listType, pageData]);

  //main
  return <>{ListBodyMemo}</>;
};

export default PageBody;
