import React, { useState } from 'react';
import PagelayoutSummary from '@base/containers/pagelayout-summary';
import { ViewPageConfig } from '@desk/knowledge-base/config/pages/view-page';
import KnowledgeBaseReport from '@desk/knowledge-base/containers/knowledge-base-report';
import TimeAttachment from '@base/containers/attachment/time-attachment';
import { useTranslation } from 'react-i18next';
import { FormIcon } from '@base/components/form';
import { ChevronDown, ChevronRight } from 'react-feather';
import classNames from 'classnames';

interface IViewLeftProps {
  id: string;
  category: string;
}
const ViewLeft: React.FC<IViewLeftProps> = (props: IViewLeftProps) => {
  const { category, id } = props;
  const { t } = useTranslation();
  const attachmentTitle = t('common_section_attachment');
  const [show, setShow] = useState(true);
  return (
    <div className="scroll-box h-100 overflow-auto">
      <KnowledgeBaseReport menuSource={category} menuSourceId={id} />
      <PagelayoutSummary
        menuSource={category}
        menuSourceId={id}
        ignoreFields={ViewPageConfig.ignoreSummaryFields}
        isCustomView={true}
      />
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {/* <FormIcon icon="move" iconType="icon" /> */}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">{attachmentTitle}</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto pd-0"
            data-toggle="collapse"
            onClick={() => {
              setShow(!show);
            }}
          >
            {show ? <ChevronDown /> : <ChevronRight />}
          </button>
        </div>
        <div
          id={`section-item-${id}`}
          className={classNames('view-aside-section-body collapse', show ? 'show' : '')}
        >
          <TimeAttachment menuSource={category} menuSourceId={id} />
        </div>
      </div>
    </div>
  );
};
export default ViewLeft;
