import Timeline from '@base/containers/timeline';
import Notes from '@base/containers/note';
import TicketContent from '@desk/ticket/containers/ticket-content';
import TeamChannel from '@desk/ticket/containers/team-channel';

export const getTabs = () => {
  return [
    {
      order: 1,
      default: true,
      label: 'Content',
      path: 'content',
      tabComponent: TicketContent,
    },
    {
      order: 2,
      label: 'Timeline',
      path: 'timeline',
      tabComponent: Timeline,
    },
    {
      order: 3,
      label: 'Note',
      path: 'note',
      tabComponent: Notes,
    },
    // {
    //   order: 4,
    //   label: 'Team channel',
    //   path: 'team-channel',
    //   tabComponent: TeamChannel,
    // },
  ];
};
