import React from 'react';
import { useKnowledgeBase } from '@desk/knowledge-base/services/knowledge-base-service';
import { ArrowLeft } from 'react-feather';
import RawHTML from '@base/components/raw-html';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import classNames from 'classnames';
import Loading from '@base/components/loading';

interface KBQuickViewProps {
  className?: string;
  id: string;
  onClose?: any;
  onInsert?: (item: any) => void;
}

const SCHEMA = `
  id
  subject
  content
  createdAt
  createdBy {
    id
    name
  }
`;

const KBQuickView = (props: KBQuickViewProps) => {
  const { className = '', id, onClose, onInsert } = props;

  //get data
  const { data, isFetching } = useKnowledgeBase(id, SCHEMA);

  return (
    <div className={classNames('pos-relative', className)}>
      {isFetching && <Loading />}
      <div className="d-flex align-items-center mg-b-15">
        <button
          type="button"
          className="btn btn-link btn-icon"
          aria-label="목록 보기"
          onClick={onClose}
        >
          <ArrowLeft />
        </button>
        <button type="button" className="btn btn-primary mg-l-auto" onClick={() => onInsert && onInsert(data)}>
          Insert
        </button>
      </div>
      <div className="card">
        <div className="card-header flex-column align-items-start">
          <span className="tx-16">{data?.subject}</span>
          <span className="tx-13 tx-color-03">
            {convertDateTimeServerToClient({ date: data?.createdAt })} created by {data?.createdBy?.name}
          </span>
        </div>
        <div className="card-body">
          <RawHTML>{data?.content}</RawHTML>
        </div>
      </div>
    </div>
  );
}

export default KBQuickView;