import React from 'react';

interface IViewProps {
  value: any;
}
const View: React.FC<IViewProps> = (props: IViewProps) => {
  const { value } = props;
  return <>{value?.name ? value.name : ''}</>;
};

export default View;
