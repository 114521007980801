import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CheckSquare, Download, Lock, Package, Slash, Tag, Trash2, UserCheck } from 'react-feather';
import { confirmAlert } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse, Paging, ResponseData } from '@base/types/interfaces/response';
import ListQuickAction from '@base/components/list/list-quick-action';
import {
  DESK_TICKET_BULK_UPDATE,
  DESK_TICKET_CLOSE,
  DESK_TICKET_DELETE,
  DESK_TICKET_SPAM,
} from '@desk/ticket/services/graphql/ticket';
import GroupUserModal from '@desk/ticket/containers/group-user-modal';
import ProductCategoryModal from '@desk/ticket/containers/product-category-modal';
import TagModal from '@desk/ticket/containers/tag-modal';
import Product from '@demo-page/settings/desktop/theme1/product';
import { useQueryClient } from '@tanstack/react-query';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import { useRecoilValue } from 'recoil';
import { pageDataByMenuAtom } from '@base/recoil/atoms';
import { MENU_DESK_TICKET } from '@base/config/menus';
import { stringify } from 'query-string';
import { keyStringify } from '@base/utils/helpers';
import { FilterInput } from '@base/types/interfaces/common';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import { ListPageConfig } from '@desk/ticket/config/pages/list-page';

const PageQuickAction: React.FC<any> = (props) => {
  const { category, itemsList, selectedIds, setSelectedIds, onReload } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  //state
  const [showGroupRep, setShowGroupRep] = useState(false);
  const [showProductCategory, setProductCategory] = useState(false);
  const [showTag, setShowTag] = useState(false);
  const pageDataValue = useRecoilValue(pageDataByMenuAtom(MENU_DESK_TICKET));

  //delete
  const mDeleteOld: any = useMutationPost<BaseMutationResponse>(
    DESK_TICKET_DELETE,
    'desk_deleteTicket',
    {
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );
  let filtersQuery: FilterInput = {
    keyword: pageDataValue?.filter?.keyword ?? '',
    sort: pageDataValue?.filter?.sort,
    paging: pageDataValue?.filter?.paging,
    query: ParseFiltersToQuery(
      pageDataValue?.filter ?? {},
      ListPageConfig.dateByOptions,
      ListPageConfig.searchFields,
    ),
  };
  const filterQueryKey = keyStringify(filtersQuery, '');
  const queryKeys = ['desk_tickets', filterQueryKey];
  const mDelete: any = useMutationPost<BaseMutationResponse>(
    DESK_TICKET_DELETE,
    'desk_deleteTicket',
    {
      // When mutate is called:
      onMutate: async (delParams: any) => {
        const delIds = delParams?.ids ?? [];
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKeys);

        // Snapshot the previous value
        const previousDatas = queryClient.getQueryData<ResponseData<ITicket>>(queryKeys);

        // Optimistically update to the new value
        if (previousDatas) {
          const newData = previousDatas?.data?.filter((item: ITicket) => {
            return !delIds.includes(item.id);
          });
          queryClient.setQueryData<ResponseData<ITicket>>(queryKeys, {
            ...previousDatas,
            data: [...newData],
          });
        }

        return { previousDatas };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err: any, variables: any, context: any) => {
        if (context?.previousDatas) {
          queryClient.setQueryData<ResponseData<ITicket>>(queryKeys, context.previousDatas);
        }
      },
    },
  );
  //close
  const mClose: any = useMutationPost<BaseMutationResponse>(DESK_TICKET_CLOSE, 'desk_closeTicket', {
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      //// console.log('mutation error', error);
      toast.error('There is an error during processing: ' + JSON.parse(error).message);
    },
  });

  //delete
  const mSpam: any = useMutationPost<BaseMutationResponse>(
    DESK_TICKET_SPAM,
    'desk_reportSpamTicket',
    {
      // When mutate is called:
      onMutate: async (params: any) => {
        const ids = params?.ids ?? [];
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKeys);

        // Snapshot the previous value
        const previousDatas = queryClient.getQueryData<ResponseData<ITicket>>(queryKeys);

        // Optimistically update to the new value
        if (previousDatas) {
          const newData = previousDatas?.data?.map((item: ITicket) => {
            if (ids.include(item.id)) {
              return {
                ...item,
                isSpam: true,
              };
            }
            return item;
          });
          queryClient.setQueryData<ResponseData<ITicket>>(queryKeys, {
            ...previousDatas,
            data: [...newData],
          });
        }

        return { previousDatas };
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );

  //bulk update mutation
  // const mUpdateBulk: any = useMutationPost<BaseMutationResponse>(
  //   DESK_TICKET_BULK_UPDATE,
  //   'desk_bulkUpdateTicket',
  //   {
  //     onError: (error: any, variables: any, context: any) => {
  //       // An error happened!
  //       toast.error('Create assign rep failed: ' + JSON.parse(error).message);
  //     },
  //   },
  // );
  const mUpdateBulk: any = useMutationPost<BaseMutationResponse>(
    DESK_TICKET_BULK_UPDATE,
    'desk_bulkUpdateTicket',
    {
      // When mutate is called:
      onMutate: async (bulkParams: any) => {
        const { ids, tags, product, category, assignedGroup, assignedUser, priority } = bulkParams;
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKeys);

        // Snapshot the previous value
        const previousDatas = queryClient.getQueryData<ResponseData<ITicket>>(queryKeys);

        // Optimistically update to the new value
        if (previousDatas) {
          const newData = previousDatas?.data?.map((item: ITicket) => {
            if (ids.include(item.id)) {
              return {
                ...item,
                tags: tags ?? item.tags,
                product: product ?? item.product,
                priority: priority ?? item.priority,
                category: category ?? item.category,
                assignedGroup: assignedGroup ?? item.assignedGroup,
                assignedUser: assignedUser ?? item.assignedUser,
              };
            }
            return item;
          });
          queryClient.setQueryData<ResponseData<ITicket>>(queryKeys, {
            ...previousDatas,
            data: [...newData],
          });
        }

        return { previousDatas };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (error: any, variables: any, context: any) => {
        if (context?.previousDatas) {
          queryClient.setQueryData<ResponseData<ITicket>>(queryKeys, context.previousDatas);
        }
        toast.error('Create assign rep failed: ' + JSON.parse(error).message);
      },
    },
  );

  /** CHECK MUTATION SUCCESS */
  useEffect(() => {
    if (mUpdateBulk.isSuccess) {
      // onReload && onReload();
      setSelectedIds([]);
    }
  }, [mUpdateBulk.isSuccess]);

  useEffect(() => {
    if (mDelete.isSuccess) {
      onReload && onReload();
      setSelectedIds([]);
    }
  }, [mDelete.isSuccess]);

  useEffect(() => {
    if (mClose.isSuccess) {
      onReload && onReload();
      setSelectedIds([]);
    }
  }, [mClose.isSuccess]);

  useEffect(() => {
    if (mSpam.isSuccess) {
      // onReload && onReload();
      setSelectedIds([]);
    }
  }, [mSpam.isSuccess]);

  //delete item
  const handleDelete = () => {
    mDelete.mutate(
      { ids: selectedIds },
      {
        onSuccess: (res: any) => {
          toast.success('Data has removed!');
        },
      },
    );
  };

  //close ticket
  const handleClose = () => {
    mClose.mutate(
      { ids: selectedIds },
      {
        onSuccess: (res: any) => {
          toast.success('Data has closed!');
        },
      },
    );
  };

  //close ticket
  const handleSpam = () => {
    mSpam.mutate(
      { ids: selectedIds },
      {
        onSuccess: (res: any) => {
          toast.success('Data has reported!');
        },
      },
    );
  };

  //change reps
  const handleGroupUserChange = (item: any) => {
    const params = {
      ids: selectedIds,
      assignedGroup: {
        id: item.assignedGroup.id,
        name: item.assignedGroup.name,
      },
      assignedUser: {
        user: {
          id: item.assignedUser.id,
          name: item.assignedUser.name,
        },
        group: null,
      },
    };
    mUpdateBulk.mutate(params);
  };

  //change tag
  const handleTagChange = (tagItems: any) => {
    const params = {
      ids: selectedIds,
      tags: tagItems.map((_ele: any) => ({ id: _ele.id, name: _ele.name })),
    };
    mUpdateBulk.mutate(params);
  };

  //change product category
  const handleProductCategoryChange = (item: any) => {
    const params = {
      ids: selectedIds,
      product: {
        id: item.product.id,
        name: item.product.name,
      },
      category: {
        id: item.category.id,
        name: item.category.name,
      },
      priority: item.priority.priority,
    };
    mUpdateBulk.mutate(params);
  };

  //buttons
  const quickButtons = [
    {
      title: 'Change Group/Rep',
      icon: <UserCheck />,
      callback: (selected: any) => {
        setShowGroupRep(true);
      },
    },
    {
      title: 'Change Product/Category',
      icon: <Package />,
      callback: (selected: any) => {
        setProductCategory(true);
      },
    },
    {
      title: 'Change Tag',
      icon: <Tag />,
      callback: (selected: any) => {
        setShowTag(true);
      },
    },
    {
      title: 'Closed',
      icon: <Lock />,
      callback: (selected: any) => {
        handleClose();
      },
    },
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        handleDelete();
      },
    },
    {
      title: 'Export',
      icon: <Download />,
      callback: (selected: any) => {},
    },
    {
      title: 'Spam',
      icon: <Slash />,
      callback: (selected: any) => {
        handleSpam();
      },
    },
  ];

  return (
    <>
      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons}
        resetSelected={() => setSelectedIds([])}
        visible={selectedIds.length > 0}
      />
      {showGroupRep && (
        <GroupUserModal
          title="Change Group / Rep"
          size="md"
          isLoading={mUpdateBulk.isLoading}
          onClose={() => setShowGroupRep(false)}
          onChange={handleGroupUserChange}
        />
      )}
      {showProductCategory && (
        <ProductCategoryModal
          title="Change Product / Category"
          size="md"
          isLoading={mUpdateBulk.isLoading}
          onClose={() => setProductCategory(false)}
          onChange={handleProductCategoryChange}
        />
      )}
      {showTag && (
        <TagModal
          title="Change Tag"
          size="md"
          isLoading={mUpdateBulk.isLoading}
          onClose={() => setShowTag(false)}
          onChange={handleTagChange}
        />
      )}
    </>
  );
};

export default PageQuickAction;
