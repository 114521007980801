import React from 'react';
import classnames from 'classnames';
import KBCategoryAutoComplete from '@desk/knowledge-base/containers/kb-category-auto-complete';

const Edit: React.FC = (props: any) => {
  const { value, errors, componentProps, onChange } = props;
  //// console.log('props edit', props);

  return (
    <div>
      <div className={classnames({ 'is-required': errors?.isRequired })}>
        <KBCategoryAutoComplete {...componentProps} value={value} onChange={onChange} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default Edit;
