import Icon from '@base/assets/icons/svg-icons';
import React from 'react';
import { Plus, Star } from 'react-feather';
import { FormIcon, Tooltip } from '@base/components/form';
import { MENU_DESK } from '@base/config/menus';
import ListFilter from '@base/containers/list/filter';

interface IDashboardToolbarProps {
  setShowReport: (showReport: boolean) => void;
  showReport: boolean;
  onAdd: (category: string | undefined) => void;
  categoryOptions: any;
  categorySelected: any;
  onCategoryChange: (catetory: string) => void;
  tooltipMessage?: any;
}
const DashboardToolbar: React.FC<IDashboardToolbarProps> = (props: IDashboardToolbarProps) => {
  const {
    showReport,
    setShowReport,
    onAdd,
    onCategoryChange,
    categoryOptions,
    categorySelected,
    tooltipMessage,
  } = props;
  return (
    <>
      <div className="list-header">
        <div className="d-flex align-items-center mg-r-10 mg-b-5">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">
            {!categoryOptions[categorySelected]
              ? '-- Menu --'
              : typeof categoryOptions[categorySelected] === 'object'
              ? categoryOptions[categorySelected].title
              : categoryOptions[categorySelected]}
          </h1>
          {onCategoryChange && categoryOptions && (
            <ListFilter
              listFilter={categoryOptions}
              value={categorySelected}
              onChange={onCategoryChange}
              useNewFilter={false}
              menu={MENU_DESK}
            />
          )}
          {tooltipMessage && (
            <Tooltip overlay={<div className="list-header-help">{tooltipMessage}</div>}>
              <i className="mg-x-5">
                <FormIcon icon="HelpCircle" iconType="feather" size={18} />
              </i>
            </Tooltip>
          )}
        </div>
        <div className="dropdown mg-l-auto">
          <button
            type="button"
            className="btn btn-primary pd-x-10"
            data-toggle="modal"
            data-target="#"
          >
            <Plus />
            <span className="sr-only">추가</span>
          </button>
          <button
            type="button"
            className="btn btn-info mg-l-5"
            onClick={() => setShowReport(!showReport)}
          >
            {showReport ? 'Dashboard' : 'Report'}
          </button>
        </div>
      </div>
    </>
  );
};

export default DashboardToolbar;
