import React from 'react';
import classnames from 'classnames';
import { Input } from '@base/components/form';

const Edit: React.FC = (props: any) => {
  const { value, onChange = () => {}, errors = {} } = props;
  return (
    <div className={classnames('wd-100p')}>
      <div
        className={classnames({
          'is-required': errors?.isRequired,
        })}
      >
        <Input
          {...props}
          value={value.name ?? ''}
          onChange={(nName: any) => {
            const nVal = {
              ...value,
              name: nName,
            };
            onChange(nVal);
          }}
        />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default Edit;
