import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Select } from '@base/components/form';
import { useTranslation } from 'react-i18next';
import { useTicketCategories } from '@desk/ticket/services/ticket-service';

interface IPrioritySelectProps {
  className?: string;
  single?: boolean;
  isDisabled?: boolean;
  productIds?: string[];
  value?: any;
  onChange?: (value: any) => void;
  placeholder?: string;
  isPublic?: boolean;
  token?: string;
}

const CategorySelect: React.FC<IPrioritySelectProps> = (props) => {
  const {
    className,
    single = true,
    isDisabled,
    productIds = [],
    value, //={}
    onChange,
    placeholder = 'Select...',
    isPublic,
    token,
  } = props;
  const { t } = useTranslation();
  //state
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  //init rows
  useEffect(() => {
    if (single) {
      if (value) {
        if (_.isString(value)) {
          if (value !== selectedOption?.value) {
            //find in options
            const fOption = options.find((_ele: any) => _ele.value === value);
            if (fOption) {
              setSelectedOption(fOption);
            }
          }
        } else {
          if (value?.id !== selectedOption?.id) {
            setSelectedOption(value);
          }
        }
      } else {
        setSelectedOption(null);
      }
    } else {
      if (value) {
        if (Array.isArray(value) && value.length > 0) {
          //2 cases: value=string[] or object[] + selectedOption=object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedOption?.map((_ele: any) => _ele.value);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fOption = options.find((_ele: any) => _ele.value === _item);
                if (fOption) {
                  newValue.push(fOption);
                }
              });
              setSelectedOption(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedOption)) {
              setSelectedOption(value);
            }
          }
        } else {
          setSelectedOption([]);
        }
      } else {
        setSelectedOption(null);
      }
    }
  }, [value, options]);

  // ============= HOOK get field options ======================
  const { data, isLoading, isFetching } = useTicketCategories('', productIds, isPublic, token);

  //init states list
  useEffect(() => {
    if (data?.data) {
      setOptions(data.data || []);
    }
  }, [data]);
  // ============= END get field options ======================

  //option change
  const handleValueChange = (newValue: any) => {
    setSelectedOption(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      <Select
        //outSide={true}
        isSearchable={false}
        isMulti={!single}
        isDisabled={isDisabled}
        isLoading={isFetching}
        isClearable
        options={options}
        getOptionLabel={(opt: any) => opt.name}
        getOptionValue={(opt: any) => opt.id}
        value={selectedOption}
        onChange={handleValueChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CategorySelect;
