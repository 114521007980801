export const REPLY_TYPE_OPTIONS = [
  { value: 'email', label: 'Email' },
  { value: 'sms', label: 'SMS' },
  // { value: 'call', label: 'Call' },
  // { value: 'fax', label: 'Fax' },
];

export const TICKET_COMMENT_KIND_NEW = 'KIND_COMMENT';
export const TICKET_COMMENT_KIND_REPLY = 'KIND_REPLY';
export const TICKET_COMMENT_KIND_FORWARD = 'KIND_FORWARD';
export const TICKET_COMMENT_DISPLAY_PUBLIC = 'DISPLAY_PUBLIC';
export const TICKET_COMMENT_DISPLAY_PRIVATE = 'DISPLAY_PRIVATE';
