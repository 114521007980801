import React from 'react';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { Mail, Phone } from 'react-feather';
import ListGridItemBase, {
  defaultColumnRender,
  ListGridItemHeader,
  ListGridItemBody,
} from '@base/components/list/list-grid/list-grid-item';
import { MENU_DESK_KNOWLEDGE } from '@base/config/menus';
import { KEY_KNOWLEDGE_BASE_SUBJECT } from '@desk/knowledge-base/config/key-names';
import { default as viewConfig } from '@desk/ticket/config/view-field';
import { MAIN_MENU } from '@desk/main/config';
import { DEFAULT_CATEGORY } from '@desk/knowledge-base/config/pages/main-page';

export interface IListGridItem {
  primaryKey: string | number;
  data: any;
  isSplitMode: boolean;
  onCheckedRow: (checkedValue: string | number) => void;
  selectedIds: string[];
  columns: {
    name: string;
    title: string;
  }[];
  // columnsOnSplitMode: {
  //   [key: string]: {
  //     icon?: React.ReactNode;
  //     isTag: boolean;
  //   };
  // };
  columnRender?: (col: string) => any;
}

const ListGridItem: React.FC<IListGridItem> = (props: any) => {
  const {
    category,
    primaryKey,
    data,
    isSplitMode,
    onCheckedRow,
    selectedIds,
    columns,
    columnRender,
  } = props;
  //lang
  const { t, i18n } = useTranslation();

  //router url
  const splitModeItems = [
    {
      component: (
        <li className="d-flex align-items-center mg-t-5">
          {data.category
            ? data.folder
              ? data.category.name + '/' + data.folder.name
              : data.category.name
            : ''}
        </li>
      ),
    },
  ];
  const url = `/${MAIN_MENU}/${DEFAULT_CATEGORY}/${data.id}`;
  return (
    <ListGridItemBase
      primaryKey={primaryKey || 'id'}
      data={data}
      isSplitMode={isSplitMode}
      onCheckedRow={onCheckedRow}
      selectedIds={selectedIds}
    >
      <ListGridItemHeader
        id={data.id}
        title={data[KEY_KNOWLEDGE_BASE_SUBJECT]}
        titleLink={url}
        keyName={KEY_KNOWLEDGE_BASE_SUBJECT}
        menuSource={MENU_DESK_KNOWLEDGE}
        viewConfig={viewConfig}
        //onView={() => true}
        renderLeftComponent={() => {
          return !data.isPublish ? (
            <>
              <span className="badge badge-secondary">Draft</span>&nbsp;
            </>
          ) : (
            ''
          );
        }}
      />
      <ListGridItemBody isSplitMode={isSplitMode}>
        {isSplitMode ? (
          <>
            {splitModeItems
              ? splitModeItems.map((item, i) => {
                  return <React.Fragment key={i}>{item.component}</React.Fragment>;
                })
              : ''}
          </>
        ) : (
          <div className="grid-list-item-detail-body">
            {columns &&
              columns.map((col: any, i: number) => {
                if (col.name === KEY_KNOWLEDGE_BASE_SUBJECT) {
                  return;
                }
                const columnComponent =
                  isFunction(columnRender) && columnRender(col.name)
                    ? columnRender(col.name)(col.name, data)
                    : defaultColumnRender(col.name, data);
                return (
                  <div className="grid-list-item-col" key={i}>
                    <span className="grid-list-item-col-title">{t(col.title) + ':'}</span>
                    <span className="grid-list-item-col-content">{columnComponent}</span>
                  </div>
                );
              })}
          </div>
        )}
      </ListGridItemBody>
    </ListGridItemBase>
  );
};

ListGridItem.defaultProps = {
  primaryKey: 'id',
};

export default ListGridItem;
