import { generateUUID } from '@base/utils/helpers';
import { isEmpty } from 'lodash';

export const finalizeParams = (formData: any) => {
  let newParams: any = {};
  //TODO
  // // console.log(formData);
  let {
    duration,
    dueDate,
    contact,
    category,
    channel,
    tags,
    assignedUser,
    assignedGroup,
    process,
    ...nTicketData
  } = formData;
  if (duration && typeof duration == 'object' && duration.duration) {
    nTicketData = {
      ...nTicketData,
      ...duration,
    };
  }
  if (contact !== '' && !isEmpty(contact)) {
    nTicketData.contact = contact;
  }
  if (channel !== '' && !isEmpty(channel)) {
    nTicketData.channel = channel;
  }
  if (category !== '' && !isEmpty(category)) {
    nTicketData.category = category;
  }
  if (assignedUser !== '' && !isEmpty(assignedUser)) {
    nTicketData.assignedUser = assignedUser;
  }
  if (assignedGroup !== '' && !isEmpty(assignedGroup)) {
    nTicketData.assignedGroup = assignedGroup;
  }
  if (tags.length > 0) {
    nTicketData.tags = tags;
  }
  if (process !== '' && !isEmpty(process)) {
    nTicketData.process = process;
  }

  newParams = {
    ticket: {
      ...nTicketData,
      id: generateUUID(),
    },
  };
  return newParams;
};
