import React, { useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { ListContainer } from '@base/components/list';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { handleCheckbox } from '@base/utils/helpers/list-page-utils';
import { SpanLang } from '@base/components/form';
import { listDataByMenuAtom } from '@base/recoil/atoms';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';

import PageHeader from './header';
import PageToolbar from './toolbar';
import PageBody from './body';
import PageQuickAction from './quick-actions';
import { DEFAULT_CATEGORY, MAIN_MENU, WritePage } from '@desk/ticket/config/pages/main-page';
import { WRITE_OPTION_ATOM } from '@desk/ticket/config/pages/write-page';
import { MENU_DESK, MENU_DESK_TICKET } from '@base/config/menus';
import { buildListSchema } from '@base/utils/helpers/schema';
import { FilterInput } from '@base/types/interfaces/common';
import { default as configFields } from '@desk/ticket/config/view-field';
import { ListPageConfig } from '@desk/ticket/config/pages/list-page';
import { KEY_TICKET_ID } from '@desk/ticket/config/key-names';
import displayLayoutConfig from '@desk/ticket/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';

interface IListPage {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
}

const ListPage: React.FC<IListPage> = (props) => {
  const { isSplitMode, setIsSplitMode } = props;
  //router
  const params = useParams();
  //state
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_DESK_TICKET)); //filter
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [{ isDesktop, isMobile, isTablet, device, layout }] = useRecoilState(deviceAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [writeOption, setWriteOption] = useRecoilState(WRITE_OPTION_ATOM); //for write page
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(MENU_DESK_TICKET)); //current list
  //params

  const category = DEFAULT_CATEGORY;
  const layoutMenu = `${MAIN_MENU}_${category}`; //for list
  const writeCategory = `${MAIN_MENU}_${writeOption.writeType}`; //for write
  const keyTitle = `crm_new_${writeCategory}`;
  const navigate = useNavigate();
  let { filter } = pageData;

  /*===================================== HOOK ===================================== */
  //get pagelayout
  let { data: listLayoutData, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    layoutMenu,
    'list',
  );

  //fields for build schema
  let viewingFields: any = [];
  if (pageData.activeMenu === category && pageData.settingColumns) {
    viewingFields = pageData.settingColumns.filter((_ele: any) => _ele.isViewing);
  }
  //// console.log('viewingFields', viewingFields);

  //get list data
  let listQuerySchema =
    listLayoutData?.data && viewingFields.length > 0
      ? buildListSchema({ fields: viewingFields, configFields })
      : '';
  //// console.log('listQuerySchema', listQuerySchema);

  let filtersQuery: FilterInput = {
    keyword: filter?.keyword ?? '',
    sort: filter?.sort,
    paging: filter?.paging,
    query: ParseFiltersToQuery(filter, ListPageConfig.dateByOptions, ListPageConfig.searchFields),
  };

  //// console.log('filter query', filtersQuery);
  const { isLoading, data, isFetching, isPreviousData, refetch } = ListPageConfig.useFectchList(
    filtersQuery,
    listQuerySchema,
  );

  //set viewing columns
  useEffect(() => {
    if (listLayoutData?.data) {
      const newPageData: any = { ...pageData };
      const newItems: any[] = [];
      listLayoutData?.data.map((_ele: any) => {
        newItems.push({ ..._ele, isViewing: _ele.isDefault });
      });
      newPageData.settingColumns = newItems;
      newPageData.activeMenu = category;
      setPageData(newPageData);
    }
  }, [listLayoutData, category]);

  //set data
  useEffect(() => {
    if (data?.data) {
      //set recoil view data
      //const customersList = makeCustomers(data?.data, { category: category });
      setListData(data?.data);
    } else {
      setListData([]);
    }
  }, [data?.data]);

  //checked or unchecked item
  const handleCheckItem = useCallback(
    (checkedValue: any[]) => {
      setSelectedIds(checkedValue);
    },
    [selectedIds],
  );

  /** ============================ RENDER ================================ */
  //toolbar
  const PageToolbarMemo = useMemo(() => {
    return (
      <PageToolbar
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        category={category}
        refetch={refetch}
      />
    );
  }, [isSplitMode, category]);

  //header
  const PageHeaderMemo = useMemo(() => {
    //const columnKeys = pageData.settingColumns.map((col: any) => col?.name || '') || [];
    return (
      <>
        {pageData.settingColumns.length > 0 && (
          <PageHeader
            isSplitMode={isSplitMode}
            columnKeys={[]} //NOT USE
            fields={viewingFields}
            category={category}
            onChecked={handleCheckItem}
            refetch={refetch}
          />
        )}
      </>
    );
  }, [isSplitMode, category, viewingFields, listData, selectedIds]);

  //body
  const PageBodyMemo = useMemo(() => {
    //// console.log('listData', listData);
    return (
      <>
        {pageData.settingColumns.length > 0 && (
          <PageBody
            isSplitMode={isSplitMode}
            category={category}
            //isFetching={false}
            fields={viewingFields || []}
            itemsList={listData}
            paging={data?.paging}
            //isPreviousData={isPreviousData}
            selectedIds={selectedIds}
            onChecked={handleCheckItem}
          />
        )}
      </>
    );
  }, [isSplitMode, category, listData, viewingFields, selectedIds]);

  //track get list and go to first order view
  useEffect(() => {
    //if first load and split = true, go to first item view, router
    if (params?.id === undefined && isSplitMode && listData.length > 0) {
      const [firstOrder] = listData;
      const url = `/${MENU_DESK}/${category}/${firstOrder[KEY_TICKET_ID]}`;
      navigate(url);
    }
  }, [category, listData]);

  //main
  return (
    <>
      <ListContainer
        isLoading={isLayoutLoading || isLoading}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={PageToolbarMemo}
          header={PageHeaderMemo}
          body={PageBodyMemo}
        />
      </ListContainer>

      <PageQuickAction
        category={category}
        itemsList={listData}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onReload={refetch}
      />

      {/* hook form - write new write */}
      {writeOption.isOpenWrite &&
        writeOption.writeType === DEFAULT_CATEGORY &&
        writeOption.writeMode === 'list' && (
          <WritePage
            title={<SpanLang keyLang={'New Ticket'} />}
            size="lg"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            onReload={refetch}
            menuApi={writeCategory}
          />
        )}
    </>
  );
};

export default ListPage;
