import { TreeDropdown } from '@base/components/form';
import { EKnowlegeBaseType } from '@desk/knowledge-base/types/enums';
import { KnowledgeBaseCategory } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import React, { useEffect, useState } from 'react';

interface ICategoryFolderTreeEditProps {
  value: KnowledgeBaseCategory;
  onChange: (params: any) => {};
  fetchList: (keyword: any, kbType: string) => {};
  kbType: string;
}
const CategoryFolderTreeEdit: React.FC<ICategoryFolderTreeEditProps> = (
  props: ICategoryFolderTreeEditProps,
) => {
  const { value, onChange, fetchList, kbType = EKnowlegeBaseType.ARTICLE } = props;
  const [selectedItem, setSelectedItem] = useState<any[]>([]);
  const [keyword, setKeyword] = useState('');
  const { data }: any = fetchList(keyword, kbType);
  // // console.log('CategoryFolderTreeEdit', value);
  const getTreeDatas = async ({ idFolder = 0, keyword, node }: any) => {
    // // console.log('getTreeDatas', idFolder, node);
    if (idFolder === 0) {
      return data?.results;
    } else {
      return node?.folders ?? [];
    }
  };
  const formatDataNode = (node: any) => {
    const hasChildren = node.folders && node.folders.length > 0;
    return {
      title: node.name, //language_key
      id: node.id,
      hasChildren,
      isExpand: false,
      isFolder: true,
      children: node?.folders ?? [],
    };
  };
  const handleSelectItem = (values: any[]) => {
    if (values.length) {
      onChange && onChange(values[0]);
      setSelectedItem(values);
    }
    // // console.log('handleSelectItem', values);
  };
  useEffect(() => {
    // console.log('category', value);
    if (value) {
      setSelectedItem([value]);
    } else {
      setSelectedItem([]);
    }
  }, [value]);
  //render
  return (
    <TreeDropdown
      single={true}
      //tree
      fetchTree={getTreeDatas}
      formatTreeNode={formatDataNode}
      value={selectedItem}
      onChange={handleSelectItem}
    />
  );
};
export default CategoryFolderTreeEdit;
