import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ArrowLeft, Database, Folder, Minus, Plus } from 'react-feather';
import _ from 'lodash';
import CategoryFolderTree from '../category-folder-tree';
import { useKnowledgeBasesAutoComplete } from '@desk/knowledge-base/services/knowledge-base-service';
import { NoData } from '@base/components';
import Loading from '@base/components/loading';
import KBQuickView from '@desk/ticket/containers/knowledge-base-modal/quick-view';

interface KBFilterSelectProps {
  onChange?: (items: any) => void;
}

const KnowledgeBaseFilterSelect = (props: KBFilterSelectProps) => {
  const { onChange } = props;
  //state
  const [showKnowledgeBase, setShowKnowledgeBase] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<any>(null);
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [articles, setArticles] = useState<any>([]);
  const [insertedArticles, setInsertedArticles] = useState<any>([]);
  const [showQuickView, setShowQuickView] = useState(false);
  const [viewArticle, setViewArticle] = useState<any>(null);

  //get kb records
  const params: any = {
    query: `subject:\"${searchText}\"`
  }
  if (selectedCategory) {
    params.query += ` category=\"${selectedCategory?.id || ''}\"`;
  }
  const { data: kbPosts, isFetching, refetch } = useKnowledgeBasesAutoComplete({ filter: params });
  //console.log('kbPosts', kbPosts);

  //init items
  useEffect(() => {
    if (kbPosts?.results) {
      setArticles(kbPosts.results);
    } else {
      setArticles([]);
    }
  }, [kbPosts]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //select item
  const handleInsert = (article: any) => {
    const newItems = [...insertedArticles];
    const fIdx = newItems.findIndex((_ele: any) => _ele.id === article.id);
    if (fIdx === -1) {
      newItems.push(article);
      setInsertedArticles(newItems);
      //callback
      onChange && onChange(newItems);
    }
  }

  //render articles
  const ArticlesRender = useMemo(() => {
    return (
      <>
        <div className="mg-t-5 tx-13 tx-color-03">Articles ({articles.length})</div>
        {articles.map((_item: any, index: number) => (
          <div key={index} className="d-flex flex-wrap align-items-center pd-y-5">
            {_item.subject}
            <button type="button" className="btn btn-sm btn-primary mg-l-auto" onClick={() => handleInsert(_item)}>
              Insert
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mg-l-5"
              onClick={() => {
                setViewArticle(_item);
                setShowQuickView(true);
              }}
            >
              View
            </button>
          </div>
        ))}
      </>
    );
  }, [articles, insertedArticles]);

  //main
  return (
    <div className={classNames('dropdown', { show: showDropdown })} ref={dropdownRef}>
      <div className="input-group">
        <input
          type="search"
          className="form-control bd-r-0"
          placeholder="Search Knowledge Base"
          value={inputText}
          onFocus={() => setShowDropdown(true)}
          onChange={(e: any) => handleInputChange(e.target.value, e)}
        />
        <span className="input-group-append">
          <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0" onClick={() => setShowDropdown(!showDropdown)}>
            <Database />
          </button>
        </span>
      </div>
      <div className={classNames('dropdown-menu wd-100p', { show: showDropdown })}>
        {!showQuickView &&
          <div className="row mg-0">
            <div className="col-6">
              <CategoryFolderTree
                treeId='kb-category-filter'
                onSelect={(category: any) => setSelectedCategory(category)}
              />
            </div>
            <div className="col-6 bd-l">
              {isFetching && <Loading />}
              {articles.length === 0 && <NoData label='No article(s) found.' />}
              {articles.length > 0 && ArticlesRender}
            </div>
          </div>
        }
        {showQuickView &&
          <KBQuickView
            className='pd-10'
            id={viewArticle?.id}
            onInsert={(item: any) => handleInsert(item)}
            onClose={() => setShowQuickView(false)} />
        }
      </div>
    </div>
  );
}

export default KnowledgeBaseFilterSelect;