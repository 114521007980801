import Icon from '@base/assets/icons/svg-icons';
import { EDisplay } from '../types/enums';

export const DISPLAY_OPTIONS = [
  { value: EDisplay.PUBLIC, label: 'Public' },
  { value: EDisplay.PRIVATE, label: 'Private' },
];

export const KB_ADD_OPTIONS = {
  knowledge: {
    name: 'Knowledge Base',
    icon: Icon('helpdesk'),
  },
  category: {
    name: 'Category',
    icon: Icon('category'),
  },
  folder: {
    name: 'Folder',
    icon: Icon('folder'),
  },
};
