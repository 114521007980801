import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { IClassificationValue } from '@settings/preferences/types/interfaces';

interface IViewProps {
  value: any;
}
const View: React.FC<IViewProps> = (props: IViewProps) => {
  const { value } = props;

  return (
    <>
      {value && value?.length > 0 ? (
        <ul className="classifications pd-0 mg-0">
          {value?.map((item: IClassificationValue, index: number) => {
            return (
              <li key={index}>
                <span>{item.classification?.name ? item.classification?.name + ': ' : ''}</span>
                <span>{item.value ? item.value : ''}</span>
              </li>
            );
          })}
        </ul>
      ) : (
        <TextView value={''} />
      )}
    </>
  );
};

export default View;
