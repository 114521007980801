import React from 'react';
import TextView from '@base/containers/view-field/text/view';

interface IViewProps {
  value: any;
}

const View: React.FC<IViewProps> = (props: IViewProps) => {
  const { value } = props;
  const { product, category } = value;
  return (
    <>
      {product ?
        `${product?.name || ''} / ${category?.name || ''}`
        :
        <TextView value='' />
      }
    </>
  );
};

export default View;
