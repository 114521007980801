/** VIEW PAGE CONFIG */
import { ITab } from '@base/types/interfaces/app';
import Timeline from '@base/containers/timeline';
import Notes from '@base/containers/note';
import { DESK_MENUS } from '@base/config/route-menus';
import { useKnowledgeBase } from '@desk/knowledge-base/services/knowledge-base-service';

export const TABS: ITab[] = [
  {
    order: 2,
    label: 'Timeline',
    path: 'timeline',
    tabComponent: Timeline,
  },
  {
    order: 3,
    label: 'Note',
    path: 'note',
    tabComponent: Notes,
  },
];
export const ViewPageConfig = {
  tabs: TABS,
  useGetView: useKnowledgeBase,
  menus: DESK_MENUS,
  ignoreSummaryFields: ['subject', 'content', 'viewed', 'inserted', 'helped', 'notHelped'],
};
