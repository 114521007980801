import { Select } from '@base/components/form';
import { useTicketClassificationsSetting } from '@settings/preferences/services/service';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

interface ClassificationProps {
  column?: number;
  value?: any;
  isPublic?: boolean;
  token?: string;
  onChange?: (val: any) => void;
}

const Classification: React.FC<any> = (props) => {
  const {
    column = 2,
    isPublic = false,
    token = '',
    value, //{region: {id, name, value, label}, language: {id, name, value, label}}
    onChange,
  } = props;
  //state
  const [regionOptions, setRegionOptions] = useState<any[]>([]);
  const [languageOptions, setLanguageOptions] = useState<any[]>([]);
  const [curRegion, setCurRegion] = useState<any>(null); //{value, label}
  const [curLanguage, setCurLanguage] = useState<any>(null); //{value, label}
  //init data
  useEffect(() => {
    if (value) {
      if (value?.region) {
        if (value.region?.id !== curRegion?.id) {
          let region = { ...value.region, label: value.region.name };
          //value.region.label = value.region.name;
          setCurRegion(region);
        }
      }
      if (value?.language) {
        if (value.language?.id !== curLanguage?.id) {
          let language = { ...value.language, label: value.language.name };
          setCurLanguage(language);
        }
      }
    } else {
      setCurRegion(null);
      setCurLanguage(null);
    }
  }, [value]);

  //HOOK get options
  const { data, isLoading } = useTicketClassificationsSetting(isPublic, token);
  //// console.log('classification data', data);

  //init options
  useEffect(() => {
    if (data?.results) {
      let newOptions = [];
      const regionResult = data.results.find((_ele: any) => _ele.name === 'Region');
      if (regionResult) {
        newOptions = regionResult.values.map((_ele: any) => ({
          id: regionResult.id,
          name: regionResult.name,
          value: _ele,
          label: _ele,
        }));
        setRegionOptions(newOptions);
      }
      const languageResult = data.results.find((_ele: any) => _ele.name === 'Language');
      if (languageResult) {
        newOptions = languageResult.values.map((_ele: any) => ({
          id: languageResult.id,
          name: languageResult.name,
          value: _ele,
          label: _ele,
        }));
        setLanguageOptions(newOptions);
      }
    }
  }, [data]);

  //value change
  const handleValueChange = (keyName: string, keyValue: any) => {
    if (keyName === 'region') {
      setCurRegion(keyValue);
      //callback
      onChange && onChange({ region: keyValue, language: curLanguage });
    }
    if (keyName === 'language') {
      setCurLanguage(keyValue);
      //callback
      onChange && onChange({ region: curRegion, language: keyValue });
    }
  };

  return (
    <div className="row">
      <div
        className={classNames('form-group', {
          'col-6 pd-r-5 mg-b-0': column === 2,
          'col-12 mg-b-5': column === 1,
        })}
      >
        {/* <label className="form-item-title d-flex">
          <span className="pd-l-5">Region</span>
        </label> */}
        <Select
          isSearchable={false}
          placeholder="Select region..."
          isLoading={isLoading}
          options={regionOptions}
          value={curRegion}
          onChange={(newRegion: any) => handleValueChange('region', newRegion)}
        />
      </div>
      <div
        className={classNames('form-group', {
          'col-6 pd-l-5 mg-b-0': column === 2,
          'col-12 mg-b-5': column === 1,
        })}
      >
        {/* <label className="form-item-title d-flex">
          <span className="pd-l-5">Language</span>
        </label> */}
        <Select
          isSearchable={false}
          placeholder="Select language..."
          isLoading={isLoading}
          options={languageOptions}
          value={curLanguage}
          onChange={(newLang: any) => handleValueChange('language', newLang)}
        />
      </div>
    </div>
  );
};

export default Classification;
