import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ListBody, ListGrid, ListPagination } from '@base/components/list';
import ListLoading, { GridNothing, TableNothing } from '@base/components/list/list-loading';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { ListType } from '@base/types/enums';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import { DESC, ASC } from '@base/config/constant';
import ListGridItem from '@desk/ticket/components/list/list-grid/list-grid-item';
import { ListPageConfig } from '@desk/ticket/config/pages/list-page';
import { MENU_DESK_TICKET } from '@base/config/menus';
import { listDataByMenuAtom } from '@base/recoil/atoms';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox } from '@base/components/form';
import ListReactTable8 from '@base/components/list/list-react-table-v8';

const PageBody: React.FC<any> = (props) => {
  const {
    isSplitMode,
    category, //router category
    isFetching,
    fields,
    itemsList,
    paging,
    //isPreviousData,
    selectedIds,
    onChecked,
  } = props;
  const { t } = useTranslation();
  //state
  const { device } = useRecoilValue(deviceAtom);
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_DESK_TICKET));
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(MENU_DESK_TICKET));
  let { filter, listType } = pageData;

  //config disable sortBy columns
  const disableSortByColumns: any = [];

  //render columns components

  //set filter sort param
  const setSort = (sort: any) => {
    let newFilter = {
      ...pageData,
      filter: {
        ...pageData.filter,
        sort: sort,
      },
    };
    setPageData(newFilter);
  };

  //grid props
  const listProps = {
    category: category, //router category
    listData: itemsList,
    isFetching,
    fetching: <ListLoading />,
    onCheckedRow: onChecked,
    //grid
    isSplitMode,
    selectedIds,
    device: device !== undefined ? device : 'desktop',
    nodata: <GridNothing />,
    columns: fields,
    columnRenderRemap: ListPageConfig.getColumnRenderRemap({ category }),
    hideColumns: [],
  };

  //table props
  const newFields = fields.map((_ele: any) => ({
    ..._ele,
    enableSorting: true,
    disableSortBy: disableSortByColumns.includes(_ele.keyName),
  }));

  /** ================================== React Table 8 ================================== */
  //build columns for table v8
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        width: '45px',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="pd-x-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      ...makeTable8Columns(
        newFields,
        ListPageConfig.getColumnRenderRemap({ category }),
        { category },
        [],
      ),
    ],
    [newFields, selectedIds],
  );

  const listTableProps = {
    nodata: <TableNothing />,
    data: itemsList,
    // loading: isFetching,
    columns: columns, //tableHideColumns
    initialState: {
      pageSize: paging?.itemPerPage || 15,
    },
    onRowSelect: onChecked,
    // rowSelected: selectedIds,
    onSortBy: (clName: any, isSorted: any) => {
      // refetch();
      //let orderBy = isSortedDesc ? DESC : ASC;
      //setSort({ field: clName, orderBy: orderBy });
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy: orderBy });
      }
    },
    className: '',
  };

  //table paging
  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || 15,
    nextPage: paging?.nextPage || null,
    previousPage: paging?.previousPage || null,
    //isPreviousData,
    onChange: (page: number, size: number) => {
      let newFilter = {
        ...filter,
        paging: {
          page,
          size,
        },
      };
      let newListFilter = {
        ...pageData,
        filter: newFilter,
      };
      setPageData(newListFilter);
    },
    /*// for test pagination.
    ...{
      totalPage: 2,
      totalItems: 20,
      currentPage: 1,
      itemPerPage: 10,
    },*/
  };

  const ListBodyMemo = useMemo(() => {
    return (
      <ListBody isScrollY={listType !== ListType.LIST}>
        {isSplitMode ? (
          <ListGrid
            {...listProps}
            listGridItemRender={(props: any) => <ListGridItem {...props} />}
          />
        ) : (
          <>
            {/* table list */}
            {listType == ListType.LIST && <ListReactTable8 {...listTableProps} />}
            {/* grid list */}
            {listType == ListType.GRID && (
              <ListGrid
                {...listProps}
                listGridItemRender={(props: any) => <ListGridItem {...props} />}
              />
            )}
          </>
        )}

        <ListPagination isSplitMode={isSplitMode} type={'full'} {...pagingProps} />
      </ListBody>
    );
  }, [itemsList, isSplitMode, listType]);

  //main
  return <>{ListBodyMemo}</>;
};

export default PageBody;
