import { isUndefined } from 'lodash';
import { useTickets, useTicketTags } from '@desk/ticket/services/ticket-service';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import { Link } from 'react-router-dom';
import { MAIN_MENU, NAME_KEY, PRIMARY_KEY } from '@desk/ticket/config/pages/main-page';
// import Priority from '@base/containers/priority';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import { SpanLang } from '@base/components';
import { Priority } from '@base/components/form';
import * as components from '@desk/ticket/config/write-field/components';
import * as commonComponents from '@base/config/write-field/components';
import { useGetModuleProcesses } from '@settings/process/hooks/useProcess';

/** LIST PAGE CONFIG */
export const ListPageConfig = {
  primaryKey: PRIMARY_KEY,
  nameKey: NAME_KEY,
  useFectchList: useTickets,
  getTitle: (row: any, isSplitMode: boolean): string => {
    // let preTitle: any = null;
    // let status = row?.status ? row.status : '';
    // if (isSplitMode) {
    //   preTitle = status ? (
    //     <>
    //       [<SpanLang keyLang={status.languageKey} />]
    //     </>
    //   ) : (
    //     ''
    //   );
    // }
    const name = row && isUndefined(row[NAME_KEY]) ? '' : row[NAME_KEY];
    // console.log('title', name);
    return name;
  },
  searchFields: [
    {
      name: 'subject',
      type: 'text',
      label: 'Subject',
      defaultValue: '',
    },
    {
      name: 'stage',
      type: 'text',
      label: 'Stage',
      defaultValue: '',
    },
  ],
  groupByOptions: [
    { label: 'All Tickets', value: 'all' },
    { label: 'My Tickets', value: 'my' },
    { label: 'My Group Ticket', value: 'myGroup' },
    { label: 'Unassigned Tickets', value: 'unassigned' },
    { label: 'My Due Today', value: 'myDueToday' },
    { label: 'My Overdue', value: 'myOverDue' },
    { label: 'My CC', value: 'myCC' },
    { label: 'Due Today in My Group', value: 'myGroupDueToday' },
    { label: 'Overdue in My Group', value: 'myGroupOverdue' },
    { label: 'Violated Response Due', value: 'violatedRespondDue' },
    { label: 'Violated Resolution Due', value: 'viololatedResolutionDue' },
    // { label: 'Deleted Tickets', value: 'deleted' },
  ],
  dateByOptions: [
    { label: '1st Response Due', value: 'firstRespondDue' },
    { label: 'Resolution Due', value: 'resolutionDue' },
    { label: 'Created on', value: 'createdAt' },
    { label: 'Closed on', value: 'closedAt' },
    { label: 'Updated on', value: 'updatedAt' },
  ],
  filterByOptions: [
    {
      label: 'Customer',
      value: 'customer',
      component: components.CustomerAutoComplete,
      componentProps: {
        //single: true,
        showAvatar: true,
        addLabel: 'Add new customer',
      },
      getValue: (value: any) => {
        //return param for query
        return value.length > 0 ? value.map((v: any) => v.id).join(',') : '';
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
    {
      label: 'Priority',
      value: 'prioirty',
      component: components.PrioritySelect,
      componentProps: {},
      getValue: (value: any) => {
        //return param for query
        return value?.priority || '';
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
    {
      label: 'Product / Category',
      value: 'category',
      component: components.ProductCategorySelect,
      componentProps: {},
      getValue: (value: any) => {
        //return param for query
        return value?.category ? value.category.id : '';
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
    // { label: 'Assigned Group', value: 'assignedGroup' },
    {
      label: 'Assigned Group / Rep',
      value: 'assignedUser',
      component: components.AssignGroupRep,
      componentProps: {},
      getValue: (value: any) => {
        //return param for query
        return value?.assignedUser ? value.assignedUser.id : ''; //TODO: assignedGroup ?
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
    {
      label: 'Owner',
      value: 'createdBy',
      component: components.UserAutoComplete,
      componentProps: {},
      getValue: (value: any) => {
        //return param for query
        return value.length > 0 ? value.map((v: any) => v.id).join(',') : '';
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
    {
      label: 'Process',
      value: 'process',
      component: commonComponents.Lookup,
      componentProps: {
        // fetchList: useTicketProcesses,
        fetchList: useGetModuleProcesses,
        fieldValue: 'id',
        fieldLabel: 'name',
        extraParams: { module: 'MODULE_DESK' },
        isSearch: false,
      },
      getValue: (value: any) => {
        //return param for query
        return value?.id || '';
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
    {
      label: 'Classification',
      value: 'classifications',
      component: components.Classification,
      componentProps: {
        column: 1,
      },
      getValue: (value: any) => {
        //return param for query
        let newParam: string = '';
        if (value?.region) {
          newParam += value.region.id + '|' + value.region.value;
        }
        if (value?.language) {
          newParam += '|__|' + value.language.id + '|' + value.language.value;
        }
        return newParam;
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
    {
      label: 'Cc',
      value: 'ccUsers',
      component: components.UserAutoComplete,
      componentProps: {},
      getValue: (value: any) => {
        //return param for query
        return value.length > 0 ? value.map((v: any) => v.id).join(',') : '';
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
    // { label: 'Customer Satisfaction', value: 'cusSatisfaction' },
    // { label: 'Channel', value: 'channel' },
    {
      label: 'Tag',
      value: 'tags',
      component: commonComponents.Lookup,
      componentProps: {
        fetchList: useTicketTags,
        fieldValue: 'id',
        fieldLabel: 'name',
        isMultiple: true,
      },
      getValue: (value: any) => {
        //return param for query
        return value.length > 0 ? value.map((v: any) => v.id).join(',') : '';
      },
      setValue: (value: any) => {
        //initial value for component
      },
    },
  ],
  getColumnRenderRemap: (extraParams: any) => {
    const { category } = extraParams;
    return {
      subject(col: string, row: ITicket) {
        return <Link to={`/${MAIN_MENU}/${category}/${row.id}`}>{row.subject}</Link>;
      },
      status(col: string, row: ITicket) {
        return <SpanLang keyLang={row.status?.languageKey} />;
      },
      priority(col: string, row: ITicket) {
        return <Priority value={row.priority.keyName} />;
      },
      customer(col: string, row: ITicket) {
        return row.customer ? row.customer.name : '';
      },
      // products(col: string, row: ITicket) {
      //   return row.product ? row.product.name : '';
      // },
      assignedGroup(col: string, row: ITicket) {
        return row.assignedGroup ? row.assignedGroup.name : '';
      },
      assignedUser(col: string, row: ITicket) {
        let fUser = row.assignedUser ? row.assignedUser : null;
        return fUser ? (
          <div className="media align-items-center">
            <div className="avatar avatar-xs">
              <img
                src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                className="rounded-circle"
                alt="MSR"
              />
            </div>
            <div className="media-body mg-l-10">{fUser?.user?.name}</div>
          </div>
        ) : (
          ''
        );
      },
      ccUsers(col: string, row: ITicket) {
        let fUser = row.ccUsers && row.ccUsers.length ? row.ccUsers[0] : null;
        return fUser ? (
          <div className="media align-items-center">
            <div className="avatar avatar-xs">
              <img
                src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                className="rounded-circle"
                alt="MSR"
              />
            </div>
            <div className="media-body mg-l-10">{fUser?.user?.name}</div>
          </div>
        ) : (
          ''
        );
      },
      duration(col: string, row: ITicket) {
        return row.duration + ' ' + row.durationUnit;
      },
      category(col: string, row: ITicket) {
        return (
          (row.product ? row.product.name + '/' : '') + (row.category ? row.category.name : '')
        );
      },
      process(col: string, row: ITicket) {
        return row.process ? row.process.name : '';
      },
      resolutionDue(col: string, row: ITicket) {
        return convertDateTimeServerToClient(row.resolutionDue);
      },
      firstRespondDue(col: string, row: ITicket) {
        return convertDateTimeServerToClient(row.firstRespondDue);
      },
    };
  },
};
