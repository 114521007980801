import itemEdit from '@settings/process/components/diagram/status/StatusItemWrite';
import React from 'react';
import TextView from '@base/containers/view-field/text/view';

interface IViewProps {
  value: any;
}
const View: React.FC<IViewProps> = (props: IViewProps) => {
  const { value } = props;
  const users: string[] =
    value?.length > 0
      ? value.map((item: any) => {
          return item.user?.name ? item.user.name : '';
        })
      : [];
  //// console.log("users", users);
  return <TextView value={users.join(',')} />;
};

export default View;
