import React, { useState, useEffect, useCallback, lazy } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import Button from '@base/components/form/button';
import { ChevronUp } from 'react-feather';
import Field from '@base/components/hook-form/field';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse, ResponseData } from '@base/types/interfaces/response';
import * as keyNames from '@desk/ticket/config/key-names';
import { CREATE_TICKET } from '@desk/ticket/services/graphql/ticket';
import WriteCustomerFormModal from '@customer/customer/containers/write-customer-form';
import WriteFormModal from '@customer/customer/containers/write-form-modal';
import { finalizeParams } from './payload';
import { SpanLang } from '@base/components';
import { MENU_CUSTOMER, MENU_DESK_TICKET } from '@base/config/menus';
import InputCodeGenerator from '@base/containers/code-generator';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { pageDataByMenuAtom } from '@base/recoil/atoms';
import { stringify } from 'query-string';
import { keyStringify } from '@base/utils/helpers';
import { FilterInput } from '@base/types/interfaces/common';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import { ListPageConfig } from '@desk/ticket/config/pages/list-page';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import { PRIORITIES } from '@base/config/constant';
interface WriteFormProps {
  fullScreen: boolean;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  //with write form
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props) => {
  const {
    fullScreen,
    menuApi,
    onClose,
    onReload,
    //withWriteForm
    defaultValues,
    fields,
    tabs,
    getParams,
  } = props;
  //const { t } = useTranslation();
  const queryClient = useQueryClient();
  const pageDataValue = useRecoilValue(pageDataByMenuAtom(MENU_DESK_TICKET));
  //state
  const [isReset, setIsReset] = useState(false);
  const [isShowingWriteCust, setIsShowingWriteCust] = useState(false);
  const [isShowingWriteContact, setIsShowingWriteContact] = useState(false);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { ...defaultValues, [keyNames.KEY_TICKET_CODE]: '' },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });
  let filtersQuery: FilterInput = {
    keyword: pageDataValue?.filter?.keyword ?? '',
    sort: pageDataValue?.filter?.sort,
    paging: pageDataValue?.filter?.paging,
    query: ParseFiltersToQuery(
      pageDataValue?.filter ?? {},
      ListPageConfig.dateByOptions,
      ListPageConfig.searchFields,
    ),
  };
  const filterQueryKey = keyStringify(filtersQuery, '');
  const queryKeys = ['desk_tickets', filterQueryKey];
  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_TICKET,
    'desk_createTicket',
    {
      // When mutate is called:
      // onMutate: async (params: any) => {
      //   const { ticket } = params;
      //   // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      //   await queryClient.cancelQueries(queryKeys);

      //   // Snapshot the previous value
      //   const previousDatas = queryClient.getQueryData<ResponseData<ITicket>>(queryKeys);

      //   // Optimistically update to the new value
      //   if (previousDatas) {
      //     const found = PRIORITIES.find((prio) => {
      //       return prio.keyName === ticket.priority;
      //     });
      //     const nTicket = {
      //       ...ticket,
      //       priority: found,
      //     };
      //     const newData = [nTicket, ...previousDatas.data];
      //     queryClient.setQueryData<ResponseData<ITicket>>(queryKeys, {
      //       ...previousDatas,
      //       data: [...newData],
      //     });
      //   }

      //   return { previousDatas };
      // },
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Create Ticket successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Create Ticket failed: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      if (isReset) {
        reset();
      }
      // refecth data
      onReload && onReload();
      onClose && onClose();
    }
  }, [mutationAdd.isSuccess]);

  //field value watching
  const productValue = watch(keyNames.KEY_TICKET_PRODUCT);
  const categoryValue = watch(keyNames.KEY_TICKET_CATEGORY);
  const customerValue = watch(keyNames.KEY_TICKET_CUSTOMER);
  const assignGroupValue = watch(keyNames.KEY_TICKET_ASSIGN_GROUP);
  useEffect(() => {
    if (customerValue) {
      setValue(keyNames.KEY_TICKET_CONTACT, null);
    }
  }, [customerValue]);
  useEffect(() => {
    if (productValue?.length == 0) {
      setValue(keyNames.KEY_TICKET_CATEGORY, null);
    }
  }, [productValue]);
  useEffect(() => {
    if (categoryValue) {
      if (categoryValue?.rules?.length > 0) {
        const curRule = categoryValue?.rules?.length > 0 ? categoryValue.rules[0] : null;
        setValue(keyNames.KEY_TICKET_PRIORITY, {
          priority: curRule.priority.keyName,
          languageKey: curRule.priority.languageKey,
        });
      }
    } else {
      setValue(keyNames.KEY_TICKET_PRIORITY, null);
    }
  }, [categoryValue]);
  useEffect(() => {
    setValue(keyNames.KEY_TICKET_ASSIGN_USER, null);
  }, [assignGroupValue]);

  //submit form
  const onSubmit = (formData: any) => {
    // console.log('onSubmit');
    const configParams = getParams(formData); //generate api params by fields
    // console.log('configParams', configParams);
    const newParams: any = finalizeParams(configParams);
    mutationAdd.mutate(newParams);
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  //create new customer
  const handleWriteCustomerSuccess = (newCustomer: any) => {
    setValue(keyNames.KEY_TICKET_CUSTOMER, newCustomer);
  };

  //create new customer
  const handleWriteContactSuccess = (newContact: any) => {
    setValue(keyNames.KEY_TICKET_CONTACT, newContact);
  };

  //form fields
  const renderFields = () => {
    return (
      <div
        className="pd-15 form-row scroll-box"
        style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        {fields.map((_item, _index) => {
          let newComponentProps = { ..._item?.componentProps };
          //visible contact by account
          if (
            _item.keyName === keyNames.KEY_TICKET_CONTACT &&
            customerValue?.category !== 'CATEGORY_ACCOUNT'
          ) {
            return;
          }
          //filter contact by account
          if (
            _item.keyName === keyNames.KEY_TICKET_CONTACT &&
            customerValue?.category === 'CATEGORY_ACCOUNT'
          ) {
            newComponentProps.accountId = customerValue.id;
          }
          //filter ticket category by product
          if (_item.keyName === keyNames.KEY_TICKET_CATEGORY) {
            if (!productValue) {
              newComponentProps.isDisabled = true;
            } else {
              newComponentProps.productIds = productValue ? [productValue.value] : [];
            }
          }
          //filter priority by category
          // if (_item.keyName === keyNames.KEY_TICKET_PRIORITY) {
          //   if (categoryValue) {
          //     newComponentProps.defaultOptions = categoryValue.rules || [];
          //   }
          //   // else {
          //   //   newComponentProps.isDisabled = true;
          //   // }
          // }
          //add event NEW for customer
          if (_item.keyName === keyNames.KEY_TICKET_CUSTOMER) {
            newComponentProps.onAdd = () => setIsShowingWriteCust(true);
          }
          //add event NEW for contact
          if (_item.keyName === keyNames.KEY_TICKET_CONTACT) {
            newComponentProps.onAdd = () => setIsShowingWriteContact(true);
          }
          //filter options for assign reps
          if (_item.keyName === keyNames.KEY_TICKET_ASSIGN_USER) {
            if (assignGroupValue) {
              newComponentProps.groupId = assignGroupValue.id;
              if (assignGroupValue?.reps?.length > 0) {
                newComponentProps.defaultOptions = assignGroupValue?.reps?.map((_ele: any) => ({
                  ..._ele.user,
                  group: { id: assignGroupValue.id, name: assignGroupValue.name },
                }));
              }
            }
          }
          return (
            <Field
              key={_index}
              item={{
                ..._item,
                componentProps: newComponentProps,
              }}
              control={control}
              //isHidden={_item.keyName == 'code'}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                setIsReset(false);
                handleSubmit((data) => onSubmit(data), onError)();
              }}
              disabled={mutationAdd.isLoading || !isValid}
              loading={mutationAdd.isLoading}
              name="Save"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  setIsReset(true);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                name="Save and Create New"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form values', getValues()); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  let formHeight = 'calc(100vh - 250px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 170px)';
  }
  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        {/* hidden code generator */}
        <div className="d-none">
          <Controller
            name={keyNames.KEY_TICKET_CODE}
            control={control}
            render={({ field: { value, onChange } }: any) => {
              return <InputCodeGenerator menu="ticket" value={value} onChange={onChange} />;
            }}
          />
        </div>
        {renderFields()}
        {renderFooter()}
      </form>
      {/* write new customer */}
      {isShowingWriteCust && (
        <WriteCustomerFormModal
          size="lg"
          showCanvas={true}
          className={'wd-600'}
          isOpen={isShowingWriteCust}
          onClose={() => setIsShowingWriteCust(false)}
          onSuccess={handleWriteCustomerSuccess}
        />
      )}
      {isShowingWriteContact && (
        <WriteFormModal
          title={<SpanLang keyLang={'Create Contact'} />}
          size="lg"
          showCanvas={true}
          className={'wd-600'}
          isOpen={isShowingWriteContact}
          account={{
            id: customerValue.id, //account id
            name: customerValue.name, //account name
            value: customerValue.id,
            label: customerValue.name,
          }}
          menuApi={`${MENU_CUSTOMER}_contact`}
          onClose={() => setIsShowingWriteContact(false)}
          onSuccess={handleWriteContactSuccess}
        />
      )}
    </div>
  );
};

export default withMiModal(withWriteForm(WriteForm));
