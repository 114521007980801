import { convertDateTimeServerToClient } from '@base/utils/helpers';
import { CheckOnTime } from '@desk/ticket/utils/helper';
import React from 'react';

interface IViewProps {
  value: any;
}
const View: React.FC<IViewProps> = (props: IViewProps) => {
  const { value } = props;
  const statusClss = CheckOnTime(value) ? 'status-on-time' : 'status-overdue';
  const strDate = convertDateTimeServerToClient({
    date: value,
    formatInput: '',
    formatOutput: '',
    isTime: true,
    humanize: false,
  });
  return (
    <>
      <div className="d-flex">
        <span className={`mg-r-20 ${statusClss}`}></span>
        <span> {strDate}</span>
      </div>
    </>
  );
};

export default View;
