import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, SpanLang } from '@base/components/form';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { getFieldLayoutDataByKeyName } from '@base/utils/helpers/page-layout-utils';
import { MAIN_MENU } from '@desk/ticket/config/pages/main-page';
import { useRecoilValue } from 'recoil';
import { KEY_TICKET_CONTENT } from '@desk/ticket/config/key-names';
import { ViewFields } from '@base/components/hook-form/view';
import { useTicketComments } from '@desk/ticket/services/ticket-service';
import {
  TICKET_COMMENT_KIND_FORWARD,
  TICKET_COMMENT_KIND_REPLY,
} from '@desk/ticket/config/contants';
//import Loading from '@base/components/loading';

import TicketReply from '../ticket-reply';
import WriteReplyForm from '../write-reply-form';
import WriteCommentForm from '../write-comment-form';

interface TicketContentProps {
  sourceData?: any;
  menuSource: string;
  menuSourceId: string;
  menuSourceName?: string;
}

const TicketContent = (props: TicketContentProps) => {
  const { sourceData, menuSource, menuSourceId, menuSourceName } = props;

  //state
  const [isOpenReply, setIsOpenReply] = useState(false);
  const [isOpenComment, setIsOpenComment] = useState(false);
  const [isOpenForward, setIsOpenForward] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [curPaging, setCurPaging] = useState({ page: 1, size: 10, totalPage: 1 });
  const [activeParent, setActiveParent] = useState<any>(null);
  //recoil
  const viewData = useRecoilValue(viewDataByMenuAtom(menuSource));
  const contentField = getFieldLayoutDataByKeyName(viewData, KEY_TICKET_CONTENT);

  //get list
  const {
    data: postsData,
    isFetching,
    refetch,
  } = useTicketComments({ ticketId: menuSourceId, paging: curPaging });
  //// console.log('postsData', postsData);

  //update paging
  useEffect(() => {
    if (postsData?.data) {
      if (postsData?.paging?.currentPage !== curPaging.page) {
        setItems((curItems: any) => [...curItems, ...postsData.data]);
      } else {
        setItems(postsData.data);
      }
    }
    if (postsData?.paging) {
      if (
        postsData.paging.currentPage !== curPaging.page ||
        postsData.paging.totalPage !== curPaging.totalPage
      ) {
        const newPaging = {
          ...curPaging,
          page: postsData.paging.currentPage,
          totalPage: postsData.paging.totalPage,
        };
        setCurPaging(newPaging);
      }
    }
  }, [postsData]);

  //open reply
  const handleReply = (comment: any) => {
    setIsOpenReply(true);
    setActiveParent(comment);
  };

  //open forward
  const handleForward = (comment: any) => {
    setIsOpenForward(true);
    setActiveParent(comment);
  };

  //more data
  const handleLoadMore = () => {
    if (curPaging.page < curPaging.totalPage) {
      setCurPaging((curValue: any) => ({ ...curValue, page: curValue.page + 1 }));
    }
  };

  //memo list
  const TicketReplyMemo = useMemo(() => {
    return (
      <>
        <TicketReply data={items} onReply={handleReply} onForward={handleForward} />
        {curPaging.page < curPaging.totalPage && (
          <Button
            outline
            color="light"
            className="w-100 bd-0"
            onClick={handleLoadMore}
            loading={isFetching}
          >
            Load More...
          </Button>
        )}
      </>
    );
  }, [items]);

  return (
    <div className="scroll-box pd-20" style={{ height: 'calc(100vh - 280px)' }}>
      <div className="">
        <div className="d-flex justify-content-end pd-b-20">
          <Button
            color="primary"
            icon="CornerUpLeft"
            outline={true}
            name="Reply"
            onClick={() => handleReply(sourceData)}
          />
          <Button
            color="primary"
            icon="MessageSquare"
            outline={true}
            className="mg-l-5 mg-r-5"
            name="Comment"
            onClick={() => setIsOpenComment(true)}
          />
          <Button
            color="primary"
            icon="CornerUpRight"
            outline={true}
            name="Forward"
            onClick={() => handleForward(sourceData)}
          />
        </div>
        <div className="card mg-b-10">
          <div className="card-body pd-b-0 pd-l-10 pd-r-0">
            {contentField && (
              <ViewFields
                fields={[
                  { ...contentField, config: { ...contentField.config, notShowTitle: true } },
                ]}
                menu={MAIN_MENU}
                menuSource={menuSource}
                menuSourceId={menuSourceId}
              />
            )}
          </div>
        </div>
      </div>
      {/* {isFetching && <Loading />} */}
      {TicketReplyMemo}
      {isOpenComment && (
        <WriteCommentForm
          className="mn-wd-900"
          ticketId={menuSourceId}
          ticketName={menuSourceName}
          title={<SpanLang keyLang={'New Comment'} />}
          showCanvas={true}
          isOpen={isOpenComment}
          onClose={() => setIsOpenComment(false)}
          onReload={refetch}
        />
      )}
      {isOpenReply && (
        <WriteReplyForm
          className="mn-wd-900"
          type={TICKET_COMMENT_KIND_REPLY}
          ticketId={menuSourceId}
          ticketName={menuSourceName}
          parentComment={activeParent}
          title={<SpanLang keyLang={'New Reply'} />}
          showCanvas={true}
          isOpen={isOpenReply}
          onClose={() => setIsOpenReply(false)}
          onReload={refetch}
        />
      )}
      {isOpenForward && (
        <WriteReplyForm
          className="mn-wd-900"
          type={TICKET_COMMENT_KIND_FORWARD}
          ticketId={menuSourceId}
          ticketName={menuSourceName}
          parentComment={activeParent}
          title={<SpanLang keyLang={'New Forward'} />}
          showCanvas={true}
          isOpen={isOpenForward}
          onClose={() => setIsOpenForward(false)}
          onReload={refetch}
        />
      )}
    </div>
  );
};

export default TicketContent;
