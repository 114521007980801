/**
 *
 * @param inputDate string iso date
 */
export function CheckOnTime(inputDate: string): boolean {
  const inDate = new Date(inputDate);
  const today = new Date();
  if (inDate.toString() !== 'Invalid Date' && inDate.getTime() >= today.getTime()) {
    return true;
  }
  return false;
}
