import usePost from '@base/hooks/usePost';
import { stringify } from 'query-string';
import { keyStringify } from '@base/utils/helpers';
import { FilterInput } from '@base/types/interfaces/common';
import {
  knowledgeBaseCategoriesFakeData,
  knowledgeBaseFakeData,
  knowledgeBasesFakeData,
  knowledgeBaseTagsFakeData,
} from '../utils/fake-data';
import usePosts from '@base/hooks/usePosts';
import { BaseResponse } from '@base/types/interfaces/response';
import {
  KnowledgeBase,
  KnowledgeBaseCategory,
  KBInserted,
} from '@desk/knowledge-base/types/interfaces/knowledge-base';
import { User } from '@base/types/interfaces/user';
import {
  getListQuery,
  getViewQuery,
  KNOWLEDGE_CATEGORIES_GET,
  GET_ALL_TAGS,
  KNOWLEDGE_AUTO_COMPLETE,
  KNOWLEDGE_FOLDERS_GET,
  GET_ALL_KNOWELEDGEBASE_INSERTED,
  KNOWLEDGE_DETAIL_MODAL,
} from './graphql/knowledge-base';
import { ITicketCategory } from '@desk/ticket/types/interfaces/ticket';
import { ECategoryType } from '@desk/main/types/enums';
import { isUndefined } from 'lodash';
import { EKnowlegeBaseType } from '../types/enums';

export const useKnowledgeBases = (filter: FilterInput, querySchema: string) => {
  let queryKey = ['desk_knowledgebases', keyStringify(filter, '')];
  let params = {
    filter: filter,
  };
  const response = usePosts<KnowledgeBase[]>(queryKey, getListQuery(querySchema), params, {
    // initialData: knowledgeBasesFakeData(),
    enabled: querySchema != '',
  });
  return response;
};

export const useKnowledgeBase = (id: string, querySchema: string) => {
  let queryKey = ['desk_knowledgebase', id];
  let params = {
    id,
  };
  const response = usePost<KnowledgeBase>(queryKey, getViewQuery(querySchema), params, {
    // initialData: knowledgeBaseFakeData(),
    enabled: querySchema != '',
  });
  return response;
};

export const useKnowledgeBaseCategories = ({ keyword, categoryId, folderParentId }: any) => {
  let query = '';
  if (keyword) {
    query += `name:\"${keyword}\"`;
  } else {
    if (categoryId) {
      query += ` category=\"${categoryId}\"`;
    }
    if (folderParentId) {
      query += ` parent=\"${folderParentId}\"`;
    }
  }

  let queryKey = ['desk_kbCategories', keyword, categoryId, folderParentId];

  const response = usePosts<KnowledgeBaseCategory[]>(queryKey, KNOWLEDGE_CATEGORIES_GET, {
    filter: { query },
  });
  return response;
};

//REMOVED
export const useKBCateogryFolders = (categoryId: string, folderParentId: string) => {
  let query = `category=${categoryId}`;
  if (folderParentId) {
    query += ` parent=${folderParentId}`;
  }
  let queryKey = ['desk_kbFolders', categoryId, folderParentId];
  const response = usePosts<any[]>(
    queryKey,
    KNOWLEDGE_FOLDERS_GET,
    { filter: { query } },
    { enabled: categoryId !== '' },
  );
  return response;
};

export const useKnowledgeBaseTags = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
    filters: {},
  };
  let queryKey = ['desk_tags', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<ITicketCategory>>(queryKey, GET_ALL_TAGS, params, {
    // initialData: knowledgeBaseTagsFakeData(),
  });
  return response;
};

export const useKnowledgeBasesAutoComplete = (params: any) => {
  const query = params?.filter?.query ?? '';
  let queryKey = ['desk_knowledgebases', query];
  const response = usePost<BaseResponse<KnowledgeBase>>(
    queryKey,
    KNOWLEDGE_AUTO_COMPLETE,
    params,
    {},
  );
  return response;
};

export const useKBInserteds = (params: any) => {
  const query = params?.filter?.query ?? '';
  let queryKey = ['desk_kbInserteds', query];
  const response = usePost<BaseResponse<KBInserted>>(
    queryKey,
    GET_ALL_KNOWELEDGEBASE_INSERTED,
    params,
    {},
  );
  return response;
};

export const usePublishKnowledgeBase = (id: string) => {
  let queryKey = ['desk_knowledgebase', id];
  let params = {
    id,
  };
  const response = usePost<KnowledgeBase>(queryKey, KNOWLEDGE_DETAIL_MODAL, params, {
    // initialData: knowledgeBaseFakeData(),
    enabled: id != '',
  });
  return response;
};
