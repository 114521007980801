import React, { VFC } from 'react';

interface IDesktopLayout1List {
  toolbar: React.ReactNode;
  header: React.ReactNode;
  body: React.ReactNode | React.VFC;
}

const DesktopLayout1List: VFC<IDesktopLayout1List> = ({
  toolbar,
  header,
  body,
}: IDesktopLayout1List) => {
  return (
    <>
      {toolbar}
      {header}
      {body}
    </>
  );
};

export default DesktopLayout1List;
