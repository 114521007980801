import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ChevronDown, Edit2, Folder, Lock, PlusCircle, Trash2 } from 'react-feather';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Loading from '@base/components/loading';
import { FormIcon } from '@base/components/form';
import { confirmAlert, NoData } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { useKnowledgeBaseCategories } from '@desk/knowledge-base/services/knowledge-base-service';
import { EDisplay } from '@desk/knowledge-base/types/enums';
import { KnowledgeBaseCategory } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import { deskWriteOptionAtom } from '@desk/main/recoil/atom/write-atom';
import {
  KNOWLEDGE_CATEGORIES_SORT,
  KNOWLEDGE_CATEGORY_DELETE,
  KNOWLEDGE_CATEGORY_UPDATE,
} from '@desk/knowledge-base/services/graphql/knowledge-base';
import WriteCategoryForm from '../write-category-form';
import WriteFolderForm from '../write-folder-form';
import { PAGE_MENU } from '@desk/knowledge-base/config/pages/main-page';
import { pageDataByMenuAtom } from '@base/recoil/atoms/app';

// const TEST_DATA = [
//   {
//     id: 'c1',
//     name: 'Category 1',
//     children: [
//       { id: "f1", name: "KB Folder 1", display: EDisplay.PUBLIC },
//       { id: "f2", name: "KB Folder 2", children: [{ id: "fc1", name: "KB Child Folder 1" }, { id: "fc2", name: "KB Child Folder 2" }] },
//       { id: "f3", name: "KB Folder 3", display: EDisplay.PRIVATE },
//     ]
//   },
//   {
//     id: 'c2',
//     name: 'Category 2',
//     children: [
//       { id: "f4", name: "KB Folder 4" },
//       { id: "f5", name: "KB Folder 5", display: EDisplay.PRIVATE },
//       { id: "f6", name: "KB Folder 6" },
//     ]
//   },
// ];
interface ICategoryFolderTreeProps {
  treeId: string;
  editable?: boolean;
  onSelect?: (category: KnowledgeBaseCategory | null) => void;
}

const CategoryFolderTree: React.FC<ICategoryFolderTreeProps> = (props) => {
  const { treeId, editable = true, onSelect } = props;
  const { t } = useTranslation();
  //state

  const [openCategory, setOpenCategory] = useState<any>(null);
  const [openFolder, setOpenFolder] = useState<any>(null);
  const [openDepth, setOpenDepth] = useState<number>(-1);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [deletedItem, setDeletedItem] = useState<any>(null);
  const [deletedDepth, setDeletedDepth] = useState<number>(-1);
  const [deletedParent, setDeletedParent] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null); //highlight
  //recoil
  const pageData = useRecoilValue(pageDataByMenuAtom(PAGE_MENU));
  const [writeOption, setWriteOption] = useRecoilState(deskWriteOptionAtom); //for write page

  //mutation
  const mUpdate: any = useMutationPost(KNOWLEDGE_CATEGORY_UPDATE, 'desk_updateKBCategory');

  //hook to get categories-folders
  const {
    data: postData,
    //isLoading: isLoading,
    isFetching,
    refetch: refetch,
  } = useKnowledgeBaseCategories({
    categoryId: openCategory?.id || '',
    folderParentId: openFolder?.id || '',
  });

  //set tree: category/folder
  useEffect(() => {
    if (postData?.data) {
      if (openDepth === -1) {
        //init root: all categories
        setTreeData(_.cloneDeep(postData.data));
      } else {
        //add to folder
        const newItems = _.cloneDeep(treeData); // [...treeData];
        const openId = openDepth === 0 ? openCategory.id : openFolder.id;
        let openNode = findNode(newItems, openId, {});
        if (openNode) {
          openNode.children = postData?.data;
          setTreeData(newItems);
        }
      }
    }
  }, [postData]);

  //mutation
  const mDeleteCategory: any = useMutationPost(KNOWLEDGE_CATEGORY_DELETE, 'desk_deleteKBCategory');
  const mSortCategory: any = useMutationPost(KNOWLEDGE_CATEGORIES_SORT, 'desk_sortKBCategory');

  //check create success
  useEffect(() => {
    if (mDeleteCategory.isSuccess) {
      const newItems = [...treeData];
      if (deletedDepth === 0) {
        //delete root
        const fIndex = newItems.findIndex((_ele: any) => _ele.id === deletedItem.id);
        newItems.splice(fIndex, 1);
      } else {
        //delete node
        const parentNode = findNode(newItems, deletedParent.id, {});
        const fIndex = parentNode.children.findIndex((_ele: any) => _ele.id === deletedItem.id);
        parentNode.children.splice(fIndex, 1);
      }
      setTreeData(newItems);
      //reset
      setDeletedDepth(-1);
      setDeletedItem(null);
      setDeletedParent(null);
    }
  }, [mDeleteCategory.isSuccess]);

  /** ===================================== HANDLER ==================================== */
  //find node in tree
  const findNode = (treeNodes: any[], nodeId: string, result: any): any => {
    for (let i = 0; i < treeNodes.length; i++) {
      if (treeNodes[i].id === nodeId) {
        //// console.log('nodes[i]', nodes[i]);
        result = treeNodes[i];
      }
      if (treeNodes[i]?.children?.length > 0) {
        result = findNode(treeNodes[i].children, nodeId, result);
      }
    }
    return result;
  };

  //drag end
  const handleDragEnd = (result: any) => {
    const { source, destination, type } = result;
    //// console.log('category result', result);
    if (!destination) {
      return;
    }
    if (type !== 'FOLDER0') {
      //re-order folder
      const newItems = [...treeData];
      const sourceNode = findNode(newItems, source.droppableId, {});
      const destinationNode = findNode(newItems, destination.droppableId, {});
      //// console.log('sourceNode', sourceNode);
      if (sourceNode) {
        const [removedItem] = sourceNode.children.splice(source.index, 1);
        if (destinationNode.children) {
          destinationNode.children.splice(destination.index, 0, removedItem);
        } else {
          destinationNode.children = [removedItem];
        }
      }
      setTreeData(newItems);

      //update to DB
      //1. if the same parent: sourceNode.id === destinationNode.id, sort
      if (sourceNode.id === destinationNode.id) {
        const params = destinationNode.children.map((_ele: any) => _ele.id);
        mSortCategory.mutate({ ids: params });
      }
      //2. if different parent sourceNode.id !== destinationNode.id, update
      if (sourceNode.id !== destinationNode.id) {
        //change parent
        const destinationItem = destinationNode.children[destination.index];
        const params: any = {
          id: destinationItem.id,
          order: destination.index,
        };
        if (destinationNode.category) {
          //destinationNode is folder
          params.category = {
            id: destinationNode.category.id,
            name: destinationNode.category.name,
          };
          params.parent = {
            id: destinationNode.id,
            name: destinationNode.name,
          };
        } else {
          //destinationNode is category
          params.category = {
            id: destinationNode.id,
            name: destinationNode.name,
          };
        }
        mUpdate.mutate({ category: params });

        //change sort
        //const sortParams = destinationNode.children.map((_ele: any) => _ele.id);
        //mSortCategory.mutate({ ids: sortParams });
      }
    } else {
      //re-order root
      const newItems = [...treeData];
      const [removedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removedItem);
      setTreeData(newItems);
      //update to DB
      const params = newItems.map((_ele: any) => _ele.id);
      mSortCategory.mutate({ ids: params });
    }
  };

  //edit item
  const handleEditItem = (parent: any, item: any, depth: number) => {
    if (depth === 0) {
      setWriteOption((curOption) => ({
        ...curOption,
        isOpenWrite: true,
        writeType: 'category',
        writeParam: item,
      }));
    } else {
      setWriteOption((curOption) => ({
        ...curOption,
        isOpenWrite: true,
        writeType: 'folder',
        writeParam: { ...item, parent },
      }));
    }
  };

  //delete
  const handleDeleteItem = (parent: any, item: any, depth: number) => {
    mDeleteCategory.mutate({ ids: [item.id] });
    setDeletedDepth(depth);
    setDeletedItem(item);
    setDeletedParent(parent);
    // confirmAlert({
    //   title: t('crm_new_common_delete'),
    //   message: t('crm_new_common_delete_msg'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: () => {
    //         mDeleteCategory.mutate({ ids: [item.id] });
    //         setDeletedDepth(depth);
    //         setDeletedItem(item);
    //         setDeletedParent(parent);
    //       },
    //     },
    //   ],
    // });
  };

  //get folders
  const handleGetFolders = (parent: any, item: any, depth: number) => {
    setOpenDepth(depth);
    if (depth === 0) {
      setOpenFolder(null);
      setOpenCategory(item); //item is category, parent = null
    } else {
      setOpenFolder(item); //item is folder, parent is category or folder
      setOpenCategory(item.category);
    }
  };

  //new/update category
  const handleAfterCreateCategory = (category: any) => {
    const newItems = [...treeData];
    const fIndex = newItems.findIndex((_ele: any) => _ele.id === category.id);
    if (fIndex > -1) {
      newItems[fIndex] = category;
    } else {
      newItems.push(category);
    }
    setTreeData(newItems);
  };

  //new/update folder
  const handleAfterCreateFolder = (folder: any) => {
    //// console.log('folder after', folder);
    /**
     * folder belongs to folder (category/parent !== null) --> destination is parent
     * folder belongs to category (category !== null, parent === null) --> destination is category
     * source: findNode
     */
    const newItems = _.cloneDeep(treeData);
    //1. delete in source
    const folderNode = findNode(newItems, folder.id, {});
    if (folderNode?.id) {
      const sourceParentId = folderNode.parent ? folderNode.parent.id : folderNode.category.id; //belongs to folder or category
      const sourceParentNode = findNode(newItems, sourceParentId, {});
      const fFolderIdx = sourceParentNode.children.findIndex((_ele: any) => _ele.id === folder.id);
      if (fFolderIdx > -1) {
        sourceParentNode.children.splice(fFolderIdx, 1);
      }
    }
    //2. add to destination
    const destParentId = folder.parent ? folder.parent.id : folder.category.id; //belongs to folder or category
    const destParentNode = findNode(newItems, destParentId, {}); //this is new Parent
    if (destParentNode?.id) {
      if (destParentNode.children) {
        const fFolderIndex = destParentNode.children.findIndex(
          (_ele: any) => _ele.id === folder.id,
        );
        if (fFolderIndex > -1) {
          destParentNode.children[fFolderIndex] = folder;
        } else {
          destParentNode.children.push(folder);
        }
      } else {
        destParentNode.children = [folder];
      }
    }
    setTreeData(newItems);
  };

  //selected node
  const handleSelectNode = (item: any) => {
    if (item.id !== selectedItem?.id) {
      setSelectedItem(item);
      onSelect && onSelect(item);
    } else {
      setSelectedItem(null);
      onSelect && onSelect(null);
    }
  };

  /** ===================================== RENDER ==================================== */
  //render item
  const renderNode = (parent: any, node: any, depth: number, dragHandleProps: any) => {
    return (
      <div
        className="tree-btn-wrap mg-b-3 rounded"
        style={{
          backgroundColor: selectedItem?.id === node.id ? 'lightskyblue' : 'transparent',
        }}
      >
        {depth === 0 && (
          <>
            {editable && (
              <div {...dragHandleProps}>
                <FormIcon icon="move" iconType="icon" className="cursor-move" color="gray" />
              </div>
            )}
            <FormIcon icon="category" iconType="icon" className="mg-x-5-f" color="lightcoral" />
          </>
        )}
        {depth > 0 && (
          <div {...dragHandleProps}>
            <Folder className="mg-x-5 tx-primary cursor-move" style={{ width: '20px' }} />
          </div>
        )}
        <div
          className="d-flex flex-grow-1 text-truncate cursor-default"
          onClick={() => handleSelectNode(node)}
        >
          {node.name}
          {/* {node.knowledges && node.knowledges > 0 && (
            <span className="badge badge-success mg-l-5">{node.knowledges}</span>
          )} */}
          {node?.display === EDisplay.PRIVATE && (
            <Lock className="mg-l-10 tx-warning" style={{ width: '15px' }} />
          )}
        </div>
        <div className="tree-btn-actions">
          {(node?.hasChild || editable) && (
            <button
              type="button"
              className="btn btn-xs btn-link link-03 btn-icon"
              data-toggle="collapse"
              data-target={'#node' + treeId + node.id}
              aria-expanded="false"
              onClick={() => handleGetFolders(parent, node, depth)}
            >
              {isFetching && (
                <span
                  className="spinner-border spinner-border-sm tx-primary mg-r-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <ChevronDown />
            </button>
          )}
          {editable && (
            <>
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                data-han-tooltip="Edit"
                onClick={() => handleEditItem(parent, node, depth)}
              >
                <Edit2 />
              </button>
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                data-han-tooltip="Delete"
                onClick={() => handleDeleteItem(parent, node, depth)}
              >
                <Trash2 className="tx-danger" />
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  //empty node
  const renderEmptyNode = (parent: any) => {
    return (
      <Draggable key={'-1'} draggableId={'-1'} index={-1} isDragDisabled={true}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div
              className="d-flex align-items-center justify-content-center ht-30 rounded"
              style={{ backgroundColor: 'var(--background-hover-color)' }}
            >
              <Folder className="mg-r-5" style={{ width: '15px' }} />
              <span className="text-truncate">No folder available.</span>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  //setSelectedFolder({ parent });
                  setWriteOption((curOption) => ({
                    ...curOption,
                    isOpenWrite: true,
                    writeType: 'folder',
                    writeParam: { parent },
                  }));
                }}
              >
                <PlusCircle className="tx-bold" />
              </button>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  //all tree
  //TODO: using isCombineEnabled prop to add parent-child, but NOW it doesn't support to move child to parent
  const renderTree = (parent: any, children: any[], depth: number) => {
    return (
      <Droppable droppableId={parent ? parent.id : 'node-root'} type={'FOLDER' + depth}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={classNames(`scroll-box rounded`, { 'mg-l-20': depth > 0 })}
            style={{
              padding: snapshot.isDraggingOver ? '0 0 0 5px' : 0,
              // margin: 4,
              // borderRadius: 4,
              backgroundColor: snapshot.isDraggingOver
                ? 'var(--background-hover-color)'
                : 'var(--background-container)',
              overflowX: snapshot.isDraggingOver ? 'hidden' : 'auto',
              overflowY: 'auto',
            }}
          >
            {children.map((_item: any, index: number) => (
              <Draggable
                key={_item.id}
                draggableId={_item.id}
                index={index}
                isDragDisabled={!editable}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    //{...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      padding: snapshot.isDragging ? 0 : 3,
                      borderRadius: snapshot.isDragging ? 4 : 0,
                      backgroundColor: snapshot.isDragging ? 'lightblue' : 'transparent',
                    }}
                  >
                    {renderNode(parent, _item, depth, provided.dragHandleProps)}
                    <div
                      id={'node' + treeId + _item.id}
                      className={classNames('collapse fade mg-r-5')}
                    >
                      {/* {_item?.children?.length > 0 && renderTree(_item, _item.children, depth + 1)} */}
                      {renderTree(_item, _item?.children || [], depth + 1)}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {/* create an empty droppable area in case empty children depth === 1 && */}
            {children.length === 0 && editable && renderEmptyNode(parent)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  //tree context
  const TreeMemo = useMemo(() => {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>{renderTree(null, treeData, 0)}</DragDropContext>
    );
  }, [treeData, isFetching, selectedItem, pageData]);

  //// console.log('treeData', treeData);
  //main render
  return (
    <>
      <div className="d-flex flex-grow-1 pd-10">
        <ul className="kb-tree list-unstyled w-100 mg-b-0">
          {mDeleteCategory.isLoading && <Loading />}
          {treeData.length === 0 && isFetching && <Loading />}
          {treeData.length > 0 && TreeMemo}
          {treeData.length === 0 && <NoData icon="Folder" />}
        </ul>
      </div>
      {writeOption.isOpenWrite &&
        writeOption.writeType === 'category' &&
        writeOption.writeMode === 'list' && (
          <WriteCategoryForm
            title="New Category"
            size="md"
            isOpen={writeOption.isOpenWrite}
            onClose={() => {
              setWriteOption((curOption) => ({
                ...curOption,
                isOpenWrite: false,
              }));
            }}
            data={writeOption.writeParam}
            onReload={handleAfterCreateCategory}
          />
        )}
      {writeOption.isOpenWrite &&
        writeOption.writeType === 'folder' &&
        writeOption.writeMode === 'list' && (
          <WriteFolderForm
            title="New Folder"
            size="md"
            isOpen={writeOption.isOpenWrite}
            onClose={() => {
              setWriteOption((curOption) => ({
                ...curOption,
                isOpenWrite: false,
              }));
            }}
            data={writeOption.writeParam}
            onReload={handleAfterCreateFolder}
          />
        )}
    </>
  );
};

export default CategoryFolderTree;
