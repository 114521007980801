import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { User } from 'react-feather';
import { useAssignmentReps } from '@settings/preferences/services/service';
import { Select } from '@base/components/form';

interface IAssignRepProps {
  className?: string;
  styles?: any;
  placeholder?: string;
  single?: boolean;
  groupId: string;
  defaultOptions?: any[];
  value?: any;
  onChange?: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const AssignRepAutoComplete: React.FC<IAssignRepProps> = (props) => {
  const {
    className,
    styles,
    placeholder = 'Select group then select a rep...',
    single = false, //
    groupId,
    defaultOptions = [],
    value, //[], initial value
    onChange,
  } = props;

  //state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [options, setOptions] = useState<any[]>(defaultOptions); //for default options
  const [selectedValue, setSelectedValue] = useState<any>(null);

  //initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          //2 cases for value: string[] or object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedValue?.map((_ele: any) => _ele.id);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_id: string) => {
                //find in options
                const fOption = options.find((_ele: any) => _ele.id === _id);
                if (fOption) {
                  newValue.push(fOption);
                }
              });
              setSelectedValue(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
              setSelectedValue(value);
            }
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object/string
        if (_.isString(value)) {
          if (value !== selectedValue?.id) {
            //find in options
            const fOption = options.find((_ele: any) => _ele.id === value);
            if (fOption) {
              setSelectedValue(fOption);
            }
          }
        } else {
          if (value?.id !== selectedValue?.id) {
            setSelectedValue(value);
          }
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value, options]);

  //build params
  // const getSearchParams = () => {
  //   let params: any = {
  //     keyword: searchText
  //   };
  //   return params;
  // };

  //convert to select options
  // const formatSelectOptions = (results: any) => {
  //   return results?.map((_item: any) => ({
  //     ..._item,
  //     label: _item?.name,
  //     value: _item?.id,
  //   }));
  // };

  const { data, isFetching } = useAssignmentReps(groupId);
  //// console.log('searchStatus', isFetching);
  //let newOptions = formatSelectOptions(results);

  //init states list
  useEffect(() => {
    if (data?.results) {
      const nReps = data.results.map((rep) => {
        const nData = rep?.user ? rep.user : rep;
        return {
          ...nData,
        };
      });
      setOptions(nReps);
    } else {
      setOptions(defaultOptions);
    }
  }, [data]);

  //init states list
  useEffect(() => {
    if (defaultOptions) {
      const nReps = defaultOptions.map((rep) => {
        const nData = rep?.user ? rep.user : rep;
        return {
          ...nData,
        };
      });
      setOptions(nReps);
    }
  }, [defaultOptions]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={styles}>
      <Select
        isClearable
        isSearchable={false}
        outSide={true}
        isMulti={!single}
        noOptionsMessage={() => 'No rep(s) found.'}
        placeholder={<div className="react-select-placeholder">{placeholder}</div>}
        iconIndicator={<User size={16} />}
        isLoading={isFetching}
        inputValue={inputText}
        onInputChange={handleInputChange}
        options={options}
        getOptionLabel={(opt: any) => opt.name ?? ''}
        getOptionValue={(opt: any) => opt.id ?? ''}
        value={selectedValue}
        onChange={handleSelectChange}
        //menuPlacement={'auto'}
      />
    </div>
  );
};

export default AssignRepAutoComplete;
