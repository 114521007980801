import React from 'react';
import Classification from '@desk/ticket/containers/classification';
interface IEditProps {
  value: any;
  onChange: (params: any) => {};
}
const Edit: React.FC<IEditProps> = (props: IEditProps) => {
  const { value, onChange } = props;
  return <Classification value={value} onChange={onChange} />;
};

export default Edit;
